import { toggleItemInArray } from '../../utils/toggleItemInArray';
import { updateQuantity, updateQuantityObject } from '../../utils/updateQuantity';
import { widgetTypes } from './widget.types';
import { orderTypeConst } from '../../constants/orderType.constants'
import updateCustomQuoteObject from '../../utils/updateCustomQuoteObject';
import { PolygonTypeConsts } from '../../constants/polygonType.constants';

const INITIAL_STATE = {
  loading: false,
  errors: [],
  orderFrom: "BUY_DIRECTLY",
  contractor: null,
  widgetStepper: { currentStep: 1, totalSteps: 3, currentSubStep: 0, totalSubSteps: 0, showSteps: true },
  orderUser: { name: '', email: '', phone: '', comment: '', source: '', dropdownProperties: { question: '', answer: '' }, consent: { title: '', answer: false } },
  packages: null,
  services: null,
  quantity: {},
  paymentDiscounts: {
    PAY_NOW: false,
    PAY_AS_YOU_GO: false,
    PAY_BY_CHECK: false
  },
  // ? Maybe we should set first in the list of the contractor
  // ? and if nothing is selected we can always set to Pay as you go
  paymentType: orderTypeConst.PAY_AS_YOU_GO,
  measurement: { sqFeet: 0, polygons: [], place: null },
  cart: { services: [], packages: [] },
  customQuotes: { services: {}, packages: {}, servicesInPackage: {} },
  customDiscounts: { services: {}, packages: {} },
  showIFrame: false,
  signature: '',
  orderbase64: '',
  boundary: '',
  viewOtherServices: false,
  // ? adding as a temporary fix
  isPaidWithPaypal: false,
  orderQuestions: null,
  orderDiscounts: null,
  isAcceptedTerms: false,
  callOnPlaceSelected: true,
  currentRegion: null,
  selectedCategories: [],
  selectedSubCategories: [],
  searchedServicesType: 'LAWN',
  searchedServicesFeatures: PolygonTypeConsts['LAWN'].searchTypeFeatures,
  updateSearchOptionsDisplayStatus: null,
  pciVault_card: null,
  pciVault_cardToken: '',
  pciVaultReference: '',
  referrerInfo: null,
  estimateDetail_images: [],
  notes: [],
  selectedProSearchedFeatures: [],
  yardInfo: {},
  internalTags: [],
};

const widgetReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case widgetTypes.SET_CURRENT_STEP:
      return {
        ...state,
        widgetStepper: { ...state.widgetStepper, currentStep: action.payload },
      };
    case widgetTypes.SET_WIDGET_STEPPER:
      return {
        ...state,
        widgetStepper: payload,
      };
    case widgetTypes.SET_TOTAL_STEPS:
      return {
        ...state,
        widgetStepper: { ...state.widgetStepper, totalSteps: action.payload },
      };
    case widgetTypes.SET_SUB_STEP:
      return {
        ...state,
        widgetStepper: { ...state.widgetStepper, currentSubStep: action.payload },
      };
    case widgetTypes.SET_TOTAL_SUB_STEPS:
      return {
        ...state,
        widgetStepper: { ...state.widgetStepper, totalSubSteps: action.payload },
      };
    case widgetTypes.SET_SHOW_STEP:
      return {
        ...state,
        widgetStepper: { ...state.widgetStepper, showSteps: action.payload },
      };
    case widgetTypes.SET_ORDER_TYPE:
      return {
        ...state,
        orderFrom: payload,
      };
    case widgetTypes.SET_WIDGET_INITIAL_STATE:
      return {
        ...INITIAL_STATE,
      };
    case widgetTypes.CLEAR_WIDGET_STATE:
      return {
        ...INITIAL_STATE,
        searchedServicesType: state.searchedServicesType,
        searchedServicesFeatures: state.searchedServicesFeatures,
        selectedProSearchedFeatures: state.selectedProSearchedFeatures,
        boundary: state.boundary,
        cart: { services: [], packages: [] }
      };
    case widgetTypes.SET_WIDGET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case widgetTypes.SET_ERRORS:
      return {
        ...state,
        errors: payload,
      };
    case widgetTypes.SET_CONTRACTOR:
      return {
        ...state,
        contractor: payload,
      };
    case widgetTypes.SET_PACKAGES:
      return {
        ...state,
        packages: payload,
      };
    case widgetTypes.SET_SERVICES:
      return {
        ...state,
        services: action.payload.map((service) => {
          return { ...service, quantity: service.quantity ? service.quantity : 1 };
        }),
      };
    case widgetTypes.UPDATE_MEASUREMENT:
      return {
        ...state,
        measurement: { ...state.measurement, ...action.payload },
      };
    case widgetTypes.SET_QUANTITY_FOR_SERVICE:
      return {
        ...state,
        quantity: payload
      };
    case widgetTypes.UPDATE_SERVICE_QUANTITY:
      return {
        ...state,
        services: updateQuantity(state.services, payload._id, payload.type),
      };
    case widgetTypes.UPDATE_WIDGET_SERVICE_QUANTITY_IN_CART:
      return {
        ...state,
        quantity: updateQuantityObject(state.quantity, payload._id, payload.type)

      };
    case widgetTypes.UPDATE_PACKAGES_QUANTITY:
      return {
        ...state,
        packages: updateQuantity(state.packages, payload.id, payload.type),
      };
    case widgetTypes.TOGGLE_SERVICE_IN_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          services: toggleItemInArray(state.cart.services, payload),
        },
      };
    case widgetTypes.TOGGLE_PACKAGE_IN_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          packages: toggleItemInArray(state.cart.packages, payload),
        },
      };
    case widgetTypes.UPDATE_PACKAGE_IN_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          packages: payload,
        },
      };
    case widgetTypes.TOGGLE_CATEGORY:
      return {
        ...state,
        selectedCategories: payload.map(category => category)
      };
    case widgetTypes.TOGGLE_SUB_CATEGORY:
      return {
        ...state,
        selectedSubCategories: payload
      };
    case widgetTypes.SET_CUSTOM_QUOTES:
      return {
        ...state,
        customQuotes: payload,
      };
    case widgetTypes.SET_CUSTOM_DISCOUNTS:
      return {
        ...state,
        customDiscounts: payload
      }
    case widgetTypes.TOOGLE_SERVICE_IN_CUSTOM_QUOTE:
      return {
        ...state,
        customQuotes: {
          ...state.customQuotes,
          services: updateCustomQuoteObject(state.customQuotes.services, payload._id, payload.price),
        },
      };
    case widgetTypes.TOOGLE_PACKAGE_IN_CUSTOM_QUOTE:
      return {
        ...state,
        customQuotes: {
          ...state.customQuotes,
          packages: updateCustomQuoteObject(state.customQuotes.packages, payload._id, payload.price),
        },
      };
    case widgetTypes.TOOGLE_PACKAGE_IN_SERVICE_CUSTOM_QUOTE:
      return {
        ...state,
        customQuotes: {
          ...state.customQuotes,
          servicesInPackage: updateCustomQuoteObject(state.customQuotes.servicesInPackage, payload._id, payload.price),
        },
      };
    case widgetTypes.SET_CART:
      return {
        ...state,
        cart: payload,
      };
    case widgetTypes.SET_SIGNATURE:
      return {
        ...state,
        signature: payload,
      };
    case widgetTypes.SET_ORDER_PDF_BASE_64:
      return {
        ...state,
        orderbase64: payload,
      };
    case widgetTypes.CHANGE_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: payload,
        errors: []
      };
    case widgetTypes.UPDATE_ORDER_USER:
      return {
        ...state,
        orderUser: payload,
      };
    case widgetTypes.SHOULD_SHOW_IFRAME:
      return {
        ...state,
        showIFrame: payload
      }
    case widgetTypes.SET_VIEW_OTHER_SERVICES:
      return {
        ...state,
        viewOtherServices: payload
      }
    case widgetTypes.SET_CONTACT_INFO:
      return {
        ...state,
        orderUser: payload
      }
    case widgetTypes.SET_WIDGET_BOUNDARY:
      return {
        ...state,
        boundary: payload
      }
    case widgetTypes.SET_PAYPAL_PAID:
      return {
        ...state,
        isPaidWithPaypal: true
      }
    case widgetTypes.SET_ORDER_QUESTIONS:
      return {
        ...state,
        orderQuestions: payload
      }
    case widgetTypes.SET_PAYMENT_DISCOUNTS:
      const { type, value } = payload
      return {
        ...state,
        paymentDiscounts: { ...state.paymentDiscounts, [type]: value }
      }
    case widgetTypes.SET_IS_ACCEPTED_TERMS:
      return {
        ...state,
        isAcceptedTerms: payload
      }
    case widgetTypes.SET_CALL_ON_PLACE_SELECTED:
      return {
        ...state,
        callOnPlaceSelected: payload
      }
    case widgetTypes.SET_CURRENT_REGION:
      return {
        ...state,
        currentRegion: payload
      }
    case widgetTypes.SET_ORDER_DISCOUNTS:
      return {
        ...state,
        orderDiscounts: payload
      }
    case widgetTypes.SET_SEARCHED_SERVICES_TYPE:
      return {
        ...state,
        searchedServicesType: payload,
        searchedServicesFeatures: PolygonTypeConsts[payload].searchTypeFeatures
      }
    case widgetTypes.SET_PRO_SEARCHED_FEATURES:
      return {
        ...state,
        selectedProSearchedFeatures: payload
      }
    case widgetTypes.UPDATE_SEARCH_OPTIONS_DISPLAY_STATUS:
      return {
        ...state,
        updateSearchOptionsDisplayStatus: payload
      }
    case widgetTypes.SET_PCI_VAULT_CARD_INFO:
      return {
        ...state,
        pciVault_card: payload
      }
    case widgetTypes.SET_PCI_VAULT_CARD_TOKEN:
      return {
        ...state,
        pciVault_cardToken: payload
      }
      case widgetTypes.SET_PCI_VAULT_REFERENCE:
        return {
          ...state,
          pciVaultReference: payload
        }
    case widgetTypes.SET_REFERRER_INFO:
      return {
        ...state,
        referrerInfo: { ...state.referrerInfo, ...payload }
      }
    case widgetTypes.SET_ESTIMATE_DETAIL_IMAGES:
      return {
        ...state,
        estimateDetail_images: payload
      }
    case widgetTypes.SET_NOTES:
      return {
        ...state,
        notes: payload
      }
    case widgetTypes.SET_YARD_INFO:
      return {
        ...state,
        yardInfo: payload
      }
    case widgetTypes.SET_INTERNAL_TAGS:
      return {
        ...state,
        internalTags: payload 
      }
    default:
      return state;
  }
};

export default widgetReducer;