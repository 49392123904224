import { applyServicesDiscountsOnPackage } from "./applyServicesDiscountsOnPackage";
import { calculateDiscount } from "./calculateDiscount";
import { getProTurfPackagePricePayGo } from "./getPackagePrice";
import { roundToTwoDigits } from "./roundToTwoDigits";

const getProTurfPackagePriceWithDiscount_PayGo = (packageItem, sqFeet, boundary, cartPaymentType, customQuotes, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  let packagePrice = 0
  let appliedDiscounts = []
  if(packageItem?.servicesDiscounts?.length){
    const {discountedPrice, discounts} = applyServicesDiscountsOnPackage(packageItem, sqFeet, boundary, customQuotes, cartPaymentType, buildingSquareFeet, polygonsFeaturesSqFeet)
  packagePrice = discountedPrice
    appliedDiscounts.push(...discounts)
  } else {
  packagePrice = getProTurfPackagePricePayGo(packageItem, sqFeet, boundary?.acreage_calc*43560, customQuotes, buildingSquareFeet, polygonsFeaturesSqFeet);
  }
  if (packageItem.discount?.length) {
    const {discountedTotal, discounts} = calculateDiscount(packageItem.discount, packagePrice, cartPaymentType)
    if(discountedTotal) {
      packagePrice = discountedTotal
      appliedDiscounts.push(...discounts)
    }
  return {discountedPrice: roundToTwoDigits(packagePrice).toFixed(2), discounts: appliedDiscounts};
  }
  return {discountedPrice: roundToTwoDigits(packagePrice).toFixed(2), discounts: appliedDiscounts};
};

export default getProTurfPackagePriceWithDiscount_PayGo