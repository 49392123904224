import { teamMemberTypes } from "./teamMember.types";

const INITIAL_STATE = {
    member: {},
    salesTeam: []
};

export const teamMemberReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case teamMemberTypes.SET_TEAM_MEMBER:
            return {
                ...state,
                member: { ...payload }
            }
        case teamMemberTypes.SET_WHOLE_TEAM:
            return {
                ...state,
                salesTeam: [...payload]
            }
        case teamMemberTypes.SET_INITIAL_TEAM_STATE:
            return {
                ...INITIAL_STATE
            }
        default:
            return state
    }
}
