import { contractorTypes } from './contractor.types';
import sendRequest from '../../utils/useAxios';
import { DELETE_ORDER_BY_ID, GET_CONTRACTOR_ORDERS, GET_CONTRACTOR_ORDERS_FILTERED, GET_CONTRACTOR_ORDERS_MEASUREMENTS, GET_MULTIPLE_SALESMAN_ORDERS_OVERVIEW, GET_MULTIPLE_SALESMEN_ORDERS_FILTERED, GET_ORDERS_OVERVIEW, GET_ORDERS_UTM_OVERVIEW, GET_SALESMAN_ORDERS_FILTERED, GET_SALESMAN_ORDERS_OVERVIEW, GET_SEARCHED_ORDERS, GET_SEARCHED_ORDERS_MEASUREMENTS, SET_HAS_TEAM_MEMBERS } from './contractor.api';
import { getDatesByFilter } from '../../utils/getDatesByFilter';
import moment from 'moment';
import { UTM_PARAMS } from '../../constants/app.constants';
import { generateMembersFilterQuery } from '../../utils/isTeamMember';
import { isInRoles } from '../../utils/isInRoles';
import { rolesConstants } from '../../constants/roles.constants';

export const getContractorOrders = (query) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(`${GET_CONTRACTOR_ORDERS.METHOD}`, `${GET_CONTRACTOR_ORDERS.PATH}${query}`);
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_CONTRACTOR_ORDERS, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false));
};
export const resetContractorOrders = () => async (dispatch) => {
  dispatch({ type: contractorTypes.RESET_CONTRACTOR_ORDERS}) 
};


export const getFilteredContractorOrders = (query) => async (dispatch) => {
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_CONTRACTOR_ORDERS_FILTERED.METHOD, `${GET_CONTRACTOR_ORDERS_FILTERED.PATH}?${query}`)
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_CONTRACTOR_ORDERS, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false))
};

// work 
export const getFilteredContractorMeasurements = (query) => async (dispatch) => {
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_CONTRACTOR_ORDERS_MEASUREMENTS.METHOD, `${GET_CONTRACTOR_ORDERS_MEASUREMENTS.PATH}?${query}`)
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_CONTRACTOR_ORDERS, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false))
};
// end 

export const getFilteredSalesmanOrders = (query, salesmanId) => async (dispatch) => {
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_SALESMAN_ORDERS_FILTERED.METHOD, `${GET_SALESMAN_ORDERS_FILTERED.PATH}/${salesmanId}?${query}`)
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_CONTRACTOR_ORDERS, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false))
}

export const getFilteredMultipleSalesmanOrders = (query, salesmenIds) => async (dispatch) => {
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_MULTIPLE_SALESMEN_ORDERS_FILTERED.METHOD, `${GET_MULTIPLE_SALESMEN_ORDERS_FILTERED.PATH}?${query}`, {
    salesmenIds
  })
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_CONTRACTOR_ORDERS, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false))
}

export const getSearchedOrders = (searchQuery, pageNo, ordersFilter?:any) => async (dispatch) => {
  let query:any = {
    searchQuery
  }
  if (ordersFilter) {
    query = { ...query, paymentType: ordersFilter }
  }
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_SEARCHED_ORDERS.METHOD, `${GET_SEARCHED_ORDERS.PATH}?page=${pageNo}`, query)
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_CONTRACTOR_ORDERS, data))
  } else {
    dispatch(setError(data?.message))
  }
  dispatch(setLoading(false))
  return data
}

// work
export const getSearchedOrdersMeasurements = (searchQuery, pageNo) => async (dispatch) => {
  const query = {
    searchQuery
  }
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_SEARCHED_ORDERS_MEASUREMENTS.METHOD, `${GET_SEARCHED_ORDERS_MEASUREMENTS.PATH}?page=${pageNo}`, query)
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_CONTRACTOR_ORDERS, data))
  } else {
    dispatch(setError(data?.message))
  }
  dispatch(setLoading(false))
  return data
} 
// end

export const deleteOrder = (orderId: string) => async (dispatch) => {
 
  sendRequest(DELETE_ORDER_BY_ID.METHOD, `${DELETE_ORDER_BY_ID.PATH}/${orderId}`).then(
  dispatch({
      type: contractorTypes.DELETE_ORDER_BY_ID,
      payload: orderId,
  }))  
  
}

export const getOrdersOverview = (filter) => async (dispatch, getState) => {
  const state = getState()
  const { selectedSalesMan} = state.customer
  const { salesTeam } = state.teamMember
  const { user } = state.user
  const { roles } = user || []
  const isManager = isInRoles(roles, rolesConstants.MANAGER)
  // const salesmenQuery = isManager && generateMembersFilterQuery(selectedSalesMan, salesTeam)
  const salesmenQuery = isManager ? generateMembersFilterQuery(selectedSalesMan, salesTeam):''
  dispatch(setOverViewDataLoading(true));
  
    const { startDate, endDate } = getDatesByFilter(filter);
    const utcOffset = moment().format("Z")
    const { data } = await sendRequest(GET_ORDERS_OVERVIEW.METHOD, `${GET_ORDERS_OVERVIEW.PATH}/${filter}/${startDate}/${endDate}/${utcOffset}?${salesmenQuery}`);
    if (data && !data.statusCode) {
      dispatch(setSuccess(contractorTypes.SET_OVERVIEW_DATA, data));
    } else {
      dispatch(setError(data?.message));
    }

  dispatch(setOverViewDataLoading(false));
};

export const getSalesmanOrdersOverview = (filter, salesmanId) => async (dispatch) => {
  dispatch(setLoading(true));
  const {startDate, endDate} = getDatesByFilter(filter);
  const utcOffset = moment().format("Z")
  const { data } = await sendRequest(GET_SALESMAN_ORDERS_OVERVIEW.METHOD,`${GET_SALESMAN_ORDERS_OVERVIEW.PATH}/${salesmanId}/${filter}/${startDate}/${endDate}/${utcOffset}/`);

  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_OVERVIEW_DATA, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false));
};

export const getMultipleSalesmenOverview = (filter, salesmenIds) => async (dispatch) => {
  dispatch(setLoading(true));
  const {startDate, endDate} = getDatesByFilter(filter);
  const utcOffset = moment().format("Z")
  const { data } = await sendRequest(GET_MULTIPLE_SALESMAN_ORDERS_OVERVIEW.METHOD,`${GET_MULTIPLE_SALESMAN_ORDERS_OVERVIEW.PATH}/${filter}/${startDate}/${endDate}/${utcOffset}/`, {
    salesmenIds
  });
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_OVERVIEW_DATA, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false));
}

export const toggleOrderExpand = (id) => (dispatch) => {
  dispatch({
    type: contractorTypes.TOGGLE_ORDER_EXPAND,
    payload: id,
  });
};

export const setOrdersFilter = (filter) => (dispatch) => {
  dispatch({
    type: contractorTypes.SET_ORDERS_FILTER,
    payload: filter,
  });
};

export const setOverviewFilter = (id) => (dispatch) => {
  dispatch({
    type: contractorTypes.SET_OVERVIEW_FILTER,
    payload: id,
  });
};

export const changeProductSelection = (type) => async (dispatch) => {
  dispatch({
    type: contractorTypes.CHANGE_PRODUCT_SELECTION,
    payload: type,
  });
};

export const setLoading = (type) => async (dispatch) => {
  dispatch({
    type: contractorTypes.SET_LOADING_CONTRACTOR,
    payload: type,
  });
};

export const setOverViewDataLoading = (type) => async (dispatch) => {
  dispatch({
    type: contractorTypes.SET_OVERVIEW_LOADING_CONTRACTOR,
    payload: type,
  });
};

export const setSuccess = (action, payload) => {
  return {
    type: contractorTypes[action],
    payload,
  }
}

export const setError = (payload) => {
  return {
    type: contractorTypes.SET_ERROR_CONTRACTOR,
    payload,
  }
}

export const setMarketingTabsFilter = (filter) => (dispatch) => {
  dispatch({
    type: contractorTypes.SET_MARKETING_TABS_FILTER,
    payload: filter,
  });
};

export const setHasTeamMembers = (contractorId) => async (dispatch) => {
  const { data } = await sendRequest(SET_HAS_TEAM_MEMBERS.METHOD, `${SET_HAS_TEAM_MEMBERS.PATH}/${contractorId}`)
  if (data && !data.statusCode) {
    if(data > 0) {
      dispatch({
        type: contractorTypes.SET_HAS_TEAM_MEMBERS,
        payload: true
      })
    } else {
      dispatch({
        type: contractorTypes.SET_HAS_TEAM_MEMBERS,
        payload: false
      })
    }
  } else {
    return
  }
}
