import { Fragment, ReactNode, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'

import {AiFillCaretDown} from 'react-icons/ai'
import { CheckIcon } from '@heroicons/react/solid'

interface FilterProps {
  options?: any,
  active?: string,
  label?: string,
  heading_label?: string;
  heading_icon?: ReactNode;
  heading_link?: { href: string; title: string };
  disabled?: boolean;
  id?: any,
  name?: any,
  collapseOn?:string,
}

export const Filter=({options, active, label, heading_label,heading_icon,heading_link , disabled, id, name, ...props} : FilterProps)=> {
  const [selected, setSelected] = useState({ name: active? active : options[0]?.name || label  })

  useEffect(() => {
    setSelected({ name: active? active : options[0]?.name || label  })
  }, [active])
  

  return (
    <div className={`min-w-[192px] w-full ${props?.collapseOn || "md"}:w-[192px]`}>
            {heading_label && (
        <div className="block">
          <label
            htmlFor=""
            className="flex justify-start items-center space-x-2 text-[14px] font-medium my-2"
          >
            <span>{heading_label} {heading_link && <a href={heading_link.href} target="_blank">{heading_link.title}</a> }</span>
            <span className="text-[20px] text-[#7F8783]">
              {heading_icon}
            </span>
          </label>
        </div>
      )}
      <Listbox value={selected} disabled={disabled} id={id} name={name} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button  className="relative w-full rounded-lg bg-white py-[0.625rem] pl-3 pr-10 text-left focus:ring-offset-gray-100 hover:bg-gray-50 cursor-pointer focus:outline-none focus-visible:border-gray-200  border-1 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm border border-gray-300">
            <span className={selected.name === label ?  `block text-[#BDC7C2] truncate mr-5` : `block truncate mr-5`}>{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <AiFillCaretDown
                className="h-3 w-3 text-gray-500"  
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 w-full max-h-64 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20">
              {options && options?.map((person: any, personIdx: any) => (
                <Listbox.Option
                onClick={person?.onClick}
                  key={personIdx}
                  className={({ active }) =>
                    `select-none py-2 text-left px-3 cursor-pointer flex ${
                      active ? 'bg-gray-50 text-green-500' : 'text-gray-900'
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {person?.name}
                      </span>
                      {selected && !props.hideTick ? (
                        <span className="inset-y-0 left-0 flex items-center pl-3 text-green-500">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
