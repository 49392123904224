import { Menu, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';

interface ProfileMenuProps {
  contractorDropDownOptions: any;
  username: any;
  company: any;
  avatar?: any;
  screenType?: any;
  drawerWidth?: any;
  sidebarOpen?: any;
}

export default function ProfileMenu({ contractorDropDownOptions, username, company, avatar, screenType, drawerWidth, sidebarOpen, ...props }: ProfileMenuProps) {

  const isWorker = contractorDropDownOptions.length === 1;

  const isManager = contractorDropDownOptions.length === 2;

  return (
    <div>
      <Menu as="div" className={`relative w-full inline-block text-left`}>
        <div className={`${drawerWidth === 112 && screenType === "large screen" ? `flex items-center justify-end ml-2` : `flex items-center justify-start`}`}>
          <Menu.Button className={`inline-flex ${drawerWidth === 256 && screenType === "large screen" ? "w-full justify-between items-center" : screenType === "small screen" ? "w-full justify-between items-center" : "justify-end items-center"} rounded-md bg-white px-2 py-2 text-sm font-medium text-gray-900 hover:bg-green-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
            <div className='flex justify-start items-center gap-2'>
              {avatar ?
                <img
                  className="h-8 w-8 rounded-full"
                  src={avatar}
                  alt="avatar"
                /> :
                <div className='rounded-full bg-green-50 border border-green-200 p-2 min-w-fit'>
                  <img src="https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/person_vr2994.svg" alt="profile" className='w-8 h-8' />
                </div>
              }
              {drawerWidth === 256 || sidebarOpen ? <div className='font-medium text-[14px] flex-grow-1'><span>{username}</span></div> : null}
            </div>
            <ChevronUpIcon
              className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={`absolute right-[-3rem] ${isWorker ? "md:right-[-13rem]" : "md:right-[-12.5rem]"} top-[-7rem] ${isWorker ? "md:top-[-1.5rem]" : "md:top-[-6rem]"} z-50 ${isWorker ? "-mt-1 mx-[-5px]" : isManager ? "mt-[1rem]" : "-mt-8 mx-[-10px]"} ${screenType === "small screen" ? "mt-16 md:mr-10 md:mt-[-20px]" : ""} ${screenType === "small screen" && (!isManager && !isWorker) ? "mt-[-10px] mx-[-15px]" : ""} w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
            <div className="px-1 py-1 ">
              {contractorDropDownOptions.map((val: any) => (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`
                    ${active ? 'bg-green-50 text-green-500 hover:stroke-green-500 hover:fill-green-500' : 'text-gray-900  stroke-gray-400'}
                    
                     group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={val.onClick}
                    >
                      {active ? <div className='mr-2'>{val.icon}</div> :
                        <div className='mr-2'>{val.icon}</div>
                      }
                      {val.name}
                    </button>
                  )}
                </Menu.Item>
              ))}

            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
