export const DEFAULT_QUESTION_OPTIONS = ['Yes', 'No']
export const CLIENT_APP_URL = process.env.NEXT_PUBLIC_CLIENT_URL

export const VirginiaGreenHeadingArray = [
  {
    heading: 'Guaranteed Results',
    body: 'Virginia Green guarantees your lawn will be the envy of your neighbors. Our in-house agronomy team is here to help if we are not meeting your expectations.'
  },
  {
    heading: 'Local Experts',
    body: 'We know our turf. All Virginia Green service leaders are state-certified and trained on our unique climate and soil characteristics, ensuring your lawn gets the attention it deserves.'
  },
  {
    heading: 'Science-Backed Service',
    body: 'Virginia Green believes providing the best care starts with soil and turf science. Our processes are honed to perfection at our 300 acre research farm before making their way to your home.'
  },
  {
    heading: 'No Contracts',
    body: 'Our results speak for themselves and we keep your business by providing you with excellence - no contracts required.'
  },
]

export const ImagesArray = [
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546726/Jarid%20Loading%20Animation%20Images/Jarid_Animation_1_cnklfx.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546725/Jarid%20Loading%20Animation%20Images/Jarid_Animation_2_iemj1c.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546726/Jarid%20Loading%20Animation%20Images/Jarid_Animation_3_ytbk7l.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546726/Jarid%20Loading%20Animation%20Images/Jarid_Animation_4_bhyxmn.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546726/Jarid%20Loading%20Animation%20Images/Jarid_Animation_5_uuep1s.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546726/Jarid%20Loading%20Animation%20Images/Jarid_Animation_6_gmc3gk.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546726/Jarid%20Loading%20Animation%20Images/Jarid_Animation_7_h99q3c.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546726/Jarid%20Loading%20Animation%20Images/Jarid_Animation_8_ydqv9z.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546726/Jarid%20Loading%20Animation%20Images/Jarid_Animation_9_ieqmwv.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546726/Jarid%20Loading%20Animation%20Images/Jarid_Animation_10_sxm70g.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546727/Jarid%20Loading%20Animation%20Images/Jarid_Animation_11_c2ftoe.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546726/Jarid%20Loading%20Animation%20Images/Jarid_Animation_12_olkmr5.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546727/Jarid%20Loading%20Animation%20Images/Jarid_Animation_13_f9uuxj.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546727/Jarid%20Loading%20Animation%20Images/Jarid_Animation_14_pmn7oc.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1674546727/Jarid%20Loading%20Animation%20Images/Jarid_Animation_15_qbqipo.png'
]

const DreamLawnImages={
  LOGO:'https://res.cloudinary.com/duynyjen3/image/upload/v1711525026/jeff-dreamlawn%20storefront/counter/DL_Logo_kdujuk.png',
  TREE_SHRUB_CIRCLE:'https://res.cloudinary.com/duynyjen3/image/upload/v1711525025/jeff-dreamlawn%20storefront/counter/Tree_Shrub_Circle_t8xwuk.png',
  LAWN_CARE_CIRCLE:'https://res.cloudinary.com/duynyjen3/image/upload/v1711525025/jeff-dreamlawn%20storefront/counter/Lawn_Care_Circle_xqrfb0.png',
  PEST_CONTROL_CIRCLE:'https://res.cloudinary.com/duynyjen3/image/upload/v1711525025/jeff-dreamlawn%20storefront/counter/Pest_Control_Circle_fpyyuy.png',
  IRRIGATION_CIRCLE:'https://res.cloudinary.com/duynyjen3/image/upload/v1711525025/jeff-dreamlawn%20storefront/counter/Irrigation_Circle_xoc69p.png'
}

export const DreamLawnImagesArray = [
  {
    heading:`The DreamLawn Difference:`,
    body: `75% Less Pesticides, 50% Less Water Usage`,
    image: DreamLawnImages.LOGO
  },
  {
    heading:`Your Success is Guaranteed!`,
    body: `Free service calls, maintenance seedings included, and more!`,
    image: DreamLawnImages.LAWN_CARE_CIRCLE
  },
  {
    heading:`Ants, spiders, mosquitoes - oh my!`,
    body: `Take the bite out of your lawn this season with DreamLawn Pest Protection!`,
    image: DreamLawnImages.PEST_CONTROL_CIRCLE
  },
  {
    heading:`Be Part of Reducing 100 MILLION Pounds of Pesticides by Earth Day 2025!`,
    body: ``,
    image: DreamLawnImages.LOGO
  },
  {
    heading:`Ask us how to save time, water, and money With a WiFi Irrigation Controller!`,
    body: ``,
    image: DreamLawnImages.IRRIGATION_CIRCLE
  },
  {
    heading:`In only 5 years`,
    body: `A DreamLawn home saves enough water to fill an Olympic swimming pool!`,
    image: DreamLawnImages.LOGO
  },
  {
    heading:`Only 3 treatments a year to keep fleas, ticks, & chiggers out of your lawn all summer long! `,
    body: ``,
    image: DreamLawnImages.PEST_CONTROL_CIRCLE
  },
  {
    heading:`Get your next service FREE by referring your friends!`,
    body: ``,
    image: DreamLawnImages.LOGO
  },
  {
    heading:`Did you know?`,
    body: `FALL is the best time to seed your lawn.Choose to include seeding in your lawn program!`,
    image: DreamLawnImages.LAWN_CARE_CIRCLE
  },
  {
    heading:`Early leaf drop & color change can signify plant stress.`,
    body: `Protect your landscaping throughout the season for a longer, healthier lifespan!`,
    image: DreamLawnImages.TREE_SHRUB_CIRCLE
  }

]

export const RainAndBuddiesImagesArray = [
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043377/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC1_qpentc.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043377/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC2_pgrfi5.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043377/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC3_ah9rom.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043377/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC4_wypvvm.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043376/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC5_uvebta.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043377/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC6_lxldia.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043376/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC7_jg4cay.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043376/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC8_v2qpwm.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043376/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC9_kstwht.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043376/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC10_dktrfl.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043375/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC11_k3qgvl.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043375/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC12_dpksef.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043375/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC13_ol69js.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043375/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC14_lsryke.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043375/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC15_hofcur.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043375/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC16_e8brge.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043375/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC17_db6nas.png',
  'https://res.cloudinary.com/duynyjen3/image/upload/v1677043375/Rain%20master%20and%20Lawn%20Buddies%20Loading%20Animation%20Images/GRAPHIC18_glwl5f.png'
]

export const UTM_PARAMS = { ALL: '', GOOGLE: 'GOOGLE', FACEBOOK: 'FACEBOOK' }

export const ALL_SUFFIXES = [
  "ALY",
  "ANX",
  "ARC",
  "AVE",
  "BYU",
  "BCH",
  "BND",
  "BLF",
  "BLFS",
  "BTM",
  "BLVD",
  "BR",
  "BRG",
  "BRK",
  "BRKS",
  "BG",
  "BGS",
  "BYP",
  "CP",
  "CYN",
  "CPE",
  "CSWY",
  "CTR",
  "CTRS",
  "CIR",
  "CIRS",
  "CLF",
  "CLFS",
  "CLB",
  "CMN",
  "CMNS",
  "COR",
  "CORS",
  "CRSE",
  "CT",
  "CTS",
  "CV",
  "CVS",
  "CRK",
  "CRES",
  "CRST",
  "XING",
  "XRD",
  "XRDS",
  "CURV",
  "DL",
  "DM",
  "DV",
  "DR",
  "DRS",
  "EST",
  "ESTS",
  "EXPY",
  "EXT",
  "EXTS",
  "FALL",
  "FLS",
  "FRY",
  "FLD",
  "FLDS",
  "FLT",
  "FLTS",
  "FRD",
  "FRDS",
  "FRST",
  "FRG",
  "FRGS",
  "FRK",
  "FRKS",
  "FT",
  "FWY",
  "GDN",
  "GDNS",
  "GTWY",
  "GLN",
  "GLNS",
  "GRN",
  "GRNS",
  "GRV",
  "GRVS",
  "HBR",
  "HBRS",
  "HVN",
  "HTS",
  "HWY",
  "HL",
  "HLS",
  "HOLW",
  "INLT",
  "IS",
  "ISS",
  "ISLE",
  "JCT",
  "JCTS",
  "KY",
  "KYS",
  "KNL",
  "KNLS",
  "LK",
  "LKS",
  "LAND",
  "LNDG",
  "LN",
  "LGT",
  "LGTS",
  "LF",
  "LCK",
  "LCKS",
  "LDG",
  "LOOP",
  "MALL",
  "MNR",
  "MNRS",
  "MDW",
  "MDWS",
  "MEWS",
  "ML",
  "MLS",
  "MSN",
  "MTWY",
  "MT",
  "MTN",
  "MTNS",
  "NCK",
  "ORCH",
  "OVAL",
  "OPAS",
  "PARK",
  "PARK",
  "PKWY",
  "PKWY",
  "PASS",
  "PSGE",
  "PATH",
  "PIKE",
  "PNE",
  "PNES",
  "PL",
  "PLN",
  "PLNS",
  "PLZ",
  "PT",
  "PTS",
  "PRT",
  "PRTS",
  "PR",
  "RADL",
  "RAMP",
  "RNCH",
  "RPD",
  "RPDS",
  "RST",
  "RDG",
  "RDGS",
  "RIV",
  "RD",
  "RDS",
  "RTE",
  "ROW",
  "RUE",
  "RUN",
  "SHL",
  "SHLS",
  "SHR",
  "SHRS",
  "SKWY",
  "SPG",
  "SPGS",
  "SPUR",
  "SPUR",
  "SQ",
  "SQS",
  "STA",
  "STRA",
  "STRM",
  "ST",
  "STS",
  "SMT",
  "TER",
  "TRWY",
  "TRCE",
  "TRAK",
  "TRFY",
  "TRL",
  "TRLR",
  "TUNL",
  "TPKE",
  "UPAS",
  "UN",
  "UNS",
  "VLY",
  "VLYS",
  "VIA",
  "VW",
  "VWS",
  "VLG",
  "VLGS",
  "VL",
  "VIS",
  "WALK",
  "WALK",
  "WALL",
  "WAY",
  "WAYS",
  "WL",
  "WLS",
]

export const CUSTOM_FLOWS = {
  "LAWN_BUDDIES": process.env.NEXT_PUBLIC_LAWN_BUDDIES_CUSTOMER_ID
}
export const ROBIN_WEBSITE = process.env.NEXT_PUBLIC_ROBIN_WEBSITE

export const EMERALD_TEST_ACCOUNT_CID = "650945f7d7d1ba0e911d59c3" //Test account id
// export const EMERALD_TEST_ACCOUNT_CID="6288b234f3ef3b2ccf756946"

export const EMERALD_DEMO_ACCOUNT_CID = "616f7f54e5852081ed72a21b" //Demo account id
// export const EMERALD_DEMO_ACCOUNT_CID="6288b234f3ef3b2ccf756946"


export const PRO_WEBSITE = process.env.NEXT_PUBLIC_PRO_WEBSITE
// for live
export const EMERALD_LAWNS_FERTILIZATION_PACKAGE = '633eff163b113529ffc0233a'
export const EMERALD_LAWNS_PEST_PACKAGE = '637d7139528c4be1a7ab8fdb'
export const EMERALD_LAWNS_WATER_PACKAGE = '660c51280e16a90882e69106'
// for local
// export const EMERALD_LAWNS_FERTILIZATION_PACKAGE='633eff163b113529ffc0233a'
// export const EMERALD_LAWNS_PEST_PACKAGE='637d7139528c4be1a7ab8fdb'

export const DEFAULT_STATES = {
  headingText: 'Get an instant lawn care estimate',
  buttonText: 'Check My Lawn',
  step1InfoText: "Doesn't look right? Feel free to adjust the measurement of your lawn.",
  paymentButtons: [
    {
      name: 'Pay Now',
      const: 'PAY_NOW',
      enabled: true,
      redirectUrl: 'https://www.deeplawn.com/widget/thank-you',
    },
    {
      name: 'Pay As You Go',
      const: 'PAY_AS_YOU_GO',
      enabled: true,
      redirectUrl: 'https://www.deeplawn.com/widget/thank-you',
    },
    {
      name: 'Pay By Check',
      const: 'PAY_BY_CHECK',
      enabled: false,
      redirectUrl: 'https://www.deeplawn.com/widget/thank-you',
    },
  ],
  widgetHeadingColor: {
    r: '0',
    g: '0',
    b: '0',
    a: '1',
  },
  inputFieldTextColor: {
    r: '0',
    g: '0',
    b: '0',
    a: '1',
  },
  widgetThemeColor: {
    r: '40',
    g: '166',
    b: '98',
    a: '1',
  },
  buttonColor: {
    r: '40',
    g: '166',
    b: '98',
    a: '1',
  },
  inputFieldColor: {
    r: '255',
    g: '255',
    b: '255',
    a: '1',
  },
  preTermText: 'I agree to',
  postTermText: '',
  cardSize: 'medium',
  packageCardSize: 'medium',
  leadFormHeadingText: 'While we are estimating your lawn please fill out',
  internalSources: [
    "Angi",
    "Community Impact",
    "Expo",
    "Facebook",
    "Sales Person",
    "Google",
    "InsideCoup",
    "Money Mailer",
    "NextDoor",
    "Postcard",
    "Referral",
    "TV Commercial",
    "YouTube",
    "Yelp",
    "Valpak",
  ]
};


export const PRO_QUEUES = {
  QUEUE_1: { name: 'Queue 1', id: 'QUEUE_1' },
  // QUEUE_2: { name: 'Queue 2', id: 'QUEUE_2' },
  // QUEUE_3: { name: 'Queue 3', id: 'QUEUE_3' },
  // QUEUE_4: { name: 'Queue 4', id: 'QUEUE_4' },
  // QUEUE_5: { name: 'Queue 5', id: 'QUEUE_5' },
  // QUEUE_6: { name: 'Queue 6', id: 'QUEUE_6' },
  // QUEUE_7: { name: 'Queue 7', id: 'QUEUE_7' }
}

export const DOCS_PER_PAGE_LIST = [
  { id: 1, value: '9', name: '9 / page' },
  { id: 2, value: '20', name: '20 / page' },
  { id: 5, value: '50', name: '50 / page' },
  { id: 6, value: '100', name: '100 / page' },
]

