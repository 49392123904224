
export const fbeventConstants={
  ADD_TO_CART:'AddToCart',
  INITIATE_CHECKOUT:'InitiateCheckout',
  COMPLETE_REGISTRATION:'CompleteRegistration',
  PURCHASE:'Purchase',
  PAGE_VIEW:'PageView'
}
export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const eventFb = (name, options = {}) => {
  console.log({event:name,options})
  let checkList=[fbeventConstants.COMPLETE_REGISTRATION,fbeventConstants.INITIATE_CHECKOUT,fbeventConstants.PURCHASE]
  //Triggers that should be sent only once
  if(checkList.includes(name)){
    if(!eventExistsToLocalStorage(name)){
      window.fbq('track', name, options)
      window.fbq('track', fbeventConstants.PAGE_VIEW)

      saveEventToLocalStorage(name, options)
    }
    else{
      console.log(`${name} already fired`)
    }
  }
   //Triggers which can be repeated
  else{
    window.fbq('track', name, options)
    window.fbq('track', fbeventConstants.PAGE_VIEW)
  }
}
export const saveEventToLocalStorage=(name, options = {})=>{
  const eventObject=JSON.stringify({name,options})
  window.localStorage.setItem(name,eventObject)
}
export const eventExistsToLocalStorage=(name)=>{
  const foundEvent=window.localStorage.getItem(name)
  if (foundEvent) return true
  return false
}