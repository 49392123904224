import React, { useState } from "react";
interface ButtonProps {
  primary?: boolean;
  backgroundColor?: string;
  size?: "small" | "medium" | "large";
  label: string;
  icon?: any;
  onClick?: () => void;
  href?: string;
  buttonStyles?:string;
  position?: "left" | "right";
}

export const AddButton = ({
  primary,
  size = "medium",
  backgroundColor,
  label,
  icon,
  href,
  buttonStyles,
  position = "left",
  ...props
}: ButtonProps) => {
  const[iconText, setIconText]= useState('text-gray-800')

  const buttonHover = (action:string) => {
    if(action == 'hover'){
      setIconText('text-green-500')
    } else {
      setIconText('text-gray-800')
    }
  }

  const mode = primary
    ? "bg-green-500 hover:bg-green-600 text-white hover:text-white md:w-fit"
    : "border-[1px] border-[#DFE6E2] hover:text-green-500 md:py-2";

    const iconElement = icon && (
      <span className={`flex items-center ${iconText}`}>
        {icon}
      </span>
    );

  return (
    <a
    onMouseOver={()=> buttonHover('hover')}
    onMouseOut={()=> buttonHover('out')}
      href={href}
      className={[
        `rounded-xl px-3 flex space-x-3 items-center justify-center text-sm text-gray-900 w-fit py-2 ${buttonStyles?buttonStyles:''}`,
        `storybook-button--${size}`,
        mode,
      ].join(" ")}
      style={{ backgroundColor }}
      {...props}
    >
      {position === "left" && iconElement}
      <span className="font-medium whitespace-nowrap">{label}</span>
      {position === "right" && iconElement}
    </a>
  );
};
