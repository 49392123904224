import mixpanel from 'mixpanel-browser';

export const MIX_PANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN

export const mixPanelEventConstants={
  ADD_TO_CART:'AddToCart',
  INITIATE_CHECKOUT:'InitiateCheckout',
  COMPLETE_REGISTRATION:'CompleteRegistration',
  PURCHASE:'Purchase',
  PAGE_VIEW:'PageView'
}

mixpanel.init(MIX_PANEL_TOKEN, { debug: true, track_pageview: true, persistence: 'localStorage' });

export const eventMixPanel = (name:string, options = {}) => {
  mixpanel.track(name);
}
export const identifyMixPanel = (userEmail:string) => {
  mixpanel.identify(userEmail);
}
