import { measurementTypes } from './measurement.types';
import sendRequest from '../../utils/useAxios';
import { setCurrentMeasurement } from '../waiting-area/waiting-area.actions';
import { GET_MEASUREMENT, GET_ORIGINAL_MEASUREMENT, GET_MEASUREMENT_STATUS, GET_MEASUREMENT_TAX, UPDATE_MEASUREMENT } from './measurement.api';

export const getMeasurement = (placeId) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(GET_MEASUREMENT.METHOD, `${GET_MEASUREMENT.PATH}/${placeId}`);
  if (!data.statusCode && data) {
    dispatch(setSuccess(measurementTypes.GET_MEASUREMENT, data));
  } else {
    dispatch(setError(data?.message));
  }
};

export const getOriginalMeasurement = (placeId) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(GET_ORIGINAL_MEASUREMENT.METHOD, `${GET_ORIGINAL_MEASUREMENT.PATH}/${placeId}`);
  if (data) {
    dispatch(setSuccess(measurementTypes.GET_ORIGINAL_MEASUREMENT, data));
  } else {
    dispatch(setError(data?.message));
  }
};

export const updateMeasurement = (measurement, currentMeasurement) => async (
  dispatch
) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(
    UPDATE_MEASUREMENT.METHOD,
    `${UPDATE_MEASUREMENT.PATH}/${measurement.placeId}`,
    measurement
  );
  if (!data.status && data) {
    dispatch(setSuccess(measurementTypes.UPDATE_MEASUREMENT_LIST, data.placeId));
    dispatch(setCurrentMeasurement(currentMeasurement));
  } else {
    dispatch(setError(data?.message));
  }
};
export const getMeasurementWithStatus = (status) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(GET_MEASUREMENT_STATUS.METHOD, `${GET_MEASUREMENT_STATUS.PATH}/${status}`);
  if (data && !data.statusCode) {
    dispatch(setSuccess(measurementTypes.SET_MEASUREMENT_LIST, data));
  } else {
    dispatch(setError(data?.message));
  }
};
export const savePlace = (place) => async (dispatch) => {
  dispatch({
    type: measurementTypes.SET_PLACE,
    payload: place,
  });
};
export const saveAnnotations = (annotations) => async (dispatch) => {
  dispatch({
    type: measurementTypes.SET_ANNOTATIONS,
    payload: annotations,
  });
};
export const saveMeasurement = (measurement) => async (dispatch) => {
  dispatch({
    type: measurementTypes.SET_MEASUREMENT,
    payload: measurement,
  });
};
export const setIsHDMeasurement = (isHdMeasurement) => async (dispatch) => {
  dispatch({
    type: measurementTypes.IS_HD_MEASUREMENT,
    payload: isHdMeasurement,
  });
};
export const saveSqFeet = (sqFeet) => async (dispatch) => {
  dispatch({
    type: measurementTypes.SET_SQ_FEET,
    payload: sqFeet,
  });
};
export const savePolygonsFeaturesSqFeet = (polygonsSqFeet) => async (dispatch) => {
  dispatch({
    type: measurementTypes.SET_POLYGONS_FEATURES_SQ_FEET,
    payload: polygonsSqFeet,
  });
};
export const resetMeasurement = () => async (dispatch) => {
  dispatch({
    type: measurementTypes.SET_INITIAL_MEASUREMENT,
  });
};
export const calculateTax = (address, id?, serviceAreaRegion?) => async (dispatch) => {
  dispatch(setLoading(true));
  let { data } = await sendRequest(GET_MEASUREMENT_TAX.METHOD, `${GET_MEASUREMENT_TAX.PATH}/${address}?contractorId=${id}&regionId=${serviceAreaRegion ? serviceAreaRegion : ''}`);
  console.log({taxManager: data})
  // if(!data || data.statusCode) data = 7
  dispatch(setSuccess(measurementTypes.SET_TAX, (data.statusCode) ? 7 : data));
};

export const setLoading = (type) => async (dispatch) => {
  dispatch({
    type: measurementTypes.SET_LOADING_MEASUREMENT,
    payload: type,
  });
};

export const setSuccess = (action, payload) => {
  return {
    type: measurementTypes[action],
    payload,
  }
}

export const setError = (payload) => {
  return {
    type: measurementTypes.SET_ERROR_MEASUREMENT,
    payload,
  }
}

export const setMeasurementForMap = (measurement) => dispatch => {
  dispatch({
    type: measurementTypes.SET_MEASUREMENT,
    payload: measurement
  })
  dispatch({
    type: measurementTypes.SET_PLACE,
    payload: {
      place_id: measurement?.placeId,
      formatted_address: measurement?.address,
      centerLat: measurement?.mapCenter?.lat,
      centerLng: measurement?.mapCenter?.lng,
      zoom: measurement?.mapZoom
    },
  });
  dispatch({
    type: measurementTypes.SET_ANNOTATIONS,
    payload: measurement?.mapPolygons,
  });
  dispatch({
    type: measurementTypes.SET_POLYGONS_FEATURES_SQ_FEET,
    payload: measurement?.polygonsFeaturesSquareFeet || {},
  });
  dispatch({
    type: measurementTypes.SET_SQ_FEET,
    payload: measurement?.squareFeet,
  });
}
export const setProMeasurementsCount = (count) => async (dispatch) => {
  dispatch({
    type: measurementTypes.SET_PRO_MEASUREMENTS_COUNT,
    payload: count,
  });
};