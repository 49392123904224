import { integrationTypes } from "./integrations.types";

const INITIAL_STATE = {
  paypal: null,
  loading: false,
  error:null,
  allIntegrations:[],
  allEnabled:[],
  tempData:null,
  gtagstatus: {
    Deeplawn_Search: false,
    Deeplawn_Viewed_Prices: false,
    Deeplawn_Source_Added: false,
    Deeplawn_Viewed_Checkout: false
  }
}

export const integrationsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case integrationTypes.SET_INTEGRATIONS:
      return {
        ...state,
        paypal: payload,
        loading: false
      }
      case integrationTypes.SET_INTEGRATIONS_ALL:
        let organizedIntegrations={}
        let allEnabled=[]
        if(payload){
          payload.map((each)=>{
            allEnabled.push(each.type)
            organizedIntegrations[each.type]=each
          })
        }
       
      return {
        ...state,
        allIntegrations: organizedIntegrations,
        allEnabled:allEnabled,
        loading: false
      }
    case integrationTypes.SET_INTEGRATIONS_LOADING:
      return {
        ...state,
        loading: payload
      }
    case integrationTypes.SET_INTEGRATIONS_ERROR:
      return {
        ...state,
        error: payload
      }
      case integrationTypes.SET_INTEGRATIONS_TEMP_DATA:
        return {
          ...state,
          tempData:payload
        }
    case integrationTypes.SET_GTAG_STATUS:
      return {
        ...state,
        gtagstatus: payload
      }
    default:
      return state;
  }
}