import React from 'react';
interface Props {
  fullScreen?: boolean
}

const LoadingAnimation = (props: Props) => (
  <div className={`flex justify-center items-center ${props?.fullScreen ? 'w-screen h-screen' : ''}`} >
    <img className='w-full h-full max-w-md max-h-md object-contain' src='/deep-lawn-preloader.gif' alt="Loading..." />
  </div>
);

export default LoadingAnimation;
