import { leadTypes } from "./leads.types";

const INITIAL_STATE = {
  lead: null,
  leads: null,
  loading: false,
  leadsUnCheckedCount: 0,
  leadByPlaceIdloading: true,
  leadWidgetAddress: null,
  isLeadCreated:false,
  fieldRoutesSubscriptionIDs: [],
  fieldRouteLead: null,
  jobberLead: null
}

export const leadsReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action

  switch (type) {
    case leadTypes.SET_LEAD:
      return {
        ...state,
        lead: payload,
        loading: false
      }
    case leadTypes.SET_LEADS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case leadTypes.SET_LEADS_BY_PLACE_ID_LOADING:
      return {
        ...state,
        leadByPlaceIdloading: payload
      }
    case leadTypes.SET_LEADS:
      const {leads, leadsUnCheckedCount} = payload
      return {
        ...state,
        leads,
        leadsUnCheckedCount,
        loading: false
      }
    case leadTypes.DELETE_LEAD_BY_ID:
      return {
        ...state,
        leads: state.leads?.filter(lead => lead._id !== payload)
      }
    case leadTypes.SET_WIDGET_LEAD_ADDRESS:
      return {
        ...state,
        leadWidgetAddress: payload
      }
    case leadTypes.SET_LEADS_INITIAL_STATE:
      return {
        ...INITIAL_STATE
      }
    case leadTypes.SET_IS_LEAD_CREATED:
      return {
        ...state,
        isLeadCreated: payload
      }
    case leadTypes.SET_FIELD_ROUTES_SUBSCRIPTION_IDS:
      return {
        ...state,
        fieldRoutesSubscriptionIDs:[...state.fieldRoutesSubscriptionIDs,payload]
      }
    case leadTypes.SET_FIELD_ROUTES_LEAD:
      return {
        ...state,
        fieldRouteLead: payload
      }
      case leadTypes.SET_JOBBER_LEAD:
      return {
        ...state,
        jobberLead: payload
      }
    default:
      return state;
  }
}