export const GET_SERVICE_AREA = {
  METHOD: 'GET',
  PATH: '/service-area/get-service-area',
};
export const CREATE_SERVICE_AREA = {
  METHOD: 'POST',
  PATH: '/service-area',
};
export const UPDATE_SERVICE_AREA = {
  METHOD: 'PATCH',
  PATH: '/service-area',
};
export const UPDATE_SPEICIFC_REGION_COUNT = {
  METHOD: 'PATCH',
  PATH: '/service-area/region',
};
export const UPDATE_COUNT_ZIPCODE = {
  METHOD: 'PATCH',
  PATH: '/service-area/update-zipcode-address-count',
};
export const REMOVE_SERVICE_AREAS = {
  METHOD: 'PATCH',
  PATH: '/service-area/remove-service-areas',
};
