import { IBoundary } from "./boundary.interface";
import { boundaryTypes } from "./boundary.types";

const INITIAL_STATE: IBoundary = {
  loading: false,
  boundary: [],
};

const BoundaryReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case boundaryTypes.SET_BOUNDARY:
      return {
        ...state,
        boundary: payload,
      };
    default:
      return state;
  }
};

export default BoundaryReducer;
