//LEAD
export const CREATE_REAL_GREEN_LEAD = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/create-lead'
}
export const GET_REAL_GREEN_LEAD = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/get-lead'
}
export const UPDATE_REAL_GREEN_LEAD = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/update-lead'
}

export const CHECK_REAL_GREEN_LEAD_BY_MAIL = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/check-by-email'
}

export const SEARCH_REAL_GREEN_CUSTOMER = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/search-customer'
}

export const SET_REAL_GREEN_ALL_PACKAGES = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/get-all-packages'
}

export const SET_REAL_GREEN_ALL_SERVICES = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/get-all-services'
}
export const SET_REAL_GREEN_ALL_DISCOUNTS = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/get-all-discounts'
}
export const SET_REAL_GREEN_ALL_SOURCES = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/get-all-sources'
}
export const ADD_REAL_GREEN_PACKAGES = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/add-package'
}
export const UPDATE_REAL_GREEN_PACKAGE = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/update-package'
}
export const GET_REAL_GREEN_SERVICES_IN_PACKAGE = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/get-services-in-programs'
}

export const SEND_REAL_GREEN_FLAGS = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/send-flags'
}
export const GET_REAL_GREEN_CUSTOMER_PACKAGES = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/get-customer-packages'
}

export const SEND_REAL_GREEN_DOCUMENT = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/send-files'
}

export const CREATE_REAL_GREEN_CUSTOMER = {
	METHOD: 'POST',
	PATH: '/integrations/real-green/create-customer'
}







