import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { getRecommendedServiceCardSize, getRecommendedCardSize } from '../../redux/widget/widget.selectors';

type fileUploader = {
    imageUrl: string, 
    imageLoading: boolean, 
    imageUpload: any, 
    recommendedSize: string,
    isService?:boolean
}

const FormFileUploader = ({ imageUrl, imageLoading, imageUpload, recommendedSize, isService }: fileUploader) => {

    const onDrop = useCallback((acceptedFiles) => {
        imageUpload(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        disabled: false,
        accept: "image/jpeg, image/png",
    });

    return (
        <div className=''>
            <br />
            <div {...getRootProps()} className='w-full border-1 border-green-400 border-dashed px-10 py-7 xl:px-20 xl:py-14 rounded-xl bg-green-50 opacity-75 hover:border-green-500 cursor-pointer' >
                <input {...getInputProps()} />
                {imageUrl && !imageLoading && (
                    <div className="mb-4 flex justify-center">
                        <img
                            className="preview"
                            src={imageUrl}
                            alt=""
                            width="300px"
                        />
                    </div>
                )}
                {!imageUrl && <img src="/ArrowUpTray.svg" alt="image icon" className='block max-w-[100px] mx-auto' />}
                <p className='mb-0 text-center text-sm mt-1 whitespace-wrap text-gray-900'>Drag images here or click to upload</p>
                <p className='mb-0 text-gray-700 text-xs text-center mt-1'>Formats .jpg and .png</p>
            </div>
            <span className='text-gray-900 text-sm'>{isService ? getRecommendedServiceCardSize(recommendedSize) : getRecommendedCardSize(recommendedSize) }</span>
        </div>
    )
};

export default FormFileUploader;
