import { EMERALD_TEST_ACCOUNT_CID } from '../../constants/app.constants';
import { ApplyServiceCustomDiscounts } from '../../utils/applyCustomDiscounts';
import { checkHasCustomQuote } from '../../utils/checkHasCustomQuote';
import getServicePriceWithDiscount from '../../utils/getServicePriceWithDiscount';
import { removeCountryCode } from '../../utils/removeCountryCode';
import sendRequest from '../../utils/useAxios';
import { setContactInfo } from '../widget/widget.actions';
import { isEmeraldLawn } from '../widget/widget.selectors';
import { ADD_REAL_GREEN_PACKAGES, CREATE_REAL_GREEN_CUSTOMER, CREATE_REAL_GREEN_LEAD, GET_REAL_GREEN_CUSTOMER_PACKAGES, GET_REAL_GREEN_LEAD, SEARCH_REAL_GREEN_CUSTOMER, SET_REAL_GREEN_ALL_DISCOUNTS, SET_REAL_GREEN_ALL_PACKAGES, SET_REAL_GREEN_ALL_SERVICES, SET_REAL_GREEN_ALL_SOURCES, UPDATE_REAL_GREEN_LEAD, UPDATE_REAL_GREEN_PACKAGE } from './real-green.api';
import { realGreenTypes } from './real-green.types';

export const getSourceCode = (source, allSources, customer) => {
  console.log({source, allSources})
  let foundSource = allSources?.find((each) => each.sourceDescription === source)
  if (foundSource?.id) {
    return foundSource.id
  }
  return null
}

export const getRealGreenSource=(sourceID, customer)=>{
  const sourcesAndCodes = {
    24: "Angi",
    40: "Community Impact",
    45: "Expo",
    50: "Facebook",
    103: "Sales Person",
    58: "Google",
    91: "InsideCoup",
    92: "Money Mailer",
    61: "NextDoor",
    67: "Postcard",
    78: "Referral",
    55: "TV Commercial",
    7: "YouTube",
    53: "Yelp",
    37: "Valpak",
  }

  if(isEmeraldLawn(customer?.settings) && sourcesAndCodes[sourceID]){
    return sourcesAndCodes[sourceID]
  }
  return null
}

export function isPresentReferrer(referrerInfo:any) {
  if (
    ["Angi","Community Impact","Facebook","InsideCoup","Money Mailer",
      "NextDoor","YouTube","Yelp","Valpak","Video","Mail","Web","Google"]
      .includes(referrerInfo?.selectedReferrer)) return true
  else return false
}

export const createRealGreenLead = ({ customerId, formData, address, address_components, location }) => async (dispatch, getState) => {
  dispatch(setRealGreenLoading(true))
  const state = getState()
  const { member: salesPerson } = state.teamMember
  const { user } = state.user;
  const { customer } = state.customer;
  const {allIntegrations}=state.integrations
  const {allSources}=state.realGreen

  const getValue = (type) => {
    let foundValue: any = null
    address_components?.map((each) => {
      if (each.types.includes(type)) {
        foundValue = { long_name: each.long_name, short_name: each.short_name }
      }
    })
    return foundValue

  }

  let phoneNumber = formData?.phone?.split('-').join('')
  phoneNumber = !phoneNumber ? "0000000000" : removeCountryCode(phoneNumber)
  
  let first_name_splitted = formData?.name?.split(" ")[0]
  let last_name_splitted = formData?.name?.split(" ")[1]
  const requestBody = {
    "name": formData?.firstName && formData?.lastName ? `${formData?.firstName} ${formData?.lastName}`:formData?.name,
    "zipcode": getValue('postal_code')?.long_name || "78728",
    "streetNumberAndName": `${getValue('street_number')?.long_name} ${getValue('route')?.long_name}`,
    "emailAddress": formData?.email,
    "cellPhoneNumber": phoneNumber,
    "sourceCode": getSourceCode(formData?.source,allSources,customer),
    "firstName": formData?.firstName?formData?.firstName:first_name_splitted,
    "lastName": formData?.lastName?formData?.lastName:last_name_splitted,
    "formattedAddress": {
      "houseNumber": getValue('street_number')?.long_name,
      "streetName": getValue('route')?.long_name,
      "addressLine2": null,
      "city": getValue('locality')?.long_name,
      "state": getValue('administrative_area_level_1')?.short_name,
      "zip": getValue('postal_code')?.long_name,
      "countryCode": "+1",
      "latitude": parseFloat(location?.lat?.toFixed(5)),
      "longitude": parseFloat(location?.lng?.toFixed(5)),
    },
    "employeeId": user?.integrationsData?.realGreen?.realGreenUsername ||  allIntegrations?.realGreen?.auth?.realGreenUserName,
    "clearMemoOnLastNameChange": true,
    "clearTechNotesOnLastNameChange": true,
  }
  const { data } = await sendRequest(CREATE_REAL_GREEN_LEAD.METHOD, CREATE_REAL_GREEN_LEAD.PATH, {
    customerId,
    requestBody,
  })

  if (data && !data.statusCode) {
    //Created lead,
    dispatch({
      type: realGreenTypes.SET_REAL_GREEN_LEAD,
      payload: {
        customerNumber: data.custNo,
      },
    });

    const realGreenCustomerBody = {
      "cust_no": data.custNo,
      "firstname": formData?.firstName ? formData?.firstName : first_name_splitted,
      "lastname": formData?.lastName ? formData?.lastName : last_name_splitted,
      "address": address,
      "streetno": getValue('street_number')?.long_name,
      "streetnm": getValue('route')?.long_name,
      "city": getValue('locality')?.long_name,
      "state": getValue('administrative_area_level_1')?.short_name,
      "zip": getValue('postal_code')?.long_name,
      "email": formData?.email,
      "phone_home": phoneNumber,
      "status": 0,
      "r_c": "R",
      "auditEmployeeID": user?.integrationsData?.realGreen?.realGreenUsername || allIntegrations?.realGreen?.auth?.realGreenUserName,
      "credit_lim": 0,
    }
  
    //Creating actual customer
    await sendRequest(CREATE_REAL_GREEN_CUSTOMER.METHOD, `${CREATE_REAL_GREEN_CUSTOMER.PATH}`, { customerId, requestBody: realGreenCustomerBody })
      .then(async (res) => {
          //Getting actual customer object
        await sendRequest(GET_REAL_GREEN_LEAD.METHOD, `${GET_REAL_GREEN_LEAD.PATH}`, { customerId, leadIdToCheck: res.data })
          .then(async (res) => {
            if (!res.statusCode) {
              if (res.data) {
                //Updating actual customer object
                const updatedCustomerData = {
                  ...res.data,
                  size: formData?.measurement?.squareFeet / 1000,
                  statusCharacter: "0",
                  estimateBy: user?.integrationsData?.realGreen?.realGreenUsername ||  allIntegrations?.realGreen?.auth?.realGreenUserName,
                  techNote: isEmeraldLawn(customer?.settings) ? `House Square Feet = ${formData?.measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0} sqFeet\r\n`:``
                }
                const requestBody = updatedCustomerData

                const { data } = await sendRequest(UPDATE_REAL_GREEN_LEAD.METHOD, UPDATE_REAL_GREEN_LEAD.PATH, { customerId, requestBody, memberId: salesPerson?.integrationsData?.realGreen?.realGreenUsername || user?.integrationsData?.realGreen?.realGreenUsername || '' })
                

                dispatch({
                  type: realGreenTypes.SET_REAL_GREEN_LEAD,
                  payload: {
                    customerData: updatedCustomerData,
                    alreadyExists: true
                  },
                });

                const customerPrograms = await getRealGreenCustomerProgramsByCustomerID(customerId, updatedCustomerData?.id)
                let selectedRealGreenIDs: string[] = []
                customerPrograms?.map((each: any) => {
                  selectedRealGreenIDs.push(each?.programCodeId)
                })
                dispatch({
                  type: realGreenTypes.SET_REAL_GREEN_SELECTED_ITEMS,
                  payload: customerPrograms
                });

                dispatch({
                  type: realGreenTypes.REPLACE_REAL_GREEN_SELECTED_IDS,
                  payload: selectedRealGreenIDs
                });

              }
            }

          })
          .catch((err) => {
            console.log('Customer Does not exist')

          })

      })
      .catch((err) => {
        console.log('customer not created')
      })
    dispatch(flagForNewRealGreen(false))
    dispatch(setRealGreenLoading(false))
  }
  else {
    dispatch(flagForNewRealGreen(false))
    dispatch(setRealGreenLoading(false))
  }

}

export const updateRealGreenLead = async ( customerId, prevCustomerObject, newProperties, salesPerson?:any,user?:any  )  => {

  const updatedCustomerData = { ...prevCustomerObject, ...newProperties }
  const requestBody = updatedCustomerData

  const { data } = await sendRequest(UPDATE_REAL_GREEN_LEAD.METHOD, UPDATE_REAL_GREEN_LEAD.PATH, { customerId, requestBody , memberId: salesPerson?.integrationsData?.realGreen?.realGreenUsername || user?.integrationsData?.realGreen?.realGreenUsername || '' })
  
  return { response:data, update:updatedCustomerData }
}

export const getRealGreenLead = (contractor_id) => async (dispatch) => {
  const { data } = await sendRequest(GET_REAL_GREEN_LEAD.METHOD, `${GET_REAL_GREEN_LEAD.PATH}${contractor_id}`)
  if (data && !data.statusCode) {
    dispatch({
      type: realGreenTypes.GET_REAL_GREEN_LEAD,
      payload: data.customFields,
    });
  }
}

export const getRealGreenAllPackages = (customerId) => async (dispatch) => {
  const { data } = await sendRequest(SET_REAL_GREEN_ALL_PACKAGES.METHOD, `${SET_REAL_GREEN_ALL_PACKAGES.PATH}`, { customerId })
  if (data && !data.statusCode) {
    dispatch({
      type: realGreenTypes.SET_REAL_GREEN_ALL_PACKAGES,
      payload: data,
    });
  }
}

export const getRealGreenAllServices = (customerId) => async (dispatch) => {
  const { data } = await sendRequest(SET_REAL_GREEN_ALL_SERVICES.METHOD, `${SET_REAL_GREEN_ALL_SERVICES.PATH}`, { customerId })
  if (data && !data.statusCode) {
    dispatch({
      type: realGreenTypes.SET_REAL_GREEN_ALL_SERVICES,
      payload: data,
    });
  }
}

export const getRealGreenAllDiscounts = (customerId) => async (dispatch) => {
  const { data } = await sendRequest(SET_REAL_GREEN_ALL_DISCOUNTS.METHOD, `${SET_REAL_GREEN_ALL_DISCOUNTS.PATH}`, { customerId })
  if (data && !data.statusCode) {
    dispatch({
      type: realGreenTypes.SET_REAL_GREEN_ALL_DISCOUNTS,
      payload: data,
    });
  }
}

export const getRealGreenAllSources = (customerId) => async (dispatch) => {
  const { data } = await sendRequest(SET_REAL_GREEN_ALL_SOURCES.METHOD, `${SET_REAL_GREEN_ALL_SOURCES.PATH}`, { customerId })
  if (data && !data.statusCode) {
    dispatch({
      type: realGreenTypes.SET_REAL_GREEN_ALL_SOURCES,
      payload: data,
    });
  }
}

export const setRealGreenLoading = (loading) => async (dispatch) => {
  dispatch({
    type: realGreenTypes.RG_LOADING,
    payload: loading,
  });
}

export const getRealGreenSize = (measurement, programDefinitionID) => {
  if (programDefinitionID === 168) {
    if (measurement?.propertyInfo?.propertyAttributes?.buildgSqFt) {
      return measurement?.propertyInfo?.propertyAttributes?.buildgSqFt / 1000
    }
      return measurement?.squareFeet / 1000
  }
    return measurement?.squareFeet / 1000
}

export const addRealGreenItems = ({ customerId, items, isFromDashboard = false }) => async (dispatch, getState) => {
  const state=getState()
  const { member: salesPerson } = state.teamMember
  dispatch(setRealGreenLoading(true))
  const {orderUser}=state.widget
  const {allIntegrations}=state.integrations

  const { currentleadData, selectedIds } = state.realGreen;
  const { quantity, boundary, paymentType: cartPaymentType, customQuotes, customDiscounts } = state.widget;
  const { user } = state.user;
  const { measurement, sqFeet, polygonsFeaturesSqFeet } = state.measurement;
  const date = new Date()
  if (currentleadData?.customerNumber) {
    let newItemsToAdd = []
    if(selectedIds.length<=0){
      newItemsToAdd=items
    }
    else {
      items.map((eachItem) => {
        if (eachItem?.realGreenItem && eachItem?.realGreenItem?.programDefinitionID) {
          const temp_selected_package = selectedIds.find(item => item === eachItem?.realGreenItem?.programDefinitionID)
          if (!temp_selected_package) {
            newItemsToAdd.push(eachItem)
          }
        }
      })
    }
    

    for (let eachItem of newItemsToAdd) {
      if (eachItem?.realGreenItem && eachItem?.realGreenItem?.programDefinitionID) {
        dispatch({
          type: realGreenTypes.SET_REAL_GREEN_SELECTED_IDS,
          payload: [eachItem?.realGreenItem?.programDefinitionID]
        });
  
        //adding actual package object
        let preparedPackageData = {
          "cust_no": currentleadData?.customerNumber,
          "size": getRealGreenSize(measurement,eachItem?.realGreenItem?.programDefinitionID),
          "route": currentleadData?.customerData?.routeCode || null,
          "status": eachItem?.realGreenItem?.programCode === "EST" ? "1" : "0",
          "soldby1": " ",
          "reqtakenby": user?.integrationsData?.realGreen?.realGreenUsername || allIntegrations?.realGreen?.auth?.realGreenUserName,
          "billtype": "R",
          "disccode": eachItem?.realGreenDiscount ? eachItem?.realGreenDiscount?.id : null,
          "autorenew": true,
          "technote": " ",
          "custnote": "",
          // "season": 2023,
          "season":date.getFullYear(),
          "sourcecd": currentleadData?.customerData?.sourceCD,
          "rejcode": null,
          "callahead": null,
          // "datesold": "2023-02-09T14:28:24.792Z",
          "datesold": new Date().toISOString(),
          "auditEmployeeID": user?.integrationsData?.realGreen?.realGreenUsername || allIntegrations?.realGreen?.auth?.realGreenUserName,
          "progdefid": eachItem?.realGreenItem?.programDefinitionID
        }
        const requestBody = preparedPackageData

        let isCustomQuote = false
        let price = null
        if (!eachItem?.services?.length) {
          const buildgSqFt = measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0
          isCustomQuote = checkHasCustomQuote(eachItem, quantity, boundary, sqFeet, buildgSqFt, polygonsFeaturesSqFeet)
          if (isCustomQuote && isFromDashboard) {
            const { discountedPrice } = getServicePriceWithDiscount(eachItem, quantity, sqFeet, cartPaymentType, boundary, customQuotes, buildgSqFt, polygonsFeaturesSqFeet)
            price = ApplyServiceCustomDiscounts(customDiscounts, eachItem, discountedPrice, cartPaymentType, quantity)
          }
        }

        const { data } = await sendRequest(ADD_REAL_GREEN_PACKAGES.METHOD, ADD_REAL_GREEN_PACKAGES.PATH, { customerId, requestBody, price })
      }
    }

    const customerPrograms = await getRealGreenCustomerProgramsByCustomerID(customerId, currentleadData?.customerNumber)
    let selectedRealGreenIDs :string[]=[]
    customerPrograms?.map((each:any)=>{
        selectedRealGreenIDs.push(each?.programCodeId)
      })
      dispatch({
        type: realGreenTypes.SET_REAL_GREEN_SELECTED_ITEMS,
        payload: customerPrograms
      });
   
  dispatch({
    type: realGreenTypes.REPLACE_REAL_GREEN_SELECTED_IDS,
    payload: selectedRealGreenIDs
  });
    dispatch({
      type: "RG_SERVICES_ADDED",
    });

    dispatch(setRealGreenLoading(false))
    const hasEstimateService = newItemsToAdd.find(eachItem => eachItem?.realGreenItem?.programCode === "EST")
    if(hasEstimateService && currentleadData.customerData.statusCharacter !== "1"){
      //Updating actual customer object
      const { response, update } = await updateRealGreenLead(customerId, currentleadData?.customerData, {statusCharacter: "1"}, salesPerson, user)
      if(response && !response.statusCode){
        dispatch({
          type: realGreenTypes.SET_REAL_GREEN_LEAD,
          payload: {
            customerData: update,
            alreadyExists: true
          }
        });
      }
    }
    
  }
  else {
    console.log("Customer does not exist")
  }

}

export const updateRealGreenItems = async (customer,user,selectedItems, cart, propertiesToUpdate) => {

  const allCartItems = [...cart?.services, ...cart?.packages]
  const newItemsToUpdate=selectedItems
  
  for (let eachItem of newItemsToUpdate) {
    //Updating actual customer object

    const updatedPackageData = { ...eachItem, ...propertiesToUpdate }
    const requestBody = updatedPackageData

    const { data } = await sendRequest(UPDATE_REAL_GREEN_PACKAGE.METHOD, UPDATE_REAL_GREEN_PACKAGE.PATH, { customerId: customer?._id, requestBody })
  }
}


export const getSource = (sourceID: any, allSources: any) => {
  const sourcesAndCodes: any = {
    24: "Angi",
    40: "Community Impact",
    45: "Expo",
    50: "Facebook",
    103: "Sales Person",
    58: "Google",
    91: "InsideCoup",
    92: "Money Mailer",
    61: "NextDoor",
    67: "Postcard",
    78: "Referral",
    55: "TV Commercial",
    7: "YouTube",
    53: "Yelp",
    37: "Valpak",
  }
  if (allSources && allSources.length) allSources.forEach((temp_source: any)=> sourcesAndCodes[temp_source.id] = temp_source.sourceDescription)
  if (sourcesAndCodes[sourceID]) return sourcesAndCodes[sourceID]
  return null
}

export const setCustomerDataBySearch = ({ customerId, data }) => async (dispatch, getState) => {
  const state = getState()
  const { allSources } = state.realGreen
  dispatch({
    type: realGreenTypes.SET_REAL_GREEN_LEAD,
    payload: {
      customerNumber: data[0]?.id || data.id,
      customerData: data[0],
      alreadyExists: true
    },
  });

  const customerPrograms = await getRealGreenCustomerProgramsByCustomerID(customerId, data[0]?.id || data.id)
  let selectedRealGreenIDs = []
  customerPrograms?.map((each: any) => {
    selectedRealGreenIDs.push(each?.programCodeId)
  })
  dispatch({
    type: realGreenTypes.SET_REAL_GREEN_SELECTED_ITEMS,
    payload: customerPrograms
  });
  dispatch({
    type: realGreenTypes.SET_REAL_GREEN_SELECTED_IDS,
    payload: selectedRealGreenIDs
  });
  const contactData = {
    firstName: data[0].firstName,
    lastName: data[0].lastName,
    email: data[0].email,
    phone: data[0].phones[0]?.number,
    name: (data[0].firstName && data[0].lastName) ?
      `${data[0].firstName} ${data[0].lastName}`
      : '',
    ...(getSource(data[0]?.sourceCD, allSources) && { source: getSource(data[0]?.sourceCD, allSources) })
  };

  dispatch(setContactInfo(contactData));
}

export const getRealGreenCustomerProgramsByCustomerID = async (contractor_id, realGreenCustomerId) => {

  const { data } = await sendRequest(GET_REAL_GREEN_CUSTOMER_PACKAGES.METHOD, GET_REAL_GREEN_CUSTOMER_PACKAGES.PATH, {
    customerId: contractor_id,
    requestBody: { realGreenCustomerId }
  })
 
  return data

}

export const handleResetRealGreen = () => async (dispatch, getState) => {
  dispatch({
    type: realGreenTypes.SET_REAL_GREEN_INITIAL_STATE,
  });
}

export const flagForNewRealGreen = (flag) => async (dispatch, getState) => {
  dispatch({
    type: realGreenTypes.SET_REAL_GREEN_FLAG_FOR_NEW,
    payload:flag
  });
}


