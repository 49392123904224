import React from "react";
interface TotalObj {
    label: string;
    amount: string;
    loading: boolean
  }
  interface TabsProps {
    data: TotalObj;
  }
  export default function Total({ data, ...props }: TabsProps) {
    return (
      <div className={`${data?.loading ? 'bg-gray-200 animate-pulse h-20' : 'bg-green-900'} w-full min-w-[200px] md:w-fit rounded-xl p-4`}>
        {!data?.loading &&
          <>
            <h1 className="text-xl md:text-2xl font-bold text-green-100">{data.amount}</h1>
            <p className="mb-0 text-white text-xs uppercase ">Total {data.label}</p>
          </>
        }
      </div>
    );
  }