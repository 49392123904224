import { orderTypeConst } from '../../constants/orderType.constants';
import { toggleInArrayOfObjects } from '../../utils/toogleInArrayOfObjects';
import { contractorTypes } from './contractor.types';

const INITIAL_STATE = {
  loading: false,
  orders: null,
  leads: null,
  productSelection: 'SERVICES',
  overviewFilter: 'Today',
  overviewData: {
    ordersData: {sum: 0, data: []},
    quotesData: {count: 0, data: []},
    searchesData: {count: 0, data: []},
  },
  overviewDataLoading: false,
  ordersFilter: 'All',
  totalCount: 0,
  error: null,
  marketingTabsFilter: 'TEMPLATES',
  hasTeamMembers: false
};

const contractorReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case contractorTypes.SET_CONTRACTOR_ORDERS:
      return {
        ...state,
        orders: payload.orders?.map((item) => {
          return { ...item, expandable: false };
        }),
        totalCount: payload.totalCount,
        error: null,
      };
      case contractorTypes.RESET_CONTRACTOR_ORDERS:
      return {
        ...state,
        orders:null,
        totalCount: 0,
        // error: null,
      };
    case contractorTypes.SET_LOADING_CONTRACTOR:
      return {
        ...state,
        loading: action.payload
      };
    case contractorTypes.SET_OVERVIEW_LOADING_CONTRACTOR:
      return {
        ...state,
        overviewDataLoading: action.payload
      };
    case contractorTypes.SET_ORDERS_FILTER:
      return {
        ...state,
        ordersFilter: payload,
      };
    case contractorTypes.SET_CONTRACTOR_ORDERS_TOTAL_COUNT:
      return {
        ...state,
        totalCount: payload,
      };
    case contractorTypes.SET_OVERVIEW_FILTER:
      return {
        ...state,
        overviewFilter: payload,
      };
    case contractorTypes.TOGGLE_ORDER_EXPAND:
      return {
        ...state,
        orders: toggleInArrayOfObjects(state.orders, payload, 'expandable'),
      };
    case contractorTypes.CHANGE_PRODUCT_SELECTION:
      return {
        ...state,
        productSelection: payload,
      };
    case contractorTypes.SET_OVERVIEW_DATA:
      return {
        ...state,
        overviewData: payload,
        error: null,
      };
    case contractorTypes.SET_ERROR_CONTRACTOR:
      return {
        ...state,
        error: payload,
      };
    case contractorTypes.DELETE_ORDER_BY_ID:
      return {
        ...state,
        orders: state.orders?.filter((order) => order._id !== payload),
      };
    case contractorTypes.SET_MARKETING_TABS_FILTER:
      return {
        ...state,
        marketingTabsFilter: payload,
      };
    case contractorTypes.SET_HAS_TEAM_MEMBERS:
      return {
        ...state,
        hasTeamMembers: payload
      }
    default:
      return state;
  }
};

export default contractorReducer;
