import { ThemeProvider } from '@material-ui/core';
import Cookies from 'js-cookie';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import 'react-modal-video/scss/modal-video.scss';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import LoadingAnimation from '../components/loading/loadingAnimation.component';
import SEO from '../next-seo.config';
import { GET_CUSTOMER } from '../redux/customer/customer.api';
import { useStore } from '../redux/store';
import { routes } from '../routes';
import '../styles/globals.css';
import '../styles/prism.css';
import { Theme } from '../theme/theme';
import sendRequest from '../utils/useAxios';
import Dashboard from './contractor/home';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const store = useStore(pageProps.initialReduxState);
  const persistor = persistStore(store, {}, () => {
    persistor.persist();
  });
  const checkMigrated = async() => {
    if (typeof window !== 'undefined') {

      // Migration: Check if user is migrated and redirect to V2 if true
      const { data } = await sendRequest(GET_CUSTOMER.METHOD, GET_CUSTOMER.PATH);
      if(data?.isMigrated === true) {
        if(data?.settings?.enableCommercialMeasurements === true){
          return
        }
        if(router.pathname.includes('quote')) {
          return 
        }
        Cookies.set('migrated', 'true');
        router.push( process.env.NEXT_PUBLIC_V2_APP_URL ?? '' );
      }else{
        Cookies.remove('migrated');
        router.reload();
      }
    }
  }

  // Migration: Set the migrate flag in cookies of V1 if user is migrated 
  const migratedCookie = Cookies.get('migrated');
  if (migratedCookie) checkMigrated();

  const checkRole = () => {
    if (typeof window !== 'undefined') {
      const u = localStorage.getItem("persist:primary");
      const us = JSON.parse(u);
      if (!us) return

      const { user } = JSON.parse(us.user);
      if (user === null) return;

      let isAllowed = false;
      const routeObj = routes[router.pathname];

      user?.roles.map((role) => {
        if (routeObj === undefined) {
          isAllowed = true
        }
        else if (routeObj.roles.includes(role)) {
          isAllowed = true
        }
      });

      if (!isAllowed) {
        isAllowed = false;
      };

      return isAllowed;
    }
  }



  const DisplayDashboard = () => {
    if (migratedCookie) {
      // Migration: If migrated cookie found display loading until redirects to V2 
      checkMigrated();
      return <LoadingAnimation fullScreen={true} />
    } else {
      if (checkRole()) {
        return <Component {...pageProps} />
      } else {
        return <Dashboard {...pageProps} />
      }
    }
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        {
          router.pathname.includes('contractor') || router.pathname.includes('worker') || router.pathname.includes('admin')
            ? (
              /* eslint-disable react/jsx-props-no-spreading */
              <PersistGate loading={<LoadingAnimation fullScreen={true} />} persistor={persistor}>
                <DisplayDashboard />
              </PersistGate>
            )
            : (
              /* eslint-disable react/jsx-props-no-spreading */
              <>
                <DefaultSeo {...SEO} />
                {/* Migration: If migrated cookie found display loading until redirects to V2  */}
                {migratedCookie ? <LoadingAnimation fullScreen={true} /> : <Component {...pageProps} />}
              </>
            )
        }
      </ThemeProvider>
    </Provider>
  );
}

export default MyApp;
