export default {
    titleTemplate: '%s - Deep Lawn | E-Commerce tools for lawn care Companies',
    description: "E-Commerce tools for lawn care Companies. At Deep Lawn you can sell your services online in less than a minute",
    openGraph: {
        title: '%s - Deep Lawn | E-Commerce tools for lawn care Companies',
        description: "E-Commerce tools for lawn care Companies. At Deep Lawn you can sell your services online in less than a minute",
        type: 'website',
        locale: 'en_US',
        site_name: 'Deep Lawn',
        images: [
        {
            url: 'https://app.deeplawn.com/deeplawnlogo.png',
            width: 800,
            height: 600,
            alt: 'Deep Lawn | E-Commerce tools for lawn care Companies',
            type: 'image/png',
        }
    
        ]

    },
  };