'use client'
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { Button } from '@material-ui/core';
import classnames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, PropsWithChildren, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { rolesConstants } from '../../constants/roles.constants';
import ProfileMenu from '../../library/stories/Menu/ProfileMenu';
import { resetMeasurement } from '../../redux/measurement/measurement.actions';
import { logoutUser } from '../../redux/user/user.actions';
import { resetWidget } from '../../redux/widget/widget.actions';
import styles from './dashboard.layout.module.scss';
import { isPro } from '../../redux/widget/widget.selectors';

import { AiOutlineMenu } from 'react-icons/ai';
import HallwayIcon from '../../components/worker/HallwayIcon';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { AccountIcon, BrandingIcon, CartIcon, DashboardIcon, DensityBlaster, IntegrationIcon, LogoutIcon, MarketingIcon, MeasurementsIcon, ProIcon, SearchIcon, ServiceArea, ServicesIcon, SupportIcon, TutorialsIcon } from '../../library/stories/icons/icons';
import { routeConstants } from '../../routes';
import { breakpoints } from '../../utils/app-utils';
import { BsPostcard } from 'react-icons/bs';
import { getCustomer } from '../../redux/customer/customer.actions';
import Image from 'next/image';

// Define the SIDEBAR_STATE_KEY before using it
const SIDEBAR_STATE_KEY = 'sidebarExpandedState';

// Helper function to concatenate class names
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface Props {
  isNotFooter?: boolean;
}

export const DashboardLayout = (props: PropsWithChildren<Props>) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();
  const { user } = useSelector((state: any) => state.user);
  const { customer, loading } = useSelector((state: any) => state.customer);
  const { proMeasurementsCount } = useSelector((state: any) => state.measurement);
  const [displayLayout, setDisplayLayout] = useState(false);

  // Define tablet breakpoint for clarity
  const TABLET_MIN = breakpoints.TABLET?.width?.min || 768; // Fallback to 768px if not defined
  const TABLET_MAX = breakpoints.TABLET?.width?.max || 1023; // Fallback to 1023px if not defined
  const LARGE_SCREEN_WIDTH = 1240;

  // Determine if the current screen is a tablet
  const isTablet = width >= TABLET_MIN && width <= TABLET_MAX;

  const isWidthForButton = width !== undefined && width > 1024 && width < 1240;

  // Initialize isSidebarOpen based on localStorage or default to window width
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    if (typeof window !== 'undefined') {
      const savedState = localStorage.getItem(SIDEBAR_STATE_KEY);
      if (savedState !== null) {
        return savedState === 'true';
      }
    }
    return true; // Default to open if no saved state
  });
  
  // Effect to update sidebar state once width is available
  const prevWidthRef = useRef(width);

  useEffect(() => {
    if (typeof window !== 'undefined' && width !== undefined) {
      const savedState = localStorage.getItem(SIDEBAR_STATE_KEY);
      const isLargeScreen = width >= LARGE_SCREEN_WIDTH;
      const wasLargeScreen = prevWidthRef.current >= LARGE_SCREEN_WIDTH;

      if (isLargeScreen !== wasLargeScreen) {
        // Screen size crossed the threshold, reset sidebar state
        const newIsSidebarOpen = isLargeScreen; // Expand on large screens, collapse on small screens
        setIsSidebarOpen(newIsSidebarOpen);
        // Update localStorage
        localStorage.setItem(SIDEBAR_STATE_KEY, String(newIsSidebarOpen));
      } else if (savedState !== null) {
        // Screen size didn't change range, use saved preference
        setIsSidebarOpen(savedState === 'true');
      } else {
        // No saved preference, use default for current screen size
        setIsSidebarOpen(isLargeScreen);
      }

      // Update the previous width
      prevWidthRef.current = width;
    }
  }, [width]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [pro, setPro] = useState(false);
  const [isLarge, setIsLarge] = useState(false);

  // Function to toggle sidebar state and update localStorage
  const toggleSidebar = (newState: boolean) => {
    setIsSidebarOpen(newState);
    if (typeof window !== 'undefined') {
      localStorage.setItem(SIDEBAR_STATE_KEY, String(newState));
    }
  };

  const contractor = user?.roles[0] === rolesConstants.CONTRACTOR;
  const sales = user?.roles[0] === rolesConstants.SALES;
  const robin = user?.roles?.includes(rolesConstants.ROBIN);
  const worker = user?.roles?.includes(rolesConstants.WORKER);
  const admin = user?.roles?.includes(rolesConstants.ADMIN);

  const isContractor = user?.roles?.includes(rolesConstants.CONTRACTOR);
  const hasDensityBlaster = (customer?.settings?.enableDensityBlaster) || false;

  // Fetch customer data if not available
  useEffect(() => {
    if (!customer) dispatch(getCustomer());
  }, [customer, dispatch]);

  // Set 'pro' state based on window properties
  useLayoutEffect(() => {
    if (router) {
      setPro(isPro(window));
    }
  }, [router]);

  // Control layout display after loading
  useEffect(() => {
    setTimeout(() => {
      setDisplayLayout(true);
    }, 500);
  }, [loading]);

  // Extracting user name and company name for display
  let oneWordFromName = user?.name.length > 15 ? user?.name?.split(/\s+/).slice(0, 1).join(" ") : user?.name;

  let twoWordsFromUserName = user ? user.name.split(/\s+/).slice(0, 2).join(" ") : '';
  let twoWordsFromCompanyName = user ? user.company.split(/\s+/).slice(0, 2).join(" ") : '';
  let shortName = oneWordFromName ? oneWordFromName.split(' ').map((word: any) => word.charAt(0)) : '';
  let shortCompanyName = twoWordsFromCompanyName ? twoWordsFromCompanyName.split(' ').map((word: any) => word.charAt(0)) : '';

  // Define page constants with icons and routes
  const pageConstants = {
    DASHBOARD: { ...routeConstants.DASHBOARD, icon: <DashboardIcon /> },
    SEARCH: {
      ...routeConstants.SEARCH,
      href: `${routeConstants.SEARCH.basePath}/${user?._id}`,
      pathname: routeConstants.SEARCH.pathname,
      icon: <SearchIcon />,
    },
    ROBIN_SEARCH: {
      ...routeConstants.ROBIN_SEARCH,
      href: `${routeConstants.ROBIN_SEARCH.basePath}/${user?.owner_profile_id || user?._id}`,
      icon: <SearchIcon />,
    },
    MANAGER_SALES_SEARCH: {
      ...routeConstants.MANAGER_SALES_SEARCH,
      href: `${routeConstants.MANAGER_SALES_SEARCH.basePath}/${user?.owner_profile_id}`,
      icon: <SearchIcon />,
    },
    SERVICES: {
      ...routeConstants.SERVICES,
      icon: <ServicesIcon />,
    },
    SERVICE_AREA: {
      ...routeConstants.SERVICE_AREA,
      icon: <ServiceArea />,
    },
    INTEGRATIONS: {
      ...routeConstants.INTEGRATIONS,
      icon: <IntegrationIcon />,
    },
    BRANDING: {
      ...routeConstants.BRANDING,
      icon: <BrandingIcon />,
    },
    MEASUREMENTS: {
      ...routeConstants.MEASUREMENTS,
      icon: <CartIcon />,
    },
    MARKETING: {
      ...routeConstants.MARKETING,
      icon: <MarketingIcon />,
    },
    DENSITY_BLASTER: {
      ...routeConstants.DENSITY_BLASTER,
      icon: <DensityBlaster />
    },
    PROFILE: {
      ...routeConstants.PROFILE,
      onClick: () => router.push(routeConstants.PROFILE.pathname),
      icon: <AccountIcon />
    },
    MY_ACCOUNT: {
      ...routeConstants.MY_ACCOUNT,
      onClick: () => router.push(routeConstants.MY_ACCOUNT.pathname),
      icon: <AccountIcon/>
    },
    SUPPORT: {
      ...routeConstants.SUPPORT,
      icon: <SupportIcon />
    },
    TUTORIALS: {
      ...routeConstants.TUTORIALS,
      onClick: () => router.push(routeConstants.TUTORIALS.pathname),
      icon: <TutorialsIcon />
    },
    LOGOUT: {
      ...routeConstants.LOGOUT,
      onClick: () => dispatch(logoutUser(router)),
      icon: <LogoutIcon />
    },
    HALLWAY: {
      ...routeConstants.WORKER.HALLWAY,
      icon: <HallwayIcon src="/assets/icons/hallway.png" alt="hallway icon" />
    },
    ADMIN: {
      SEARCH_FEED: {
        ...routeConstants.ADMIN.SEARCH_FEED,
        icon: <SearchIcon />
      },
      WORKERS_LIST: {
        ...routeConstants.ADMIN.WORKERS_LIST,
        icon: <ServiceArea />
      },
      WORKERS: {
        ...routeConstants.ADMIN.WORKERS,
        icon: <IntegrationIcon />
      },
      CONTRACTORS: {
        ...routeConstants.ADMIN.CONTRACTORS_LIST,
        icon: <ServicesIcon />
      },
      INTEGRATIONS: {
        ...routeConstants.ADMIN.INTEGRATIONS,
        icon: <IntegrationIcon />
      }
    }
  };

  // Define navigation arrays based on user roles
  const contractorRoutes = [
    pageConstants.DASHBOARD,
    pageConstants.SEARCH,
    pageConstants.SERVICES,
    pageConstants.SERVICE_AREA,
    pageConstants.INTEGRATIONS,
    pageConstants.BRANDING,
    pageConstants.MEASUREMENTS,
    pageConstants.MARKETING
  ];

  let contractorNavigation = contractorRoutes;

  const managerRoutes = [
    pageConstants.DASHBOARD,
    pageConstants.MANAGER_SALES_SEARCH,
    pageConstants.SERVICES,
    pageConstants.INTEGRATIONS,
    pageConstants.MEASUREMENTS,
    pageConstants.MARKETING,
  ];

  const adminNavigation = [
    pageConstants.ADMIN.SEARCH_FEED,
    pageConstants.ADMIN.WORKERS_LIST,
    pageConstants.ADMIN.WORKERS,
    pageConstants.ADMIN.CONTRACTORS,
    pageConstants.ADMIN.INTEGRATIONS
  ];

  const managerNavigation = managerRoutes;

  const salesNavigation = [
    pageConstants.DASHBOARD,
    pageConstants.MANAGER_SALES_SEARCH,
    pageConstants.MARKETING,
    pageConstants.MEASUREMENTS,
  ];

  const userNavigation = [
    pageConstants.PROFILE,
    pageConstants.LOGOUT
  ];

  const contractorDropDownNavigation = [
    pageConstants.MY_ACCOUNT,
    pageConstants.LOGOUT
  ];

  const adminDropdownNavigation = [
    pageConstants.LOGOUT
  ];

  const workerNavigation = [
    { name: 'Hallway', href: '/worker/hallway', pathname: '/worker/hallway', icon: <HallwayIcon src="/assets/icons/hallway.png" alt="hallway icon" /> },
    { name: 'Pro Hallway', href: '/worker/prohallway', pathname: '/worker/prohallway', icon: <HallwayIcon src="/assets/icons/ram.png" alt="pro hallway icon" /> },
    { name: 'Density Blaster Hallway', href: '/worker/densityblasterhallway', pathname: '/worker/densityblasterhallway', icon: <BsPostcard className='h-8 w-8' /> },
  ];

  const workerDropdownNavigation = [
    pageConstants.LOGOUT
  ];

  const robinNavigation = [
    pageConstants.ROBIN_SEARCH
  ];

  const robinDropdownNavigation = [
    pageConstants.LOGOUT
  ];

  const proNavigation = [
    { name: 'Measurements', href: '/contractor/pro-measurements', pathname: '/contractor/pro-measurements', icon: <MeasurementsIcon /> },
    {
      name: 'Search',
      href: `/contractor/search/${user?.owner_profile_id || user?._id}`,
      pathname: '/contractor/search/[id]',
      icon: <SearchIcon />,
    },
    {
      name: 'Settings',
      href: '/contractor/pro-settings',
      pathname: '/contractor/pro-settings',
      icon: <MarketingIcon />,
    },
  ];

  // Determine navigation and dropdown based on user roles
  let navigation = contractor
  ? contractorNavigation
  : sales
    ? salesNavigation
    : worker ? workerNavigation : managerNavigation;

if (customer?.settings?.enableCommercialMeasurements) {
  const isProExists = navigation?.filter(singleNav => singleNav?.name == 'Commercial Measurement')
  if (!isProExists.length) {
    navigation.push({
      name: 'Commercial Measurement',
      href: `${process.env.NEXT_PUBLIC_PRO_WEBSITE}`,
      pathname: `${process.env.NEXT_PUBLIC_PRO_WEBSITE}`,
      icon: <ProIcon />,

    })
  }
}

  let dropDownNav = contractor ? contractorDropDownNavigation : worker ? workerDropdownNavigation : userNavigation;

  if (robin) {
    navigation = robinNavigation;
    dropDownNav = robinDropdownNavigation;
  }
  if (admin) {
    navigation = adminNavigation;
    dropDownNav = adminDropdownNavigation;
  }

  if (pro) {
    navigation = proNavigation;
    dropDownNav = robinDropdownNavigation; // Assuming proDropdownNav is similar to robinDropdownNav
  }

  // Add Support to navigation
  navigation = [...navigation, pageConstants.SUPPORT];

  const profileIcon = contractor ? customer?.customerLogo : user?.avatar;

  // Reset widgets and measurements on specific route change
  useEffect(() => {
    if (router.pathname === routeConstants.SEARCH.pathname) {
      dispatch(resetWidget());
      dispatch(resetMeasurement());
    }
  }, [router.pathname, dispatch]);

  // Redirect based on user roles
  useEffect(() => {
    if (user) {
      if (user.roles.includes('UNPAIDCUSTOMER')) {
        router.replace(routeConstants.PRICING.pathname);
      } else if (user.roles.includes(rolesConstants.ROBIN)) {
        router.replace(`${routeConstants.ROBIN_SEARCH.basePath}/${user?.owner_profile_id || user._id}`);
      }
    }
  }, [user, router]);

  // Handle sidebar state on mount and window resize
  const handleSidebar = useCallback(() => {
    if (typeof window !== 'undefined' && localStorage.getItem(SIDEBAR_STATE_KEY) === null) {
      const isLargeScreen =
          width >= breakpoints.LAPTOP.width.min &&
          width <= breakpoints.LAPTOP.width.max &&
          height >= breakpoints.LAPTOP.height.min &&
          height <= breakpoints.LAPTOP.height.max;

      const isTabletScreen = isTablet; // Using the isTablet variable defined earlier

      if (isTabletScreen) {
        toggleSidebar(false); // Ensure sidebar is closed on tablets/iPads
      } else {
        toggleSidebar(isLargeScreen);
      }

      setIsLarge(isLargeScreen);
    }
  }, [width, height, isTablet]);

  useLayoutEffect(() => {
    handleSidebar();
  }, [handleSidebar]);

  // Handle sidebar toggle via chevron
  const handleChevronClick = () => {
    toggleSidebar(!isSidebarOpen);
  };

  // If user is not logged in, redirect to home

  // Determine the selected page name based on the current route
  function getSelectedPageName() {
    if (router.pathname) {
      return router.pathname === routeConstants.DASHBOARD.pathname ? 'Dashboard' :
          router.pathname === routeConstants.SERVICES.pathname ? 'Services' :
              router.pathname === routeConstants.SEARCH.pathname ? 'Search' :
                  router.pathname === routeConstants.SERVICE_AREA.pathname ? 'Service Area' :
                      router.pathname === routeConstants.INTEGRATIONS.pathname ? 'Integrations' :
                          router.pathname === routeConstants.BRANDING.pathname ? 'Branding' :
                              router.pathname === routeConstants.MEASUREMENTS.pathname ? 'Measurements' :
                                  router.pathname === routeConstants.SUPPORT.pathname ? 'Support' :
                                      router.pathname === routeConstants.MARKETING.pathname ? 'Marketing' :
                                          'Deeplawn';
    }
  }

  // Ensure 'displayLayout' is true before rendering the layout
  if (!displayLayout) {
    return null;
  }

  return (
      <>
        <div>
          {/* Mobile sidebar options */}
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
              <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 flex z-40">
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                            type="button"
                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className={classNames(
                        "mt-5 flex-1 h-0 overflow-y-auto",
                    )}>
                      <nav className="px-2 space-y-1">
                        {navigation.map((item) => (
                            <Link
                                key={item.name}
                                target={`${item.name === "Commercial Measurement" ? "_blank" : ""}`}
                                href={router.pathname === item.pathname ? '#' : item.href}
                            >
                              <div
                                  className={classNames(
                                      (router.pathname === item.pathname || (item.name === "Support" && router.pathname === routeConstants.TUTORIALS.pathname))
                                          ? 'bg-white text-green-500 stroke-green-500 fill-green-500'
                                          : 'text-gray-600 stroke-gray-600 hover:bg-gray-50 hover:text-green-500 hover:stroke-green-500 hover:fill-green-500',
                                      'group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer'
                                  )}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    router.push(item.href);
                                    // setSidebarOpen(false); // Close sidebar after navigation
                                  }}
                              >
                                <div className="flex items-center gap-5 flex-row text-base">
                                  <div className='w-8 h-8 flex items-center'>
                                    {item.icon}
                                  </div>
                                  {item.name}
                                  {item.name === "Density Blaster" && (
                                      <span className={`flex absolute items-center bg-green-50 text-green-500 border-2 border-gray-500 rounded-lg px-2 py-0.5 text-xs font-normal ${width < 358 ? 'ml-[195px]' : 'ml-[230px]'}`}>
                                  BETA
                                </span>
                                  )}
                                  {item.name === "Pro Hallway" && router.pathname !== routeConstants.WORKER.PRO_HALLWAY.pathname && proMeasurementsCount !== 0 && (
                                      <div className='bg-green-500 p-1 text-white rounded-full hover:bg-green-700 w-7 h-7 flex justify-center items-center'>
                                        <div className='font-bold'>
                                          {proMeasurementsCount}
                                        </div>
                                      </div>
                                  )}
                                </div>
                              </div>
                            </Link>
                        ))}
                      </nav>
                    </div>
                    <ProfileMenu
                        contractorDropDownOptions={dropDownNav}
                        username={user && oneWordFromName}
                        company={user && shortCompanyName}
                        avatar={profileIcon}
                        screenType={"small screen"}
                        drawerWidth={isSidebarOpen ? 256 : 112}
                        sidebarOpen={sidebarOpen}
                    />
                  </Dialog.Panel>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* Mobile sidebar options end */}

          {/* ------------------- Sidebar Desktop ------------------- */}
          <div className={`z-10 hidden lg:flex ${isSidebarOpen ? "lg:w-64" : "lg:w-20"} lg:flex-col lg:fixed lg:inset-y-0`}>
            <div className={classNames(
                "flex flex-col flex-grow border-r border-gray-200 pt-5 bg-gray-50 overflow-y-auto",
                height <= 510 ? 'pb-[4rem]' : ''
            )}>
              <div className="flex items-center flex-shrink-0 px-4">
                <Link href={(admin || pro) ? "#" : routeConstants.DASHBOARD.href} className={classNames(isSidebarOpen ? '' : 'mx-auto')}>
                  {isSidebarOpen ? (
                      <Image
                          style={{ maxWidth: '184px', marginLeft: '10px' }}
                          src={`${typeof window !== 'undefined' ? (window.location.origin.includes(process.env.NEXT_PUBLIC_PRO_WEBSITE as string) ? "/deeplawnlogo-pro.png" : "/assets/deeplawnlogo.png") : ''}`}
                          alt="deeplawn-logo"
                          className='w-full'
                          width={200}
                          height={50}
                      />
                  ) : (
                      <Image alt='small logo' className='w-[30px] h-[30px] mx-auto' src='/assets/deeplawn-small.png' width={50} height={50} />
                  )}
                </Link>
                {/* Updated Toggle Button */}
                { isWidthForButton && <div
                    onClick={handleChevronClick}
                    className={`absolute w-7 h-7 cursor-pointer ${
                        isSidebarOpen ? 'left-60 top-6' : 'left-[66px] top-6'
                    } bg-green-500 rounded-md z-[70]`}
                >
                  {isSidebarOpen ?
                      <ChevronLeftIcon className='w-5 h-5 absolute top-[5px] left-[4px] text-white' aria-hidden="true" /> :
                      <ChevronRightIcon className='w-5 h-5 absolute top-[5px] left-[4px] text-white' aria-hidden="true" />
                  }
                </div>}
              </div>
              <div className="z-50 flex-grow flex flex-col justify-between px-3 pt-12 pb-5">
                <nav className="pr-[7px] space-y-1">
                  {navigation.map((item) => (
                      <Link
                          key={item.name}
                          target={`${item.name === "Commercial Measurement" ? "_blank" : ""}`}
                          href={router.pathname === item.pathname ? '#' : item.href}
                      >
                        <div
                            className={classNames(
                                ((router.pathname === item.pathname) || (item.name === "Support" && router.pathname === routeConstants.TUTORIALS.pathname))
                                    ? 'bg-white text-green-500 stroke-green-500 fill-green-500'
                                    : 'text-gray-600 stroke-gray-600 hover:bg-gray-50 hover:text-green-500 hover:stroke-green-500 hover:fill-green-500',
                                `${height <= 580 ?
                                    'py-[3.5px] text-xs' :
                                    'py-2 text-sm'
                                } group flex items-center text-sm font-medium rounded-md cursor-pointer`,
                                isSidebarOpen ? 'pl-2' : ''
                            )}
                            onClick={(e) => {
                              e.stopPropagation();
                              router.push(item.href);
                            }}
                        >
                          {isSidebarOpen ? (
                              <div className={`gap-5 flex items-center flex-row text-base`}>
                                <div className='w-8 h-8 flex items-center'>
                                  {item.icon}
                                </div>
                                {item.name}
                                {item.name === "Density Blaster" && (
                                    <span className="flex absolute items-center bg-green-50 text-green-500 border-2 border-gray-500 rounded-lg px-2 py-0.5 text-xs font-normal ml-[165px]">BETA</span>
                                )}
                                {item.name === "Pro Hallway" && router.pathname !== routeConstants.WORKER.PRO_HALLWAY.pathname && proMeasurementsCount !== 0 && (
                                    <div className='bg-green-500 p-1 text-white rounded-full hover:bg-green-700 w-7 h-7 flex justify-center items-center'>
                                      <div className='font-bold'>
                                        {proMeasurementsCount}
                                      </div>
                                    </div>
                                )}
                              </div>
                          ) : (
                              <div className='w-8 h-8 mx-auto'>
                                {item.icon}
                              </div>
                          )}
                        </div>
                      </Link>
                  ))}
                </nav>
              </div>
              <div className={classNames(
                  'z-50 absolute bottom-5',
                  isSidebarOpen ?
                      'w-[224px] left-1/2 transform -translate-x-1/2' :
                      'right-[6px]',
              )}>
                <ProfileMenu
                    contractorDropDownOptions={dropDownNav}
                    username={user && oneWordFromName}
                    company={user && shortCompanyName}
                    avatar={profileIcon}
                    screenType={"large screen"}
                    drawerWidth={isSidebarOpen ? 256 : 112}
                    sidebarOpen={sidebarOpen}
                />
              </div>
            </div>
          </div>
          {/* Sidebar Desktop End */}

          {/* Main Content Area */}
          <div className={` ${isSidebarOpen ? "lg:pl-64" : "lg:pl-20"} flex flex-col z-0 flex-1`} style={{ height: '100vh' }}>
            <div className="sticky top-0 z-10 flex-shrink-0 flex items-center h-16 lg:h-0 bg-white border border-gray-100 lg:border-0">
              <button
                  type="button"
                  className="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 lg:hidden"
                  onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <AiOutlineMenu className="h-6 w-6" aria-hidden="true" />
              </button>
              <h1 className='lg:hidden text-base font-bold'>{getSelectedPageName()}</h1>
              <div className="flex-1 px-4 flex justify-between">
                <div className="flex-1 flex">
                  {/* Placeholder for additional elements if needed */}
                </div>
                <div className="ml-4 flex items-center md:ml-6">
                  {/* Placeholder for additional elements if needed */}
                </div>
              </div>
            </div>

            <main className="flex-1 z-0 bg-white"
                  style={{
                    ...((router.pathname === routeConstants.CREATE_TEMPLATE.pathname || router.pathname === routeConstants.EDIT_TEMPLATE.pathname) && { flexDirection: "column", display: "flex" })
                  }}>
              {props.children}
            </main>
          </div>
        </div>
      </>
  );
};
