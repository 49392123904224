export const measurementTypes = {
  GET_MEASUREMENT: 'GET_MEASUREMENT',
  SET_MEASUREMENT_LIST: 'SET_MEASUREMENT_LIST',
  SET_INITIAL_MEASUREMENT: 'SET_INITIAL_MEASUREMENT',
  UPDATE_MEASUREMENT_LIST: 'UPDATE_MEASUREMENT_LIST',
  SET_PLACE: 'SET_PLACE',
  SET_ANNOTATIONS: 'SET_ANNOTATIONS',
  SET_MEASUREMENT: 'SET_MEASUREMENT',
  SET_TAX: 'SET_TAX',
  SET_SQ_FEET: 'SET_SQ_FEET',
  SET_LOADING_MEASUREMENT: 'SET_LOADING_MEASUREMENT',
  SET_ERROR_MEASUREMENT: 'SET_ERROR_MEASUREMENT',
  GET_ORIGINAL_MEASUREMENT: 'GET_ORIGINAL_MEASUREMENT',
  IS_HD_MEASUREMENT: 'IS_HD_MEASUREMENT',
  SET_POLYGONS_FEATURES_SQ_FEET: 'SET_POLYGONS_FEATURES_SQ_FEET',
  SET_PRO_MEASUREMENTS_COUNT: 'SET_PRO_MEASUREMENTS_COUNT',
};
