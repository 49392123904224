import dayjs from 'dayjs';
import { GeoJsonPolygonType, PolygonCoordsType } from '../components/map/map.types';
import { DEFAULT_QUESTION_OPTIONS } from '../constants/app.constants';
import { orderTypeConst, orderTypeFormat } from '../constants/orderType.constants';
import { BoundaryTypeConstant, ProFeatures } from '../constants/polygonType.constants';
import { getDiscountedServicePrice } from './getDiscountedServicePrice';
import { roundToTwoDigits } from './roundToTwoDigits';
import { NextRouter } from 'next/router';
import { TagType } from '../components/branding/internal-tags';
import { Dispatch } from 'react';
import { sendGTMEvent } from '@next/third-parties/google';
import crypto from 'crypto';

export const get_serviceAutoPilot_checkoutQuestions = (checkoutQuestions: any) => {
  let checkoutQuestions_data: any = []
  checkoutQuestions?.forEach((temp_question: any) => {
    const answer = temp_question?.answer[0] ?? ''
    checkoutQuestions_data.push({
      "fieldId": temp_question?.serviceAutopilot_data_id || 0,
      "value": temp_question?.serviceAutopilot_options_ids?.[answer] || answer
    })
  });
  return checkoutQuestions_data;
}

export const getCheckoutQuestionsWithOptions = (checkoutQuestions) => {
  return checkoutQuestions?.map((question) => ({
    question: question.question,
    options: question.options || DEFAULT_QUESTION_OPTIONS,
    allowMultipleAnswers: question.allowMultipleAnswers,
    allowTextField: question?.allowTextField,
    enableDropdown: question?.enableDropdown,
    realGreenFlags: question?.realGreenFlags,
    serviceAutopilot_data_id: question?.serviceAutopilot_data_id,
    serviceAutopilot_options_ids: question?.serviceAutopilot_options_ids || {},
    isRequired: question?.isRequired
  }));
};

export const getSalesPerson = (customer, orderQuestions) => {
  let salesPerson = '';
  if (customer?.user === process.env.NEXT_PUBLIC_TURF_KINGS_USER_ID) {
    const question = orderQuestions?.find(
      (orderQuestion) => orderQuestion.question === 'How did you hear about us?'
    );
    if (question && question?.answer.includes('salePerson') && question?.answer?.split(':')[0] === 'salePerson') {
      salesPerson = question?.answer?.split(':')[1];
    }
  }
  return salesPerson;
};

export const getGateCode = (customer, orderQuestions) => {
  let gateCode = '';
  const question = orderQuestions?.find(
    (orderQuestion) => orderQuestion.question === 'Do you have locked gates at your home or gates for your private community? If you do, to provide us with access, please enter those codes in the text box below.'
  );
  if (question) {
    gateCode = question?.answer[0] || question?.answer;
  }
  return gateCode;
};

export const getSource = (orderQuestions: any, serviceAutoPilotData: any) => {
  const defaultSource_integrations = ['52f81233-f462-43e9-b77b-459196664ce5', '50f31a2e-9d0f-4c32-8574-0cc957e071e5', '79aca21e-331f-41f3-88b4-773f6c442917', '3fbbe6d3-ea7a-491f-84a8-504ed011e078', '3b8c3451-23f8-44bc-ac8a-4ee7df641b65'];

  if (defaultSource_integrations.includes(serviceAutoPilotData?.companyId)) return 'Deep Lawn';

  let source = '';

  const source_questions = [
    'How did you hear about us?', // general
  ]
  const question = orderQuestions?.find(
    (orderQuestion: any) => source_questions.find((quest) => quest === orderQuestion.question)
  );
  if (question) {
    source = question?.answer[0];
  }
  if (source && serviceAutoPilotData?.customFieldIds && serviceAutoPilotData?.customFieldIds[source]) {
    return serviceAutoPilotData?.customFieldIds[source]
  }
  return source;
};

export const have_dogs = (orderQuestions, serviceAutoPilotData) => {
  let source = '';
  const question = orderQuestions?.find(
    (orderQuestion) => orderQuestion.question === 'Do you have dogs?'
  );
  if (question) {
    source = question?.answer[0];
  }
  if (source && serviceAutoPilotData?.customFieldIds && serviceAutoPilotData?.customFieldIds[source === "Yes" ? 'dogs_yes' : 'dogs_no']) {
    return serviceAutoPilotData?.customFieldIds[source === "Yes" ? 'dogs_yes' : 'dogs_no']
  }
  return source;
};

export const turf_type_front = (orderQuestions, serviceAutoPilotData) => {
  let source = '';
  const question = orderQuestions?.find(
    (orderQuestion) => orderQuestion.question === 'What type of grass do you have?'
  );
  if (question) {
    source = question?.answer[0];
  }
  if (source && serviceAutoPilotData?.customFieldIds && serviceAutoPilotData?.customFieldIds[source]) {
    return serviceAutoPilotData?.customFieldIds[source]
  }
  return source;
};

export const getOrderQuestionValue = (orderQuestions: any, question: string) => {
  let answer = '';
  const temp_question: any = orderQuestions?.find(
    (orderQuestion: any) => orderQuestion.question === question
  );
  if (temp_question) {
    answer = temp_question?.answer[0];
  }
  return answer;
};

export function rgbToHex(rgb) {
  const { r, g, b } = rgb
  function componentToHex(c) {
    c = c === null ? 0 : c
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const get_weekly_mow_price = (services, quantity, measurement, boundary, paymentType) => {
  let price = 0;
  const service = services?.find(
    (temp_service) => temp_service.name === 'Weekly Lawn Mowing'
  );
  if (service) {
    const temp_price = getDiscountedServicePrice(
      service,
      quantity,
      measurement?.squareFeet,
      paymentType,
      boundary,
      { services: {}, packages: {} },
      measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0,
      measurement?.polygonsFeaturesSquareFeet,
    )
    price = parseFloat(temp_price);
  }
  return price;
};

export const get_weekly_mow_bagging_price = (services, quantity, measurement, boundary, paymentType) => {
  let price = 0;
  const service = services?.find(
    (temp_service) => temp_service.name === 'Weekly Lawn Mowing with Bagging'
  );
  if (service) {
    const temp_price = getDiscountedServicePrice(
      service,
      quantity,
      measurement?.squareFeet,
      paymentType,
      boundary,
      { services: {}, packages: {} },
      measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0,
      measurement?.polygonsFeaturesSquareFeet,
    )
    price = parseFloat(temp_price);
  }
  return price;
};

export const get_bi_weekly_mow_price = (services, quantity, measurement, boundary, paymentType) => {
  let price = 0;
  const service = services?.find(
    (temp_service) => temp_service.name === 'Bi-Weekly Mowing'
  );
  if (service) {
    const temp_price = getDiscountedServicePrice(
      service,
      quantity,
      measurement?.squareFeet,
      paymentType,
      boundary,
      { services: {}, packages: {} },
      measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0,
      measurement?.polygonsFeaturesSquareFeet,
    )
    price = parseFloat(temp_price);
  }
  return price;
};

export const get_basic_spring_cleanup_price = (services, quantity, measurement, boundary, paymentType) => {
  let price = 0;
  const service = services?.find(
    (temp_service) => temp_service.name === 'Basis Spring Cleanup'
  );
  if (service) {
    const temp_price = getDiscountedServicePrice(
      service,
      quantity,
      measurement?.squareFeet,
      paymentType,
      boundary,
      { services: {}, packages: {} },
      measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0,
      measurement?.polygonsFeaturesSquareFeet,
    )
    price = parseFloat(temp_price);
  }
  return price;
};

export const get_end_of_season_fall_cleanup_price = (services, quantity, measurement, boundary, paymentType) => {
  let price = 0;
  const service = services?.find(
    (temp_service) => temp_service.name === 'End of Season Fall Cleanup'
  );
  if (service) {
    const temp_price = getDiscountedServicePrice(
      service,
      quantity,
      measurement?.squareFeet,
      paymentType,
      boundary,
      { services: {}, packages: {} },
      measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0,
      measurement?.polygonsFeaturesSquareFeet,
    )
    price = parseFloat(temp_price);
  }
  return price;
};

export const get_recurring_mowing_service_price = (services, quantity, measurement, boundary, paymentType) => {
  let price = 0;
  const service = services?.find(
    (temp_service) => temp_service.name === 'Recurring Mowing Service'
  );
  if (service) {
    const temp_price = getDiscountedServicePrice(
      service,
      quantity,
      measurement?.squareFeet,
      paymentType,
      boundary,
      { services: {}, packages: {} },
      measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0,
      measurement?.polygonsFeaturesSquareFeet,
    )
    price = parseFloat(temp_price);
  }
  return price;
};

export const getQuestionsString = (orderQuestions) => {
  let questionAnswer = ''
  if (orderQuestions && orderQuestions.length) {
    orderQuestions.map((qna) => {
      questionAnswer += `<b>${qna.question}</b>: ${qna.answer[0]}<br />`
    })
  }
  return questionAnswer
}

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
export const isImportedService = (service: any) => {
  if (service && service?.sheetId) return true
  else return false
};

export const changeDate = (timestamp) => {
  let date = new Date(timestamp)
  return date.toString().substring(4, 15)
}
export const changeStatus = (status: any) => {
  if (status === orderTypeConst.ESTIMATE_SENT) return 'Quote'
  if (["MONTHLY_PAYMENT", "EZ_PAY"].includes(status)) return 'Pay Monthly'
  let converted = status?.split('_').join(' ')
  let capitalized = converted.charAt(0).toUpperCase() + converted.slice(1).toLowerCase();
  return capitalized
}

export const formatPhone = (phoneNumber) => {
  if (!phoneNumber || !phoneNumber?.length) {
    return "N/A"
  }
  else if (phoneNumber[0] !== '1' && phoneNumber[0] !== '+') {
    return phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, phoneNumber.length)
  }

  else {
    const number = phoneNumber.slice(-10)
    return "+1 " + number.substring(0, 3) + '-' + number.substring(3, 6) + '-' + number.substring(6, number.length)
  }
}

export const getPolygonCoordinates = (mapPolygons2) => {
  let polygons: any = [];
  mapPolygons2.current?.map((polygonObj) => {
    const tempDataArray: any = [];
    polygonObj.data?.map((dataObj) => {
      const polygon = dataObj?.polygon;
      const currentPolygon: any = [];
      for (var i = 0; i < polygon.getPath().getLength(); i++) {
        const newObj = {
          lat: polygon.getPath().getAt(i).lat(),
          lng: polygon.getPath().getAt(i).lng(),
        };
        currentPolygon.push(newObj);
      }
      tempDataArray.push({
        polygon: polygonObj?.type === 'zipcode' ? [currentPolygon] : currentPolygon,
        zipcodeObject: dataObj?.zipcodeObject,
        zipcode: dataObj?.zipcode,
      })
    });
    polygons.push({ ...polygonObj, data: tempDataArray });
  });
  return polygons;
};

export const genPolygonGeoJson = (polygonsRef) => {

  const tempPolygons: GeoJsonPolygonType[] = [];
  polygonsRef.current?.map((polygonObj) => {
    const tempDataArray: any = [];
    if (polygonObj?.type === 'zipcode') {
      polygonObj?.data?.map((dataObj) => {
        tempDataArray.push({
          polygon: [],
          zipcodeObject: dataObj?.zipcodeObject,
          zipcode: dataObj?.zipcode,
        });
      });
    } else {
      polygonObj?.data?.map((dataObj) => {
        const polygon = dataObj?.polygon;
        const currentPolygon: any = [];
        for (var i = 0; i < polygon.getPath().getLength(); i++) {
          const newObj = [polygon.getPath().getAt(i).lng(), polygon.getPath().getAt(i).lat()];
          currentPolygon.push(newObj);
        }
        currentPolygon.push(currentPolygon[0]);
        tempDataArray.push({
          polygon: currentPolygon,
          zipcodeObject: dataObj?.zipcodeObject,
          zipcode: dataObj?.zipcode,
        });
      });
    }
    const tempObj = { ...polygonObj, data: tempDataArray };
    tempPolygons.push(tempObj);
  });
  return tempPolygons
};

export const genPolygonCoordinates = (polygonsRef) => {
  let tempPolygons: Array<PolygonCoordsType> = [];
  polygonsRef.current?.map((polygonObj) => {
    const tempDataArray: any = [];
    if (polygonObj?.type === 'zipcode') {
      polygonObj?.data?.map((dataObj) => {
        tempDataArray.push({
          polygon: [],
          zipcodeObject: dataObj?.zipcodeObject,
          zipcode: dataObj?.zipcode,
        });
      });
    }
    else {
      polygonObj?.data?.map((dataObj) => {
        const polygon = dataObj?.polygon;
        const currentPolygon: any = [];
        for (var i = 0; i < polygon.getPath().getLength(); i++) {
          const newObj = {
            lat: polygon.getPath().getAt(i).lat(),
            lng: polygon.getPath().getAt(i).lng(),
          };
          currentPolygon.push(newObj);
        }
        tempDataArray.push({ polygon: currentPolygon });
      });
    }
    const tempObj = { ...polygonObj, data: tempDataArray };
    tempPolygons.push(tempObj);
  });
  return tempPolygons
};

export const breakpoints = {
  PHONE: {
    width: {
      min: 320,
      max: 480
    },
    height: {
      min: 480,
      max: 1024
    }
  },
  IPAD: {
    width: {
      min: 768,
      max: 1200
    },
    height: {
      min: 820,
      max: 1366
    }
  },
  LAPTOP: {
    width: {
      min: 1024,
      max: 5000
    },
    height: {
      min: 769,
      max: 1500
    }
  },
}
export function invertObject(obj) {
  const inverted = {};
  for (const key in obj) {
    const value = obj[key];
    inverted[value] = key;
  }
  return inverted;
}
export const getRoleToDisplay = (members: any, user: any, username: string) => {
  let role = ''
  members.map((each) => {
    if (each.name === username) {
      if (each._id === user?._id) {
        role = "YOU"
      }
      else {

        if (each.roles.includes('MANAGER')) {
          role = "M"
        }
        if (each.roles.includes('SALES')) {
          role = "S"
        }
      }
    }
  })
  return role
}
export const preventWhiteSpace = (event) => {
  if (event.code === 'Space') event.preventDefault()
}

export const getTimerCount = (isHdEnabled: boolean, acreage: any) => {
  // 60 second timer for SD search on a lot less than 1 acre (43560 sqft)
  // 70 second timer for SD search on a lot greater than 1 acre (43560 sqft)
  // 70 second timer for HD search on a lot less than 1 acre (43560 sqft)
  // 90 second timer for HD search on a lot greater than 1 acre (43560 sqft)
  if (!acreage || (isHdEnabled && acreage >= 1)) return 90;
  if ((isHdEnabled && acreage < 1) || (!isHdEnabled && acreage >= 1)) return 70;
  if (!isHdEnabled && acreage < 1) return 60;
  return 90;
}

export function getDrawingManagerOptions(customer, currentPolygonType, featureSymbolRef) {
  let Features = ProFeatures
  if (currentPolygonType == 'BOUNDARY') {
    Features = BoundaryTypeConstant
  }
  let calculatedColor = customer?.branding?.polygonFeaturesConstants?.[currentPolygonType]?.color ? customer?.branding?.polygonFeaturesConstants[currentPolygonType]?.color : Features[currentPolygonType].color
  let drawingManagerOptions = {
    polylineOptions: {
      strokeColor: calculatedColor,
      fillColor: calculatedColor,
      clickable: false,
      editable: true,
      ...Features[currentPolygonType].properties,
    },
    polygonOptions: {
      strokeColor: calculatedColor,
      fillColor: calculatedColor,
      editable: true,
      draggable: false,
      ...Features[currentPolygonType].properties,
    },
    markerOptions: {
      icon: { ...featureSymbolRef.current, fillColor: calculatedColor },
    },
    drawingControl: false
  }
  return drawingManagerOptions
}

export function capitalizeWords(str: string) {
  return str
    .split(" ")
    .map((word: string) => word.toUpperCase())
    .join("_");
}

export function shouldSendCallAhead(orderQuestions: any[]) {

  const dogQuestion = orderQuestions?.find((each) => each?.question?.toLowerCase() === "do you have dogs?")
  const doorQuestion = orderQuestions?.find((each) => each?.question?.toLowerCase() === "do you have a locked gate?")


  if (dogQuestion?.answer[0] == 'Yes' || doorQuestion?.answer[0] == 'Yes') {
    return true
  }
  else {
    return false
  }

}

export function getRgDropdownName(code: string, description: string) {
  let hasValidCode = false
  let hasValidDesc = false
  if (code && code?.length) {
    hasValidCode = true
  }
  if (description && description?.length) {
    hasValidDesc = true
  }
  if (hasValidCode && hasValidDesc) {
    return `${code} - ${description}`
  }
  else if (hasValidCode && !hasValidDesc) {
    return `${code} - Unknown`
  }
  else if (!hasValidCode && hasValidDesc) {
    return `Unknown - ${description}`
  }
  else {
    return `Unknown`
  }
}

export const getCreditsleft = (totalProCredits: number, spentProCredits: number) => {
  const diff = totalProCredits - spentProCredits
  //Requirements unclear so showing generic value , whatever
  return diff > 0 ? diff : 0
}

export const isMonthlyCustomized = (isRollOverEnabled: boolean) => {
  if(!isRollOverEnabled) return false
  return true
}

export const paymentOption_month = (price, paymentOption, isMonthlyCustomized) => {
  const now = dayjs(); // Capture the current date/time once
  const currentDay = now.date();
  const currentMonth = now.month(); // 0-indexed, so January is 0, December is 11
  let monthToDivide = currentMonth;
  const rollOverDay = paymentOption?.rollOverDate;

  let monthlyPrice;

  if ((isMonthlyCustomized) && (currentDay > rollOverDay)) {
      monthToDivide = (currentMonth + 1) % 12;

      const remainingMonths = 12 - monthToDivide;

      monthlyPrice = (Number(price) / remainingMonths).toFixed(2);
  }

  // Update the number of remaining months
  const remainingMonths = 12 - monthToDivide;

  // Calculate the monthly price
  monthlyPrice = (Number(price) / remainingMonths).toFixed(2);

  return monthlyPrice;
};


export const getUtmParams = (router: NextRouter) => Object.keys(router?.query || {}).reduce((acc, key) => {
  if (key.startsWith('utm_')) {
    acc[key] = router.query[key];
  }
  return acc;
}, {});

export const getServiceAreaArray = (serviceArea) => {
  const serviceAreaArray: object[] = []
  serviceArea?.polygon?.map((singlePolygon: any) => {
    serviceAreaArray.push({ id: singlePolygon?._id, name: singlePolygon?.name?.trim() || 'Unknown' })
  })
  return serviceAreaArray
}

export const getInternalTags = (customer) => {
  return customer?.branding?.internalTags?.filter((singleTag: TagType) => !singleTag?.isDeleted)?.map((singleTag: TagType) => singleTag?.name) || []
}

export const getPaymentFilter = (paymentBtns) => {
  const filteredTypes = JSON.parse(JSON.stringify(orderTypeFormat))
  delete filteredTypes.ESTIMATE_SENT
  delete filteredTypes.EZ_PAY
  delete filteredTypes.MONTHLY_PAYMENT
  return Object.values(filteredTypes).filter(each => each)
}

export const handleSalesmenFilter = (salesmenFilterArr, setSalesmenFilterArr, value) => {
  if (salesmenFilterArr.includes(value)) {
    setSalesmenFilterArr(current => current.filter(cur => cur !== value))
  } else {
    setSalesmenFilterArr((prev) => [...prev, value])
  }
}

export const handleCreatedFromFilter = (createdFromFilter, setCreatedFromFilter, value) => {
  if (createdFromFilter.includes(value)) {
    setCreatedFromFilter(current => current.filter(cur => cur !== value))
  } else {
    setCreatedFromFilter((prev) => [...prev, value])
  }
}

export const handleInternalTagsFilter = (internalTagsFilter: string[], setInternalTagsFilter: Dispatch<React.SetStateAction<string[]>>, value: string) => {
  if (internalTagsFilter.includes(value)) {
    setInternalTagsFilter(current => current.filter(cur => cur !== value))
  } else {
    setInternalTagsFilter((prev) => [...prev, value])
  }
}

export const handlePaymentTypeFilter = (paymentTypeFilter, setPaymentTypeFilter, value) => {
  console.log({ paymentTypeFilter, selectedFilter: value, PAY_MONTHLY: orderTypeFormat.PAY_MONTHLY, condition: orderTypeFormat.PAY_MONTHLY == value })
  if (orderTypeFormat.PAY_MONTHLY == value) {
    if (paymentTypeFilter.includes("Monthly Payment") || paymentTypeFilter.includes("Ez Pay")) {
      setPaymentTypeFilter(current => current.filter(cur => cur !== "Monthly Payment" && cur !== "Ez Pay"))
    } else {
      const newArray = [...paymentTypeFilter, 'Monthly Payment', 'Ez Pay']
      console.log({ newArray })
      setPaymentTypeFilter(newArray)
    }
  } else if (paymentTypeFilter.includes(value)) {
    setPaymentTypeFilter(current => current.filter(cur => cur !== value))
  } else {
    setPaymentTypeFilter((prev) => [...prev, value])
  }
}

export const handleServiceAreaFilter = (serviceAreaFilterArr, setServiceAreaFilterArr, value) => {
  if (serviceAreaFilterArr?.includes(value)) {
    setServiceAreaFilterArr(current => current.filter(cur => cur !== value))
  } else {
    setServiceAreaFilterArr((prev) => [...prev, value])
  }
}

export const handleFeaturesFilter = (featuresFilter, setFeaturesFilter, value) => {
  const tempFeatures = [...featuresFilter]
  if (tempFeatures.includes(value)) {
    setFeaturesFilter(current => current.filter(cur => cur !== value))
  } else {
    setFeaturesFilter((prev) => [...prev, value])
  }
}


export const isGoogleTagEnabled = (allIntegrations: any) => {
  if (
    !allIntegrations?.googleTagManager?.gTagManagerId ||
    !allIntegrations?.googleTagManager?.gTagManagerId?.length
  )
    return {
      enabled: false,
      tags: [],
    };
  return {
    enabled: true,
    tags: allIntegrations.googleTagManager.gTagManagerId as string[],
  };
};

export const trackGtagEvent = (tagIds: string[], conventionalLabel: string) => {
  const tagsArray = typeof tagIds == 'string' ? [tagIds] : tagIds
  if (!window?.gtag) return;
  if (typeof window !== 'undefined' && window?.gtag) {
    tagsArray?.map((singleTag: string) => {
      window.gtag('event', 'conversion', { send_to: `${singleTag}/${conventionalLabel}` });
    })
  }
};

export const newTrackGtagEvent = (conventionalLabel: string) => {
  sendGTMEvent({ event: conventionalLabel, value: conventionalLabel });
};

export const emitGoogleTagEvent = (gTagStatus: any, GoogleTagLabel: string) => {
  if (!gTagStatus[GoogleTagLabel]) {
    console.log({TRIGGERED: GoogleTagLabel})
    newTrackGtagEvent(GoogleTagLabel);
  }
};

export const getPriceByPayment = (paymentType: string, amount: number) => {
  return (paymentType === 'EZ_PAY' || paymentType === 'MONTHLY_PAYMENT') ? `${Number(amount).toFixed(2)}/MO` : Number(amount).toFixed(2)
}

export function americaFormatPhoneNumber(phoneNumber: string) {
  if(!phoneNumber) return ''
  // Remove non-numeric characters
  phoneNumber = phoneNumber.replace(/\D/g, '');

  let formattedNumber;
  if (phoneNumber.length === 11 && phoneNumber.startsWith('1')) {
    // Handle case where phone number starts with +1
    formattedNumber = `+1 (${phoneNumber.slice(1, 4)})-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7)}`;
  } else if (phoneNumber.length === 10) {
    // Handle case where phone number does not start with +1
    formattedNumber = `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  } else {
    // Return the original phone number if it doesn't match the expected length
    return phoneNumber;
  }

  return formattedNumber;
}

export const encryptPassword = (text: string, secretKey: string): string => {
  const iv = crypto.randomBytes(16); // Initialization vector
  const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(secretKey, 'hex'), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return iv.toString('hex') + ':' + encrypted.toString('hex'); // Concatenate iv and encrypted data
};