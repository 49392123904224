export const rolesConstants = {
  CONTRACTOR: 'CONTRACTOR',
  ADMIN: 'ADMIN',
  WORKER: 'WORKER',
  SENIORWORKER: 'SENIORWORKER',
  UNPAIDCUSTOMER: 'UNPAIDCUSTOMER',
  MANAGER: 'MANAGER',
  SALES: 'SALES',
  ROBIN: 'ROBIN',
};
