import { SERVICE_AREA_TYPES } from '../constants/services.constants';
import { getServicePrice } from './getServicePrice';
import { getServiceQuantity } from './getServiceQuantity';
import { getServiceSqFeet } from './getServiceSqFeet';

export const checkHasCustomQuote = (serviceItem, quantity, boundary, sqFeet, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  if (serviceItem?.areaType === SERVICE_AREA_TYPES.BUILDING_AREA && buildingSquareFeet===0) {
    return true
  }
  let squareFeet = getServiceSqFeet(serviceItem?.areaType, sqFeet, (boundary.acreage_calc)*43560, buildingSquareFeet, polygonsFeaturesSqFeet);
  
  const servicePrice = getServicePrice(
    serviceItem.pricePerSquare,
    getServiceQuantity(quantity, serviceItem._id),
    serviceItem.minPrice,
    serviceItem.fixedPrice,
    squareFeet,
    serviceItem.ranges,
    serviceItem?.areaType
  );

  if(servicePrice === -1) {
    return true
  }
  return false
};
