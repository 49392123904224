import { useRouter } from 'next/router';
import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderTypeFormat } from '../../constants/orderType.constants';
import { rolesConstants } from '../../constants/roles.constants';
import { Table } from '../../library/stories/Tables/Table';
import { getContractorOrders } from '../../redux/contractor/contractor.actions';
import { routeConstants } from '../../routes';
import { changeDate } from '../../utils/formatDate';
import { isInRoles } from '../../utils/isInRoles';
import { generateMembersFilterQuery } from '../../utils/isTeamMember';

type Props = {
  handleSorting: any,
  sortField: string,
  sortOrder: string,
  pageNo: number,
  utmFilter: string,
  searchInput: string
  utmParametersFilter: string;
  docsPerPage: string;
}

const OrdersTable = (props: Props) => {

  const router = useRouter()
  const dispatch = useDispatch();
  const { orders, ordersFilter,loading } = useSelector(
    (state: any) => state.contractor
  );
  const { user } = useSelector((state: any) => state.user)
  const { salesTeam } = useSelector((state: any) => state.teamMember)
  const {selectedSalesMan} = useSelector((state: any) => state.customer)

  const roles = user?.roles?.length ? user?.roles : []
  const { sortField, sortOrder, pageNo, utmFilter, searchInput, utmParametersFilter, docsPerPage } = props
  const isManager = isInRoles(roles, rolesConstants.MANAGER)
  // const salesmenQuery = isManager && generateMembersFilterQuery(selectedSalesMan, salesTeam)
  const salesmenQuery = isManager ? generateMembersFilterQuery(selectedSalesMan, salesTeam) : ''

  const query = `?sortField=${sortField}&sortOrder=${sortOrder}&page=${pageNo}&paymentType=${ordersFilter == 'PAY_MONTHLY' ? 'EZ_PAY,MONTHLY_PAYMENT' : ordersFilter}&search=${searchInput}&utmParameter=${utmFilter}&utmParameters=${utmParametersFilter}${salesmenQuery}&docsPerPage=${docsPerPage}`

  useLayoutEffect(() => {
    dispatch(getContractorOrders(query))
  }, [sortField, sortOrder, ordersFilter, pageNo, utmFilter, selectedSalesMan, utmParametersFilter, docsPerPage])

  const handleAddressClick = (address: string, orderId: any) => {
    return (
      <span className='cursor-pointer hover:underline underline-offset-2 hover:text-green-600'
        onClick={() => {
          router.push(`${routeConstants.ORDER_DETAILS.basePath}/${orderId}`)
        }}>
        {address}
      </span>
    )
  }


  const getActiveTabData = () => {
    let currentData: any[] = []
    orders?.map((each: any) =>
      currentData.push({
        key: each?._id,
        address: handleAddressClick(each?.measurement?.address, each?._id),
        name: each?.user?.name,
        date: changeDate(each?.date),
        amount: `$${Number(each?.amount).toFixed(2)}`,
        status: ["MONTHLY_PAYMENT", "EZ_PAY"].includes(each?.payment) ? 'Pay Monthly' : orderTypeFormat[each?.payment],
        action: <a href={`${routeConstants.ORDER_DETAILS.basePath}/${each?._id}`} className="px-5 py-2 border-1 border-[#DFE6E2] rounded-md bg-white text-black hover:text-green-500">View</a>,
        isChecked: each.isChecked
      })
    )
    return currentData
  }

  const getActiveTabHeaders = () => {
    return [
      { name: 'Address', ref: 'measurement.address' },
      { name: 'Name', ref: 'user.name' },
      { name: 'Date', ref: 'date' },
      { name: 'Amount', ref: 'amount' },
      { name: 'Type', ref: 'payment' },
      { name: 'view', ref: 'view' },
    ]
  }

  const tabledata = {
    tabledata: getActiveTabData(),
    headers: getActiveTabHeaders(),
    enableSorting: true,
    handleSorting: props.handleSorting
  };
  if (loading && !orders?.length) return null
  
  return (
    <>
       <Table {...tabledata} /> 
        </>
  )
}

export default OrdersTable