export const orderTypeConst = {
  PAY_NOW: 'PAY_NOW',
  PAY_AS_YOU_GO: 'PAY_AS_YOU_GO',
  PAY_BY_CHECK: 'PAY_BY_CHECK',
  ESTIMATE_SENT: 'ESTIMATE_SENT',
  LEADS:'LEADS'
};

export const orderTypeFormat: any = {
  PAY_NOW: 'Pay now',
  PAY_AS_YOU_GO: 'Pay as you go',
  PAY_BY_CHECK: 'Pay by check',
  ESTIMATE_SENT: 'Quote',
  MONTHLY_PAYMENT: 'Monthly Payment',
  PAY_MONTHLY: 'Pay Monthly',
  EZ_PAY: 'Ez Pay'
}

export const ORDER_APP_FEE = 2;
