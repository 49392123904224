import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point, polygon } from "@turf/helpers";
import { convertToGeoJson } from "../../utils/map/convertToGeoJson";
import { GET_SERVICE_AREA } from "./service-area.api";
import sendRequest from "../../utils/useAxios";

export const checkInsideServiceArea = (serviceArea_polygons, center, searchedServicesType) => {

  const isInsideServiceArea: any = []
  let region = null
  const searchedRegion = serviceArea_polygons?.filter(singleRegion => singleRegion?.searchTypes?.includes(searchedServicesType))
  searchedRegion?.map((polygonObj: any) => {
    if (polygonObj?.type === 'zipcode') {
      const polygonObj_data = polygonObj.data?.map((polygonDataObj: any) => ({ polygon: polygonDataObj?.zipcodeObject?.geometry.coordinates }))
      polygonObj_data.map((dataObj: any) => {
        const polygonData = dataObj?.polygon
        polygonData.map((polygonItem: any) => {
          const geojsonData = [convertToGeoJson(polygonItem)]
          const isInsidePolygon = booleanPointInPolygon(
            point([center.lng, center.lat]),
            polygon(geojsonData)
          );
          if (isInsidePolygon) region = polygonObj
          isInsideServiceArea.push(isInsidePolygon)
        })
      })
    } else {
      polygonObj.data.map((dataObj: any) => {
        const polygonData = dataObj?.polygon
        const geojsonData = [convertToGeoJson(polygonData)]
        const isInsidePolygon = booleanPointInPolygon(
          point([center.lng, center.lat]),
          polygon(geojsonData)
        );
        if (isInsidePolygon) region = polygonObj
        isInsideServiceArea.push(isInsidePolygon)
      })
    }
  })
  const isInside = isInsideServiceArea.includes(true)
  return { isInside, region }
};

export const getUserServiceArea = async (userId: any) => {
  const { data } = await sendRequest(GET_SERVICE_AREA.METHOD, `${GET_SERVICE_AREA.PATH}/${userId}`);
  if (data && !data.statusCode) {
    return data
  }
  return null
};
