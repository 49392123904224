import { Pagination } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rolesConstants } from '../../constants/roles.constants';
import { Filter } from '../../library/stories/Buttons/Filter';
import { Search } from '../../library/stories/Inputs/Search';
import { getContractorOrders, setOrdersFilter } from '../../redux/contractor/contractor.actions';
import { isInRoles } from '../../utils/isInRoles';
import sendRequest from '../../utils/useAxios';
import OrdersTable from './orders-table.component';
import LeadsTable from './leads-table.component';
import { getLeads } from '../../redux/leads/leads.actions';
import { generateMembersFilterQuery } from '../../utils/isTeamMember';
import { useRouter } from 'next/router';
import { getEnabledPaymentButtons } from '../../redux/customer/customer.selectors';
import { DropdownWithSearch } from '../../library/stories/Buttons/dropdown-with-search';
import { DOCS_PER_PAGE_LIST } from '../../constants/app.constants';

const DashboardTable = () => {
  const dispatch = useDispatch();
  const router = useRouter()
  const { user } = useSelector((state: any) => state.user)
  const { customer } = useSelector((state: any) => state.customer)
  const { ordersFilter, totalCount, loading } = useSelector(
    (state: any) => state.contractor
  );
  const { salesTeam } = useSelector((state: any) => state.teamMember)
  const { selectedSalesMan } = useSelector((state: any) => state.customer)

  const paymentBtns = useSelector(getEnabledPaymentButtons())

  const [pageNo, setPageNo] = useState(1);
  const [jumpToPage, setJumpToPage] = useState('');


  const [docsPerPage, setDocsPerPage] = useState<{ id: number; value: string; name: string; }>(DOCS_PER_PAGE_LIST[0]);

  const [utmFilter, setUtmFilter] = useState('ALL');
  const [utmParametersFilter, setUtmParametersFilter] = useState('ALL');
  const [searchInput, setSearchInput] = useState<string>('')
  const [utmFilterList, setUtmFilterList] = useState<string[]>([]);
  const [utmFilterOptions, setUtmFilterOptions] = useState<{ name: string, onClick: () => void }[]>([{ name: 'All', onClick: () => onFilterUTM }]);
  const [utmParametersOptions, setUtmParametersOptions] = useState<{ name: string, onClick: () => void }[]>([]);

  const [sortField, setSortField] = useState(ordersFilter != 'LEADS' ? 'date' : 'createdAt');
  const [sortOrder, setSortOrder] = useState('desc');

  const { roles } = user || []
  const isContractor = isInRoles(roles, rolesConstants.CONTRACTOR)
  const isManager = isInRoles(roles, rolesConstants.MANAGER)
  const { utmEnableParameter } = customer?.settings
  const salesmenQuery = isManager ? generateMembersFilterQuery(selectedSalesMan, salesTeam) : ''

  const returnQuery = (page: any) => {

    return `?sortField=${sortField}&sortOrder=${sortOrder}&page=${page}&paymentType=${ordersFilter == 'PAY_MONTHLY' ? 'EZ_PAY,MONTHLY_PAYMENT' : ordersFilter}&search=${searchInput}&utmParameter=${utmFilter}&utmParameters=${utmParametersFilter}${salesmenQuery}?docsPerPage=${docsPerPage.value}`

  }

  const query = returnQuery(pageNo)

  useEffect(() => {
    dispatch(setOrdersFilter('All'))
  }, [router])

  useEffect(() => {
    if ((isContractor || isManager) && utmEnableParameter) {
      fetchUniqueUTMTags();
      setUtmParameters();
    }
  }, [ordersFilter])

  // Set multiple utm parameters inside utm filter 
  const setUtmParameters = async () => {
    if (!customer?.branding?.utmParameters) return null
    const utmParameters = customer?.branding?.utmParameters
    if (!utmParameters || !utmParameters.length) return null;
    const utmFilterOptions = utmParameters
      .map((item: string) => ({
        name: item.replace(/_/g, " "),
        onClick: () => onFilterMultipleUTM(item)
      }));
    setUtmParametersOptions(utmFilterOptions)
  }

  const getFilterOptions = () => {
    const FILTER_TYPES_OPTIONS = [
      { name: 'All', onClick: () => getFilteredOrders("All") },
      { name: 'Pay now', onClick: () => getFilteredOrders("PAY_NOW") },
      { name: 'Pay Monthly', onClick: () => getFilteredOrders("PAY_MONTHLY") },
      { name: 'Pay as you go', onClick: () => getFilteredOrders("PAY_AS_YOU_GO") },
      { name: 'Estimate sent', onClick: () => getFilteredOrders("ESTIMATE_SENT") },
      { name: 'Leads', onClick: () => getFilteredOrders("LEADS") }
    ];
    return FILTER_TYPES_OPTIONS
  }

  const getFilteredOrders = (type: any) => {
    setSortField(type != 'LEADS' ? 'date' : 'createdAt')
    setPageNo(1);
    dispatch(setOrdersFilter(type));
  };

  const onFilterUTM = async (filter: any) => {
    setUtmFilter(filter)
    setUtmParametersFilter('')
  }
  const onFilterMultipleUTM = async (filter: any) => {
    setUtmParametersFilter(filter)
    setUtmFilter('')
  }

  const fetchUniqueUTMTags = async () => {
    try {
      let url = ordersFilter === 'LEADS' ? '/leads/get-unique-utm-tags' : '/orders/get-unique-utm-tags';
      const { data } = await sendRequest('GET', url);
      if (data && data.length) {
        setUtmFilterList(['ALL', ...data]);
        const utmFilterOptions = data
          .filter((item: any) => item !== null && item.trim() !== '')
          .map((item: any) => ({
            name: item,
            onClick: () => onFilterUTM(item)
          }));
        setUtmFilterOptions([{ name: 'All', onClick: () => onFilterUTM('') }].concat(utmFilterOptions))
      } else {
        setUtmFilterList([]);
        setUtmFilter('ALL');
        setUtmFilterOptions([{ name: 'All', onClick: () => onFilterUTM('All') }]);
      }
    } catch (error) {
      // Handle error
      console.error('Error fetching unique UTMTags:', error);
    }
  };

  const calculatePaginationRecords = () => {
    const record_from = ((pageNo - 1) * Number(docsPerPage.value)) + 1
    const record_to = pageNo * Number(docsPerPage.value)
    const total_record = Math.ceil(totalCount)
    return {
      total_record,
      record_from: record_from <= total_record ? record_from : total_record,
      record_to: record_to <= total_record ? record_to : total_record,
    }
  }


  const handleSearch = () => {
    // ! Not sure how to refactor this.
    // Currently it is sending an extra request
    // with the old page set in the query
    if (ordersFilter === 'LEADS') {
      dispatch(getLeads(returnQuery(1)))
    } else {
      dispatch(getContractorOrders(returnQuery(1)))
    }
  }

  const handleSorting = (sortFieldTable: string, sortOrderTable: string) => {
    setSortField(sortFieldTable)
    setSortOrder(sortOrderTable)
  };

  const handleKeyPress = (event: any) => {
    if (isNaN(event.key)) {
      event.preventDefault();
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      if (Number(jumpToPage) > Math.ceil(totalCount / Number(docsPerPage.value))) return;
      if (Number(jumpToPage) === pageNo) return;
      if (Number(jumpToPage) === 0 || !Number(jumpToPage)) return;
      setPageNo(Number(jumpToPage));
      setJumpToPage('');
    }
  };

  return (
    <>
      <section className="col-md-12 section md:mt-10 mb-5" style={{ paddingTop: '15px' }}>
        <div className="col-md-12 pull-left">
          <div className="row">
            <h3 className="heading text-2xl font-bold">Active orders</h3>
          </div>
        </div>
      </section>
      <div className="md:flex md:justify-between items-center space-y-3 md:space-y-0 mb-5">
        <Search
          label="for address, owners"
          onChange={(event: any) => setSearchInput(event.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <div className="md:flex md:justify-center items-center md:space-x-3 space-y-3 md:space-y-0">
          {(isContractor || isManager) && utmFilterList.length && utmEnableParameter &&
            <Filter
              active="All"
              label={`UTM`}
              options={[...utmFilterOptions, ...utmParametersOptions]}
            /> || ''}
          <Filter
            active="All"
            label={`Sort by type`}
            options={getFilterOptions()}
          />
        </div>
      </div>
      <hr />
      <section className="col-md-12 ">
        {loading &&
          <div className=''>
            <div className="h-7 bg-gray-200  animate-pulse  rounded-md  w-full mb-3"></div>
            <div className="h-7 bg-gray-200 opacity-80 animate-pulse  rounded-md  w-full mb-3"></div>
            <div className="h-7 bg-gray-200 opacity-70 animate-pulse  rounded-md  w-full mb-3"></div>
            <div className="h-7 bg-gray-200 opacity-60 animate-pulse  rounded-md  w-full mb-3"></div>
            <div className="h-7 bg-gray-200 opacity-50 animate-pulse  rounded-md  w-full mb-3"></div>
            <div className="h-7 bg-gray-200 opacity-40 animate-pulse  rounded-md  w-full"></div>
          </div>}
        <div className={`${loading ? 'hidden' : 'block'} `}>
          {ordersFilter !== 'LEADS' ?
            <OrdersTable
              handleSorting={handleSorting}
              sortField={sortField}
              sortOrder={sortOrder}
              pageNo={pageNo}
              utmFilter={utmFilter}
              utmParametersFilter={utmParametersFilter}
              searchInput={searchInput}
              docsPerPage={docsPerPage.value}
            />
            :
            <LeadsTable
              handleSorting={handleSorting}
              sortField={sortField}
              sortOrder={sortOrder}
              pageNo={pageNo}
              utmFilter={utmFilter}
              utmParametersFilter={utmParametersFilter}
              searchInput={searchInput}
              docsPerPage={docsPerPage.value}
            />
          }
        </div>
      </section>
      <div className={`flex flex-col md:flex-row gap-3 justify-between items-start md:items-center ${loading ? 'hidden' : 'block'}`} style={{ marginTop: 30 }}>
        {
          totalCount > 0 &&
          <div>
            <span>Showing {calculatePaginationRecords().record_from} to {calculatePaginationRecords().record_to} of {calculatePaginationRecords().total_record} results</span>
          </div>
        }

        <div className='flex flex-col md:flex-row items-start md:items-center gap-3 justify-end'>
          <div>
            <Pagination className='border-separate rounded-md border w-[100%]' shape='rounded' count={Math.ceil(totalCount / Number(docsPerPage.value))}
              page={pageNo} onChange={(_, value) => setPageNo(value)} />
          </div>
          {totalCount > 81 &&
            <div className='flex justify-center items-center gap-2'>
              <h1 className='!m-0'>Go to</h1>
              <input
                value={jumpToPage}
                onKeyPress={handleKeyPress}
                onChange={(e) => setJumpToPage(e.target.value)}
                type="text"
                id="page_no"
                className="bg-white text-sm w-[50px] h-[32px] border border-[#e5e7eb] text-gray-900 rounded-[0.375rem] focus:ring-0 focus-visible:outline-none focus:border-green-500 block px-2.5 py-1.5"
              />
              <h1 className='!m-0'>Page</h1>
            </div>
          }
          {/* {totalCount > 81 &&
          <DropdownWithSearch data={DOCS_PER_PAGE_LIST} selected={docsPerPage} setSelected={setDocsPerPage} />
        }
        {(totalCount <= 81 && Number(docsPerPage.value) > 9) &&
          <DropdownWithSearch data={DOCS_PER_PAGE_LIST} selected={docsPerPage} setSelected={setDocsPerPage} />
        } */}
        </div>



      </div>

    </>
  )
}

export default DashboardTable