import { customerTypes } from './customer.types';
import Cookies from 'js-cookie';

const INITIAL_STATE = {
  loading: false,
  customer: null,
  selectedSalesMan: null,
  currency: '$',
  accountUrl: '',
  accountId: '',
  error: null,
  uploadedLogo: null,
  exceededTrailLimit: false
};

const customerReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case customerTypes.SET_LOADING_CUSTOMER:
      return {
        ...state,
        loading: payload,
      };
    case customerTypes.SET_CUSTOMER_CURRENCY:
      return {
        ...state,
        currency: payload,
      };
    case customerTypes.SET_CUSTOMER:

      // Migration: Check for the `isMigrated` cookie
      const isMigrated = Cookies.get('migrated');
      // Migration: Redirect to the new version
      if (isMigrated) return window.location.href = 'https://v2.deeplawn.com';

      const excludePaths = ['search', 'pro', 'support'];

      // Migration: if the customer has isMigrated true than set the cookie and redirect to V2
      if (payload?.isMigrated === true && !excludePaths.some(path => window.location.pathname.includes(path))) {
        Cookies.set('migrated', 'true');
        return window.location.href = process.env.NEXT_PUBLIC_V2_APP_URL ?? '' ;
      }else{
        Cookies.remove('migrated');
      }

      return {
        ...state,
        customer: payload,
        selectedSalesMan: payload.settings?.managerSelectOptions?.salesmenSelected || [],
        loading: false,
        error: null
      };
    case customerTypes.SET_SELECTED_SALESMAN:
      return {
        ...state,
        selectedSalesMan: payload
      };
    case customerTypes.SET_ACCOUNT_ID:
      return {
        ...state,
        loading: false,
        accountId: payload,
        error: null
      };
    case customerTypes.SET_ACCOUNT_URL:
      return {
        ...state,
        loading: false,
        accountUrl: payload,
        error: null
      };
    case customerTypes.SET_INITIAL_STATE:
      return {
        token: '',
        user: null,
        customer: null,
      };
    case customerTypes.SET_ERROR_CUSTOMER:
      return {
        ...state,
        loading: false,
        error: payload
      };

    case customerTypes.SET_COMPANY_LOGO:
      return {
        ...state,
        uploadedLogo: payload,
        error: null
      };
    case customerTypes.SET_IS_EXCEEDED_TRIAL_LIMIT:
      return {
        ...state,
        exceededTrailLimit: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default customerReducer;
