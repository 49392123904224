import { EMERALD_TEST_ACCOUNT_CID } from "../../constants/app.constants";
import { get_Place_address_components_object } from "../../utils/searchInPlaceObjectComponents";
import sendRequest from "../../utils/useAxios";
import { SEARCH_REAL_GREEN_CUSTOMER } from "./real-green.api";

export function returnRealGreenPackageStatus(customerId:string,programType:string,isEstimate=false){
  const status = isEstimate
  ? programType=='E' ? "0" : "2"
  : programType=='E' ? "0" :
  customerId == EMERALD_TEST_ACCOUNT_CID ? "3" : "9"
  
  console.log({programType,status})
      return status
}

const getRealGreenCustomerBySearch = async (customerId: string, requestBody: any) => {
  const { data } = await sendRequest(SEARCH_REAL_GREEN_CUSTOMER.METHOD, SEARCH_REAL_GREEN_CUSTOMER.PATH, { customerId, requestBody });
  if (data && !data.statusCode && data?.length) {
    return data
  }
  return null
}

const getExistingRealGreenCustomer = async (customerId: string, checkByLocationBody: any, checkCustomerBody: any, checkCustomerBodyShortSuffix: any, checkCustomerBodyWithoutSuffix: any) => {
  let realGreenCustomerData = null;

  realGreenCustomerData = await getRealGreenCustomerBySearch(customerId, checkByLocationBody);

  if (!realGreenCustomerData) realGreenCustomerData = await getRealGreenCustomerBySearch(customerId, checkCustomerBody);

  if (!realGreenCustomerData) realGreenCustomerData = await getRealGreenCustomerBySearch(customerId, checkCustomerBodyShortSuffix);

  if (!realGreenCustomerData) realGreenCustomerData = await getRealGreenCustomerBySearch(customerId, checkCustomerBodyWithoutSuffix);

  return realGreenCustomerData
}

export const checkRealGreenCustomerBySearch = async (placeObject: any, customerId: string) => {
  if (!placeObject || !Object.keys(placeObject).length) return

  const geometry = JSON.parse(JSON.stringify(placeObject.geometry));
  const propertyLat = parseFloat(geometry.location.lat.toFixed(5));
  const propertyLng = parseFloat(geometry.location.lng.toFixed(5));

  const { primary_line, city, state_short, zip_code, street_number, route_short }: any = get_Place_address_components_object(placeObject.address_components);

  const splittedStreetAddress = primary_line
  const splittedStreetAddressShortSuffix = `${street_number} ${route_short}`
  // const suffix = splittedStreetAddress[splittedStreetAddress.length - 1]

  const checkByLocationBody = {
    customerLatitude: { minValue: propertyLat, maxValue: propertyLat },
    customerLongitude: { minValue: propertyLng, maxValue: propertyLng }
  }

  const checkCustomerBody = {
    customerStreetNumber: street_number,
    customerStreetAddress: splittedStreetAddress,
    customerCity: city,
    customerState: state_short,
    customerZip: zip_code,

  }

  const checkCustomerBodyShortSuffix = {
    ...checkCustomerBody,
    customerStreetAddress: splittedStreetAddressShortSuffix,
  }

  const checkCustomerBodyWithoutSuffix = {
    ...checkCustomerBody,
    customerStreetAddress: splittedStreetAddress.split(' ').map((each: any) => each.trim()).splice(0, splittedStreetAddress.split(' ').length - 1).join(" "),
  }

  let realGreenCustomerData = await getExistingRealGreenCustomer(customerId, checkByLocationBody, checkCustomerBody, checkCustomerBodyShortSuffix, checkCustomerBodyWithoutSuffix)

  return realGreenCustomerData
}