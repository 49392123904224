
import { calculateDiscount } from "./calculateDiscount";
import { getServiceQuantity } from "./getServiceQuantity";
import { roundToTwoDigits } from "./roundToTwoDigits";

export const ApplyServiceCustomDiscounts = (customDiscounts, service, price, cartPaymentType, quantity) => {
  if (customDiscounts?.services[service._id]?.length) {
    const serviceQuantity = typeof quantity === 'object' ? getServiceQuantity(quantity, service._id) : quantity;
    const { discountedTotal } = calculateDiscount(customDiscounts?.services[service._id], price, cartPaymentType, serviceQuantity)
    if (discountedTotal || discountedTotal >= 0) {
      return roundToTwoDigits(discountedTotal).toFixed(2)
    }
  }
  return price
}

export const ApplyPackageCustomDiscounts = (customDiscounts, packageItem, price, cartPaymentType) => {
  if (customDiscounts?.packages[packageItem._id]?.length) {
    const { discountedTotal } = calculateDiscount(customDiscounts?.packages[packageItem._id], price, cartPaymentType)
    if (discountedTotal || discountedTotal >= 0) {
      return roundToTwoDigits(discountedTotal).toFixed(2)
    }
  }
  return price
}