export const realGreenTypes = {
  //LEAD TYPES
  SET_REAL_GREEN_LEAD: "SET_REAL_GREEN_LEAD",
  UPDATE_REAL_GREEN_LEAD: "UPDATE_REAL_GREEN_LEAD",
  GET_REAL_GREEN_LEAD: "GET_REAL_GREEN_LEAD",
  //Packages Services Types
  SET_REAL_GREEN_ALL_PACKAGES: "SET_REAL_GREEN_ALL_PACKAGES",
  SET_REAL_GREEN_ALL_SERVICES: "SET_REAL_GREEN_ALL_SERVICES",
  SET_REAL_GREEN_ALL_DISCOUNTS:"SET_REAL_GREEN_ALL_DISCOUNTS",
  SET_REAL_GREEN_ALL_SOURCES:"SET_REAL_GREEN_ALL_SOURCES",

  SET_REAL_GREEN_SELECTED_ITEMS: "SET_REAL_GREEN_SELECTED_ITEMS",
  SET_REAL_GREEN_SELECTED_IDS: "SET_REAL_GREEN_SELECTED_IDS",
  REPLACE_REAL_GREEN_SELECTED_IDS:"REPLACE_REAL_GREEN_SELECTED_IDS",
  RG_LOADING: "RG_LOADING",
  SET_REAL_GREEN_FLAG_FOR_NEW:"SET_REAL_GREEN_FLAG_FOR_NEW",
  SET_REAL_GREEN_INITIAL_STATE:"SET_REAL_GREEN_INITIAL_STATE",
  SET_REAL_GREEN_ORDER_INFO:"SET_REAL_GREEN_ORDER_INFO",
  UPDATE_REAL_GREEN_CURRENT_LEAD: "UPDATE_REAL_GREEN_CURRENT_LEAD"
}