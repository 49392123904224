import dayjs from 'dayjs';
import moment from 'moment';
import React from 'react';
import { Area, AreaChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface ChartProps {
  label: string;
  total: number;
  currency?: string;
  datakey: string;
  totalChartData: any[];
}

export const Chart = ({ label, total, currency, totalChartData, datakey, ...props }: ChartProps) => {
  
  function formatXAxis(tickItem: any) {
    if (tickItem) {
      let checkDate = tickItem?.includes(':')
      if (checkDate) {
        return tickItem
      }
      return moment(tickItem, 'DD MM YYYY').format('MMM DD')
    }
  }

  function formateTooltipDate(tickItem: any) {
    if (tickItem) {
      let checkDate = tickItem?.toString().includes(':')
      if (checkDate) {
        return tickItem
      }
      return moment(tickItem, 'DD MM YYYY').format('MMM DD, YYYY')
    }
  }

  function formatTotalValue(value: any) {
    if (label === 'Quotes') {
      return `${value}`.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    let formatedValue = `$${value}`.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formatedValue
  }

  return (
    <div className="rounded-lg border-1 border-gray-100 p-4 bg-white">
      <div className="card" style={{ height: '100%' }}>
        <div className="card-body">
          <h5 className="text-lg font-bold mb-3">Total {label}</h5>
          <h1 className="mb-5 text-sm">{currency}{total}</h1>
          <div style={{ width: '100%', height: '170px' }}>
            {totalChartData && (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart width={730} height={250} data={totalChartData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#ABF9CFBD" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#ABF9CFBD" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  {total > 0 ? (
                    <XAxis dataKey="date" tick={{ fontSize: 11 }} tickFormatter={formatXAxis} />
                  ) : (
                    <XAxis dataKey="date" tick={{ fontSize: 11 }} domain={[0, 1]} />
                  )}
                  <Tooltip 
                  labelFormatter={formateTooltipDate} 
                  formatter={formatTotalValue}
                  />
                  <Area dataKey={datakey} stroke="#28A662" fillOpacity={1} fill="url(#colorUv)" />
                </AreaChart>
              </ResponsiveContainer>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};