import { rolesConstants } from './constants/roles.constants';
export const routes = {
  '/widget/[id]': {
    roles: [rolesConstants.CONTRACTOR],
  },
  '/contractor/home': {
    roles: [rolesConstants.CONTRACTOR, rolesConstants.MANAGER],
  },
  '/contractor/services-packages': {
    roles: [rolesConstants.CONTRACTOR, rolesConstants.MANAGER],
  },
  '/contractor/branding': {
    roles: [rolesConstants.CONTRACTOR],
  },
  '/contractor/profile': {
    roles: [rolesConstants.SALES, rolesConstants.MANAGER],
  },
  '/contractor/my-account': {
    roles: [rolesConstants.CONTRACTOR],
  },
  '/contractor/integrations': {
    roles: [rolesConstants.CONTRACTOR, rolesConstants.MANAGER],
  },
  '/contractor/density-blaster': {
    roles: [rolesConstants.CONTRACTOR]
  },
  '/contractor/service-area': {
    roles: [rolesConstants.CONTRACTOR]
  },
  '/admin/waiting-area': {
    roles: [rolesConstants.ADMIN],
  },
  '/admin/list-batches': {
    roles: [rolesConstants.ADMIN],
  },
  '/admin/workers-list': {
    roles: [rolesConstants.ADMIN],
  },
  '/admin/bulk-addresses': {
    roles: [rolesConstants.ADMIN],
  },
  '/worker/hallway': {
    roles: [rolesConstants.SENIORWORKER],
  },
  '/admin/worker-analytics/[workerId]': {
    roles: [rolesConstants.ADMIN],
  },
  '/admin/dataset': {
    roles: [rolesConstants.ADMIN],
  },
  '/worker/measure': {
    roles: [rolesConstants.WORKER],
  },
  '/worker/dashboard': {
    roles: [rolesConstants.WORKER],
  },
  '/worker/list-batches': {
    roles: [rolesConstants.WORKER],
  },
  '/start-measuring': {
    roles: [],
  },
};

export const routeNames = {
  WIDGET_URL: '/widget/[id]',
  SIGN_IN: '/sign-in',
};

export const routeConstants = {
  DASHBOARD: {
    name: 'Dashboard',
    href: '/contractor/home',
    pathname: '/contractor/home',
  },
  SEARCH: {
    name: 'Search',
    basePath: `/contractor/search`,
    pathname: '/contractor/search/[id]',
  },
  ROBIN_SEARCH: {
    name: 'Search',
    basePath: `/contractor/search`,
    pathname: '/contractor/search/[id]',
  },
  MANAGER_SALES_SEARCH: {
    name: 'Search',
    basePath: `/contractor/search`,
    pathname: '/contractor/search/[id]',
  },
  SERVICES: {
    name: 'Services',
    href: '/contractor/services-packages',
    pathname: '/contractor/services-packages',
  },
  CREATE_SERVICE: {
    name: 'Create Service',
    href: '/contractor/create-service',
    pathname: '/contractor/create-service',
  },
  EDIT_SERVICE: {
    name: 'Edit Service',
    basePath: '/contractor/edit-service',
    pathname: '/contractor/edit-service/[id]',
  },
  CREATE_PACKAGE: {
    name: 'Create Package',
    href: '/contractor/create-package',
    pathname: '/contractor/create-package',
  },
  EDIT_PACKAGE: {
    name: 'Edit Package',
    basePath: '/contractor/edit-package',
    pathname: '/contractor/edit-package/[id]',
  },
  CREATE_DISCOUNT: {
    name: 'Create Discount',
    href: '/contractor/create-discount',
    pathname: '/contractor/create-discount',
  },
  EDIT_DISCOUNT: {
    name: 'Edit Discount',
    basePath: '/contractor/edit-discount',
    pathname: '/contractor/edit-discount/[id]',
  },
  SERVICE_AREA: {
    name: 'Service area',
    href: '/contractor/service-area',
    pathname: '/contractor/service-area',
  },
  INTEGRATIONS: {
    name: 'Integrations',
    href: '/contractor/integrations',
    pathname: '/contractor/integrations',
  },
  BRANDING: {
    name: 'Branding',
    href: '/contractor/branding',
    pathname: '/contractor/branding',
  },
  MEASUREMENTS: {
    name: 'Orders',
    href: '/contractor/orders',
    pathname: '/contractor/orders',
  },
  MARKETING: {
    name: 'Marketing',
    href: '/contractor/marketing',
    pathname: '/contractor/marketing',
  },
  DENSITY_BLASTER: {
    name: 'Density Blaster',
    href: '/contractor/density-blaster',
    pathname: '/contractor/density-blaster'
  },
  CREATE_TEMPLATE: {
    name: 'Create Template',
    href: '/contractor/template',
    pathname: '/contractor/template',
  },
  EDIT_TEMPLATE: {
    name: 'Edit Template',
    basePath: '/contractor/edit-template',
    pathname: '/contractor/edit-template/[id]',
  },
  PROFILE: {
    name: 'Profile',
    href: '/contractor/profile',
    pathname: '/contractor/profile',
  },
  MY_ACCOUNT: {
    name: 'My Account',
    href: '/contractor/my-account',
    pathname: '/contractor/my-account',
  },
  SUPPORT: {
    name: 'Support',
    href: '/contractor/support',
    pathname: '/contractor/support',
  },
  TUTORIALS: {
    name: 'Tutorials',
    href: '/contractor/support/tutorials',
    pathname: '/contractor/support/tutorials',
  },
  LOGOUT: {
    name: 'Logout',
  },
  WORKER: {
    HOME: {
      name: 'Home',
      href: '/worker/home',
      pathname: '/worker/home'
    },
    HALLWAY: {
      name: 'Hallway',
      href: '/worker/hallway',
      pathname: '/worker/hallway'
    },
    PRO_HALLWAY: {
      name: 'Pro Hallway',
      href: '/worker/prohallway',
      pathname: '/worker/prohallway'
    },
    DASHBOARD: {
      name: 'Worker Dashboard',
      href: '/worker/dashboard',
      pathname: '/worker/dashboard',
    },
    MEASURE: {
      name: 'Measure',
      href: '/worker/measure',
      pathname: '/worker/measure',
    },
    DEPLOY: {
      name: 'Deploy',
      href: '/worker/deploy',
      pathname: '/worker/deploy',
    }
  },
  ADMIN: {
    SEARCH_FEED: {
      name: 'Searches Feed',
      href: '/admin/searches-feed',
      pathname: '/admin/searches-feed',
    },
    WORKERS_LIST: {
      name: 'Workers List',
      href: '/admin/workers-list',
      pathname: '/admin/workers-list',
    },
    WORKERS: {
      name: 'Workers',
      href: '/admin/workers',
      pathname: '/admin/workers',
    },
    CONTRACTORS_LIST: {
      name: 'Contractors',
      href: '/admin/contractors-list',
      pathname: '/admin/contractor-list',
    },
    WAITING_AREA : {
      name: 'Waiting Area',
      href: '/admin/waiting-area',
      pathname: '/admin/waiting-area',
    },
    DATASET : {
      name: 'Data Set',
      href: '/admin/dataset',
      pathname: '/admin/dataset',
    },
    BULK_ADDRESSES : {
      name: 'Bulk Addresses',
      href: '/admin/bulk-addresses',
      pathname: '/admin/bulk-addresses',
    },
    BULK_ADDRESSES_MANAGER : {
      name: 'Bulk Addresses Manager',
      href: '/admin/bulk-addresses-manager',
      pathname: '/admin/bulk-addresses-manager',
    },
    APPROVED_WAITING_AREA : {
      name: 'Approved Waiting Area',
      href: '/admin/approved-waiting-area',
      pathname: '/admin/approved-waiting-area',
    },
    INTEGRATIONS: {
      name: 'Integrations',
      href: '/admin/integrations',
      pathname: '/admin/integrations',
    }
  },
  PRICING: {
    name: 'Pricing',
    href: '/contractor/pricing',
    pathname: '/contractor/pricing',
  },
  BULK_DATA: {
    name: 'Bulk Data',
    href: '/contractor/bulk-data',
    pathname: '/contractor/bulk-data',
  },
  PAYMENT_DETAILS : {
    name: 'Payment Details',
    href: '/contractor/payment-details',
    pathname: '/contractor/payment-details',
  },
  ORDER_DETAILS: {
    name: 'Order Details',
    basePath: '/contractor/order-details',
    pathname: '/contractor/order-details/[id]',
  },
  LEAD_DETAILS: {
    name: 'Lead Details',
    basePath: '/contractor/lead-details',
    pathname: '/contractor/lead-details/[id]',
  }
}
