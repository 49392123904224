export const searchInPlaceObjectComponents = (arr, key) => {
  const updated_array = arr.filter((item: any) => {
    if (item.types.includes(key)) {
      return item.long_name
    }
  })
  return updated_array.length ? updated_array[0].long_name : ''
}

export const get_Place_address_components_object = (address_components: any) => {
  let address_components_object: any = {
    // long name i.e IOWA
    street_number: "",
    route: "",
    locality: "",
    administrative_area_level_1: "",
    postal_code: "",
    country: "",

    // short names i.e IA
    // ==>>> Do not Uncomment the below code, we are adding them in foreach function <<==
    // street_number_short: "",
    // route_short: "",
    // locality_short: "",
    // administrative_area_level_1_short: "",
    // postal_code_short: "",
    // country_short: "",
  }

  Object.keys(address_components_object).forEach(key => {
    const component = address_components?.find((temp_component: any) => temp_component.types.includes(key));
    address_components_object = {
      ...address_components_object,
      [key]: `${component?.long_name || ""}`,
      [`${key}_short}`]: `${component?.short_name || ""}`
    }
  });

  return {
    ...address_components_object,
    primary_line: `${address_components_object.street_number} ${address_components_object.route}`,
    city: address_components_object.locality,
    state: address_components_object.administrative_area_level_1,
    state_short: address_components_object.administrative_area_level_1_short,
    zip_code: address_components_object.postal_code,
    country: address_components_object.country,
  }
}