import { SERVICE_AREA_TYPES } from '../constants/services.constants';
import { getServicePrice } from './getServicePrice';
import { getServiceSqFeet } from './getServiceSqFeet';

export const checkPackageHasCustomQuote = (packageItem, boundarySqFeet, sqFeet, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  for(let service of packageItem.services) {
    if (service.areaType === SERVICE_AREA_TYPES.BUILDING_AREA && buildingSquareFeet===0) {
      return true
    }
    let squareFeet = getServiceSqFeet(service.areaType, sqFeet, boundarySqFeet, buildingSquareFeet, polygonsFeaturesSqFeet);
    
    let servicePrice = getServicePrice(
      service.pricePerSquare,
      packageItem.quantity[service._id],
      service.minPrice,
      service.fixedPrice,
      squareFeet,
      service.ranges,
      service.areaType
    );
    if(servicePrice == -1) {
      return true
    }
  };
  
  return false
};

export const packageCustomQuoteServices = (packageItem, boundarySqFeet, sqFeet, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  let servicesObject = { [packageItem._id]: {} }
  for (let service of packageItem.services) {
    if (service.areaType === SERVICE_AREA_TYPES.BUILDING_AREA && buildingSquareFeet === 0) {
      const singleService = { [service._id]: 0 }
      servicesObject[packageItem._id] = { ...servicesObject[packageItem._id], ...singleService }
    } else {
      let squareFeet = getServiceSqFeet(service.areaType, sqFeet, boundarySqFeet, buildingSquareFeet, polygonsFeaturesSqFeet);

      let servicePrice = getServicePrice(
        service.pricePerSquare,
        packageItem.quantity[service._id],
        service.minPrice,
        service.fixedPrice,
        squareFeet,
        service.ranges,
        service.areaType
      );
      if (servicePrice === -1) {
        const singleService = { [service._id]: 0 }
        servicesObject[packageItem._id] = { ...servicesObject[packageItem._id], ...singleService }
      }
    }
  }
  return servicesObject
};
