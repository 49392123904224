import { templateJsonTypes } from "./template-json.types";

const INITIAL_STATE = {
    frontTemplate: null,
    backTemplate: null,
}

export const templateJsonReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action

    switch (type) {
        case templateJsonTypes.SET_FRONT_TEMPLATE:
            return {
                ...state,
                frontTemplate: payload
            }
        case templateJsonTypes.SET_BACK_TEMPLATE:
            return {
                ...state,
                backTemplate: payload
            }
        default:
            return state;
    }
}