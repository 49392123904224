export const leadTypes = {
  SET_LEAD: "SET_LEAD",
  SET_LEADS: "SET_LEADS",
  SET_LEADS_LOADING: "SET_LEADS_LOADING",
  SET_LEADS_BY_PLACE_ID_LOADING: "SET_LEADS_BY_PLACE_ID_LOADING",
  DELETE_LEAD_BY_ID: "DELETE_LEAD_BY_ID",
  SET_LEADS_INITIAL_STATE: "SET_LEADS_INITIAL_STATE",
  SET_WIDGET_LEAD_ADDRESS: "SET_WIDGET_LEAD_ADDRESS",
  SET_IS_LEAD_CREATED:"SET_IS_LEAD_CREATED",
  SET_FIELD_ROUTES_SUBSCRIPTION_IDS: "SET_FIELD_ROUTES_SUBSCRIPTION_IDS",
  SET_FIELD_ROUTES_LEAD: "SET_FIELD_ROUTES_LEAD",
  SET_JOBBER_LEAD: "SET_JOBBER_LEAD"
}