import React from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const HallwayIcon = ({alt, src}: any) => {
    const { width, height } = useWindowDimensions()
  return <img src={src} width={`${height <= 580 ? '28px' : '32px'}`} height={`${height <= 580 ? '28px' : '32px'}`} alt={alt} /> 

}

export default HallwayIcon;
