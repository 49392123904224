import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFound from '../../library/stories/Cards/NotFound';
import { Table } from '../../library/stories/Tables/Table';
import { getLeads } from '../../redux/leads/leads.actions';
import { routeConstants } from '../../routes';
import { changeDate } from '../../utils/formatDate';
import { formatPhone } from '../../utils/formatPhone';
import { useRouter } from 'next/router';

type Props = {
  handleSorting: any,
  sortField: string,
  sortOrder: string,
  pageNo: number,
  utmFilter: string,
  searchInput: string,
  utmParametersFilter: string;
  docsPerPage: string;
}

const LeadsTable = (props: Props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { ordersFilter } = useSelector(
    (state: any) => state.contractor
  );
  const { leads } = useSelector((state: any) => state.leads)

  const { sortField, sortOrder, pageNo, utmFilter, searchInput, utmParametersFilter, docsPerPage } = props

  const query = `?sortField=${sortField}&sortOrder=${sortOrder}&page=${pageNo}&search=${searchInput}&utmParameter=${utmFilter}&utmParameters=${utmParametersFilter}&docsPerPage=${docsPerPage}`

  useEffect(() => {
    dispatch(getLeads(query))
  }, [sortField, sortOrder, ordersFilter, pageNo, utmFilter, utmParametersFilter, docsPerPage])

  const handleAddressClick = (address: string, leadId: any) => {
    return (
      <span className='cursor-pointer hover:underline underline-offset-2 hover:text-green-600'
        onClick={() => {
          router.push(`${routeConstants.LEAD_DETAILS.basePath}/${leadId}`)
        }}>
        {address}
      </span>
    )
  }


  const getActiveTabData = () => {
    let currentData: any[] = []
    leads?.map((each: any) =>
      currentData.push({
        key: each?._id,
        address: handleAddressClick(each?.measurement?.address, each?._id),
        name: each?.name,
        email: each?.email,
        phone: each?.phone ? formatPhone(each?.phone) : formatPhone(each?.referrerInfo?.phone) ,
        date: changeDate(each?.createdAt),
        action: <a href={`${routeConstants.LEAD_DETAILS.basePath}/${each?._id}`} className="px-5 py-2 border-1 border-[#DFE6E2] rounded-md bg-white text-black hover:text-green-500">View</a>,
        isChecked: each.isChecked
      })
    )
    return currentData
  }

  const getActiveTabHeaders = () => {
    return [
      { name: 'Address', ref: 'measurement.address' },
      { name: 'Name', ref: 'name' },
      { name: 'Email', ref: 'email' },
      { name: 'Phone', ref: 'phone' },
      { name: 'Date', ref: 'createdAt' },
      { name: 'view', ref: 'view' },
    ]
  }

  const tabledata = {
    tabledata: getActiveTabData(),
    headers: getActiveTabHeaders(),
    enableSorting: true,
    handleSorting: props.handleSorting
  };

  return (
    <>
      {
        leads && leads.length ? <Table {...tabledata} /> : <NotFound message='leads' />
      }
    </>
  )
}

export default LeadsTable