export const GET_CONTRACTOR_ORDERS = {
	METHOD: 'GET',
	PATH: '/orders'
}

export const GET_CONTRACTOR_ORDERS_FILTERED = {
	METHOD: 'GET',
	PATH: '/orders/filter'
}

// work 
export const GET_CONTRACTOR_ORDERS_MEASUREMENTS = {
	METHOD: 'GET',
	PATH: '/orders/filter/measurements'
}
// end 

export const GET_SALESMAN_ORDERS_FILTERED = {
	METHOD: 'GET',
	PATH: '/orders/salesman'
}

export const GET_MULTIPLE_SALESMEN_ORDERS_FILTERED = {
	METHOD: 'POST',
	PATH: '/orders/salesmen/multiple'
}

export const GET_ORDERS_OVERVIEW = {
	METHOD: 'GET',
	PATH: '/orders/overview'
}

export const GET_SALESMAN_ORDERS_OVERVIEW = {
	METHOD: 'GET',
	PATH: '/orders/salesman/overview'
}

export const GET_MULTIPLE_SALESMAN_ORDERS_OVERVIEW = {
	METHOD: 'POST',
	PATH: '/orders/multiple/salesmen/overview'
}


export const DELETE_ORDER_BY_ID = {
	METHOD: 'DELETE',
	PATH: '/orders/order'
}

export const GET_SEARCHED_ORDERS = {
	METHOD: 'POST',
	PATH: '/orders/search'
}

// work 
export const GET_SEARCHED_ORDERS_MEASUREMENTS = {
	METHOD: 'POST',
	PATH: '/orders/search/meauremets'
}
// end 

export const GET_ORDERS_UTM_OVERVIEW = {
	METHOD: 'GET',
	PATH: '/orders/overview/utm'
}

export const SET_HAS_TEAM_MEMBERS = {
	METHOD: 'GET',
	PATH: '/teams/count'
}
