export const integrationTypes = {
  SET_INTEGRATIONS: 'SET_INTEGRATIONS',
  SET_INTEGRATIONS_LOADING: 'SET_INTEGRATIONS_LOADING',
  SET_INTEGRATIONS_ERROR: 'SET_INTEGRATIONS_ERROR',
  SET_INTEGRATIONS_ALL:"SET_INTEGRATIONS_ALL",
  SET_INTEGRATIONS_TEMP_DATA:"SET_INTEGRATIONS_TEMP_DATA",
  SET_GTAG_STATUS:"SET_GTAG_STATUS",
} 

export const GTAGLABEL = {
  Deeplawn_Prospect: "Deeplawn_Prospect",
  Deeplawn_Search: "Deeplawn_Search",
  Deeplawn_Viewed_Prices: "Deeplawn_Viewed_Prices",
  Deeplawn_Source_Added: "Deeplawn_Source_Added",
  Deeplawn_Viewed_Checkout: "Deeplawn_Viewed_Checkout"
}