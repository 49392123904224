export const BoundaryTypeConstant: any = {
  BOUNDARY: {
    key: 'BOUNDARY',
    type: "Boundary",
    color: '#3DE396',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeColor: '#FF0000',
      strokeOpacity: 0.4,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.0,
      zIndex: 1
    },
    displayToggleBtn: false,
    // search card
    search_option_icon: '/assets/grass-option.png',
  }
}
// paths: boundaryCoordinates,
//         strokeColor: '#FF0000',
//         strokeOpacity: disabledLawnSquareFootage(customer) ? 0.8 : 0.4,
//         strokeWeight: 2,
//         fillColor: '#FF0000',
//         fillOpacity: 0.0,
//         zIndex: 1

// Make sure to add polygon color on server constants.ts > POLYGONS_COLOR, SEARCH_TYPE

export const ProFeatures: any = {
  LAWN: {
    key: 'LAWN',
    type: "Lawn", //ONLY FOR DISPLAY PURPOSE
    color: '#3DE396',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
    // search card
    search_option_icon: '/assets/grass-option.png',
  },
  BUILDING: {
    key: 'BUILDING',
    type: "Building",
    color: '#f24e3f',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.20,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: true,
  },
  PARKING: {
    key: 'PARKING',
    type: "Parking",
    color: '#12089e',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  STANDARD_PARKING_AREA: {
    key: 'STANDARD_PARKING_AREA',
    type: "Standard Parking Area",
    color: '#040f13',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  HANDICAP_PARKING_AREA: {
    key: 'HANDICAP_PARKING_AREA',
    type: "Handicap Parking Area",
    color: '#0b2d39',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  STANDARD_PARKING_SPOT: {
    key: 'STANDARD_PARKING_SPOT',
    type: "Standard Parking Spot",
    color: '#ABBD79',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    icon: null,
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  HANDICAP_PARKING_SPOT: {
    key: 'HANDICAP_PARKING_SPOT',
    type: "Handicap Parking Spot",
    color: '#AFFD79',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    icon: null,
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  DRIVEWAY: {
    key: 'DRIVEWAY',
    type: "Driveway",
    color: '#C97C0B',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  SIDEWALK: {
    key: 'SIDEWALK',
    type: "Sidewalk",
    color: '#aea405',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  ROAD: {
    key: 'ROAD',
    type: "Road",
    color: '#C97C79',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  TREE: {
    key: 'TREE',
    type: "Tree",
    color: '#CABC79',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    // icon: '/tree.png',
    icon: null,
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  // POLE: {
  //  key:'LAWN',
  // type: "POLE",
  //   color: '#CABAA9',
  //   sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
  //   icon: '/electric-pole.png',
  //   properties: {
  //     strokeOpacity: 0.8,
  //     strokeWeight: 2,
  //     fillOpacity: 0.35,
  //     suppressUndo: true,
  //     zIndex: 2
  //   },
  //   displaySearchOption: true,
  //   displayToggleBtn: false,
  // },
  PAVEMENT: {
    key: 'PAVEMENT',
    type: "Pavement",
    color: '#644e1c',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  LANDSCAPE_BED: {
    key: 'LANDSCAPE_BED',
    type: "Landscape Bed",
    color: '#DADA79',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  ASPHALT_SURFACE: {
    key: 'ASPHALT_SURFACE',
    type: "Asphalt Surface",
    color: '#DABD00',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  MULCH_BED: {
    key: 'MULCH_BED',
    type: "Mulch Bed",
    color: '#8B4513',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  ROCK_BED: {
    key: 'ROCK_BED',
    type: "Rock Bed",
    color: '#A9A9A9',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  PRIVATE_SIDEWALK: {
    key: 'PRIVATE_SIDEWALK',
    type: "Private Sidewalk",
    color: '#0000FF',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  PUBLIC_SIDEWALK: {
    key: 'PUBLIC_SIDEWALK',
    type: "Public Sidewalk",
    color: '#FF5733',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  FIELD_BASIN: {
    key: 'FIELD_BASIN',
    type: "Field Basin / Retention Pond",
    color: '#2196F3',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
}

export const PolygonTypeConsts: any = {
  LAWN: {
    key: 'LAWN',
    type: "Lawn",
    color: '#3DE396',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
    // search card
    displaySearchOption: true,
    searchTypeFeatures: ['LAWN', 'BUILDING', 'PARKING', 'STANDARD_PARKING', 'HANDICAP_PARKING'],
    search_option_icon: '/assets/grass-option.png',
  },
  BUILDING: {
    key: 'BUILDING',
    type: "BUILDING",
    color: '#f24e3f',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.20,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: true,
  },
  PARKING: {
    key: 'PARKING',
    type: "PARKING",
    color: '#12089e',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  STANDARD_PARKING: {
    key: 'STANDARD_PARKING',
    type: "Standard Parking",
    color: '#040f13',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  HANDICAP_PARKING: {
    key: 'HANDICAP_PARKING',
    type: "Handicap Parking",
    color: '#0b2d39',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  DRIVEWAY: {
    key: 'DRIVEWAY',
    type: "DRIVEWAY",
    color: '#C97C0B',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  SIDEWALK: {
    key: 'SIDEWALK',
    type: "SIDEWALK",
    color: '#F9EC1B',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  SNOW: {
    key: 'SNOW',
    type: "Snow",
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    displayToggleBtn: true,
    includeMultipleFeatures: true,
    polygonFeatures: ['DRIVEWAY', 'SIDEWALK'], // db features
    // search card
    displaySearchOption: true,
    searchTypeFeatures: ['DRIVEWAY', 'SIDEWALK'],
    search_option_icon: '/assets/snowflake.png',
  }
}

export const getFeatureName = (key) => {
  return PolygonTypeConsts[key].type
}

export const getFeaturesTypes = () => {
  return Object.keys(ProFeatures).map((key) => key).filter((feature) => feature !== "DRIVEWAY")
}
export const getDeeplawnFeaturesTypes = () => {
  let featuresList: any = []
  Object.keys(PolygonTypeConsts).map((key) => {
    if (PolygonTypeConsts[key]?.displaySearchOption == true) {
      featuresList.push(...PolygonTypeConsts[key]['searchTypeFeatures'])
    }
  })
  return featuresList
}

export function formattedFeature(str: string) {
  let formattedString;
  formattedString = str.replace(/_/g, " ")
  return formattedString
    .split(" ")
    .map((word: string) => word.charAt(0) + word.slice(1).toLowerCase())
    .join(" ");
}

export const getToggleFeatures = (feature) => {
  if (PolygonTypeConsts[feature].includeMultipleFeatures) {
    return PolygonTypeConsts[feature].polygonFeatures
  }
  return [feature]
}

export const getToggleValue = (polygonFeatures, checkFeature) => {
  if (PolygonTypeConsts[checkFeature].includeMultipleFeatures) {
    const temp_features = PolygonTypeConsts[checkFeature].polygonFeatures
    return temp_features.every(feature => polygonFeatures.includes(feature))
  }
  return polygonFeatures.includes(checkFeature)
}

export const getPopupFeatureName = (toggleFeatures) => {
  let featureName = toggleFeatures[0]
  if (toggleFeatures.length > 1) {
    featureName = Object.keys(PolygonTypeConsts).find(key => PolygonTypeConsts[key].includeMultipleFeatures && PolygonTypeConsts[key].polygonFeatures.every(feature => toggleFeatures.includes(feature)))
  }
  return PolygonTypeConsts[featureName].type
}

export const isMutipleSearchTypes = (customerPolygonFeatures) => {
  const temp_feature = Object.keys(PolygonTypeConsts).find(key => PolygonTypeConsts[key].includeMultipleFeatures && PolygonTypeConsts[key].polygonFeatures.every(feature => customerPolygonFeatures.includes(feature)))
  if (temp_feature) return true
  return false
}

export const getSearchTypes = () => {
  return Object.keys(PolygonTypeConsts).filter((singleType: string) => PolygonTypeConsts[singleType]?.displaySearchOption == true)
}