// import { ObjectLiteralElement } from "typescript";
// import { Button } from "../Buttons/Button";
import { useEffect, useState } from "react";
// import {FaSort} from 'react-icons/fa'
import { RxCaretSort } from 'react-icons/rx'
import { useSelector } from "react-redux";
import NotFound from "../Cards/NotFound";

interface TableProps {
  handleSorting?: (sortField: string, sortOrder: string) => void;
  tabledata?: any[]
  headers: any[]
  isEdit?: string
  enableSorting?: boolean
  selected?: any[]
  setSelected?: any
  isMeasurementPage?: boolean
  allSelected?: any
  setAllSelected?: any,
  name?: any,
  isPro?: boolean
  // rowClick?: (rowId: any) => void;
}
export const Table = ({ tabledata, headers, enableSorting, handleSorting, name, isEdit, ...props }: TableProps) => {
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  // const [disabled, setDisabled] = useState(false)
  const { ordersFilter } = useSelector((state: any) => state.contractor);

  function handleSelection(bloc: any) {
    if (!props.selected.includes(bloc.key)) {
      props.setSelected((prev) => [...prev, bloc.key])
    } else {
      let temp_arr = [...props.selected]
      temp_arr.splice(temp_arr.indexOf(bloc.key), 1)
      props.setSelected(temp_arr)
    }
  }

  function handleSelectionAll() {
    if (props.allSelected === null) {
      props.setSelected([])
      props.setAllSelected(ordersFilter)
      // setDisabled(true)
    } else {
      props.setAllSelected(null)
      // setDisabled(false)
    }
    // for (let bloc of tabledata) {
    //   if (props.selected?.includes(bloc.key)) {
    //     continue
    //   } else {
    //     props.setSelected((prev) => [...prev, bloc.key])
    //   }
    // }
  }

  function checkAllChecked() {
    return props.allSelected !== null
    // return tabledata?.every(i => props.selected?.includes(i.key))
  }

  // const handleRowClick = (key: any, item: string) => {
  //   if (item === 'address') {
  //     if ((key != undefined || key != null) && props.rowClick) {
  //       props.rowClick(key)
  //     }
  //   }
  // }

  const handleSortingChange = (accessor: string) => {
    const newSortOrder =
      sortField === accessor && sortOrder === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setSortOrder(newSortOrder);
    handleSorting && handleSorting(accessor, newSortOrder);
  };
  if (!tabledata || !tabledata.length) return <NotFound message={name || 'data'} />
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-0">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead>
                <tr>
                  {props.isMeasurementPage && <th>
                    <input type="checkbox" className="w-5 h-5 rounded-md cursor-pointer text-green-600 bg-white border-gray-300 focus:ring-transparent float-left"
                      onChange={handleSelectionAll} checked={checkAllChecked()}
                    />
                  </th>}
                  {headers && headers.map((each, idx) =>
                    <th
                      key={idx}
                      scope="col"
                      className={`pl-4 pr-5 min-w-min w-auto py-2 text-left text-normal font-semibold text-gray-500 sm:pl-0`}
                    >
                      <div className="flex justify-start items-center gap-2 ml-5 ">
                        {each.name === 'view' ? 'Details' : each.name === 'delete' ? 'Delete' : each.name}
                        {enableSorting && each.name !== 'view' && each.ref !== null ?
                          <RxCaretSort size={20} className="text-gray-500 hover:text-gray-700 text-sm cursor-pointer" onClick={() => handleSortingChange(each.ref)} />
                          : null}
                      </div>
                    </th>
                  )}
                </tr>

              </thead>
              <tbody className="divide-y w-96">
                {tabledata && tabledata?.map((each, index: any) =>
                  <>
                    <tr key={index} className={`hover:bg-gray-50 ${!each?.isChecked ? "bg-green-100 bg-opacity-30" : ""}`}>
                      {(props.isMeasurementPage && !props.isPro) && <td>
                        <input type="checkbox" className={`w-5 h-5 cursor-pointer rounded-md text-green-600 border-gray-300 focus:ring-transparent ${props.allSelected && 'bg-green-500 opacity-70'}`}
                          onChange={() => handleSelection(each)} disabled={props.allSelected} checked={props.selected?.includes(each.key)} />
                      </td>}
                      {(props.isPro && each.status.props.children === 'Completed') && <td>
                        <input type="checkbox" className={`w-5 h-5 cursor-pointer rounded-md text-green-600 border-gray-300 focus:ring-transparent ${props.allSelected && 'bg-green-500 opacity-70'}`}
                          onChange={() => handleSelection(each)} disabled={props.allSelected} checked={props.selected?.includes(each.key)} />
                      </td>
                      }
                      {(props.isPro && each.status.props.children !== 'Completed') && <td>
                        <div></div>
                      </td>
                      }
                      {Object.keys(tabledata[0]).filter(item => item !== 'key').map((item) =>
                        <td className="whitespace-nowrap min-w-min w-auto pr-5 py-4 text-sm text-black pl-5">
                          {(each[item] && each[item] !== "N/A") ? each[item] : each[item] == false ? null : '-'}
                        </td>
                      )}
                    </tr>
                  </>
                )}
                {isEdit && <tr>
                  <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                  <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                  <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                  <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                  <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                </tr>}
              </tbody>
            </table>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
}
