import { SERVICE_AREA_TYPES } from '../constants/services.constants';
import { getServicePrice } from './getServicePrice';
import { getServiceSqFeet } from './getServiceSqFeet';
import { roundToTwoDigits } from './roundToTwoDigits';

export const getPackagePrice = (packageItem, sqFeet, boundarySqFeet, customQuotes, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  let packagePrice = 0;
  for(const service of packageItem.services) {
    let squareFeet = getServiceSqFeet(service.areaType, sqFeet, boundarySqFeet, buildingSquareFeet, polygonsFeaturesSqFeet);

    let servicePrice = getServicePrice(
      service.pricePerSquare,
      packageItem.quantity[service._id],
      service.minPrice,
      service.fixedPrice,
      squareFeet,
      service.ranges,
      service.areaType
    );
    if(servicePrice === -1){
      if(customQuotes?.packages[packageItem._id]){
        return customQuotes.packages[packageItem._id]
      } else if (customQuotes?.servicesInPackage && customQuotes?.servicesInPackage[packageItem._id]) {
        servicePrice = (customQuotes?.servicesInPackage[packageItem._id][service._id] * (packageItem.quantity[service._id] || 1))
      } else {
        return 0
      }
    }
    packagePrice += servicePrice
  };
  if(customQuotes?.packages[packageItem._id]){
    return customQuotes.packages[packageItem._id]
  }
  return packagePrice.toFixed(2);
};

export const getProTurfPackagePricePayGo = (packageItem, sqFeet, boundarySqFeet, customQuotes, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  let packagePrice = 0;
  const service = packageItem.services[0]
  let squareFeet = getServiceSqFeet(service.areaType, sqFeet, boundarySqFeet, buildingSquareFeet, polygonsFeaturesSqFeet);
  
  let servicePrice = getServicePrice(
    service.pricePerSquare,
    packageItem.quantity[service._id],
    service.minPrice,
    service.fixedPrice,
    squareFeet,
    service.ranges,
    service.areaType
    );
    if (servicePrice === -1) {
      if (customQuotes?.packages[packageItem._id]) {
        return customQuotes.packages[packageItem._id]
      } else if (customQuotes?.servicesInPackage && customQuotes?.servicesInPackage[packageItem._id]) {
        servicePrice = customQuotes?.servicesInPackage[packageItem._id][service._id]
      } else {
        return 0
      }
    }
    packagePrice += servicePrice
  //   if (customQuotes?.packages[packageItem._id]) {
  //   return customQuotes.packages[packageItem._id]
  // }
  return packagePrice.toFixed(2);
};

export const getProTurfPackagePriceEzPay = (packageItem, sqFeet, boundarySqFeet, customQuotes, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  let packagePrice = 0;
  
  for(const service of packageItem.services) {
    let squareFeet = getServiceSqFeet(service.areaType, sqFeet, boundarySqFeet, buildingSquareFeet, polygonsFeaturesSqFeet);

    let servicePrice = getServicePrice(
      service.pricePerSquare,
      packageItem.quantity[service._id],
      service.minPrice,
      service.fixedPrice,
      squareFeet,
      service.ranges,
      service.areaType
    );
    if(servicePrice === -1){
      if(customQuotes?.packages[packageItem._id]){
        return customQuotes.packages[packageItem._id]
      } else {
        return 0
      }
    }
    packagePrice += servicePrice
  };
  //   if (customQuotes?.packages[packageItem._id]) {
  //   return customQuotes.packages[packageItem._id]
  // }
  return roundToTwoDigits(packagePrice);
};
