import { isEmeraldLawn, isOnQuotePage } from "../redux/widget/widget.selectors"
import { validateEmail } from "./app-utils"

export const validateOrder = (order, customer, orderQuestions) => {
  const {user, services,referrerInfo} = order
  const { isContactInfo: { details: {consent, dropdownProperties} } } = customer;

  let isValid = true
  let errors = []
  if (user && (user.name == "" || user.email == "" || (isEmeraldLawn(customer?.settings) ? referrerInfo?.phone==="": user.phone == ""))){
    isValid = false
    errors.push("Fill Order Information form correctly")
  }
  if(!services.length && !order.packages.length){
    isValid = false
    errors.push("Please select at least one package or service")
  }
  if(customer.isSignatureEnabled && !order.signature) {
    isValid = false
    errors.push("Please add your Signatures")
  }

  if((consent?.isEnabled && consent?.isRequired) && (!order?.user?.consent?.answer || !order?.user?.consent?.title)) {
    isValid = false
    errors.push("Please tick the consent form if you agree")
  }

  if(dropdownProperties?.isEnabled && (!order?.user?.dropdownProperties?.answer)) {
    isValid = false
    errors.push("Please select an option")
  }

  if(orderQuestions && orderQuestions.find(question => (question['answer'].length === 0 && (question?.isRequired !== false)))) {
    isValid = false
    errors.push("Please answer all the questions")
  }
  if(orderQuestions && orderQuestions.find(question => question['question'] === 'Are you tax exempt?' && question['answer'].includes('Yes') && !question.fileLink) ) {
    isValid = false
    errors.push("Please upload a file")
  }
  
  return {isValid, errors}
}

export const validateConvertToOrder = (order,isCustomQuoteOrder) => {
  const {user, services,packages,referrerInfo,measurement} = order
  let status = true
  let error=''
  if (user && ((user?.name == "" && referrerInfo?.referrerName==="") || user?.email == "" || (referrerInfo?.phone==="" && user?.phone == ""))){
    status = false
    let missingInfo=[]
    if(referrerInfo?.referrerName==="" && user?.name == ""){
      missingInfo.push('Name')
    }
    if(user?.email == ""){
      missingInfo.push('Email')
    }
    if(referrerInfo?.phone==="" && user?.phone == ""){
      missingInfo.push('Phone')
    }
    missingInfo.map((each,idx)=>{
      if(idx!=missingInfo.length-1){

        error+=`${each},`
      }
      else{
        error+=`${each}`
      }
      error+=" needs to present to create Order."
    })
  }
  if(!validateEmail(user?.email)?.length){
    status=false
    error='Email is incorrect'
  }
  if(isCustomQuoteOrder){
    status=false
    error='Order cannot be custom quote'
  }
  if(!services?.length && !packages?.length){
    status=false
    error='There needs to be at least one service or package in lead'
  }
  if(!measurement){
    status=false
    error='There is no measurement in this lead that can be used to convert it to Order'
  }
  
  return {status, error}
}