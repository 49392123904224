export const roundToTwoDigits = (number) => {
  return (Math.round(number * 100) / 100)
}

export const roundToNDigits = (number,N) => {
  let factor=10
  for(let i=0;i<=N;i++){
    factor*=10
  }
  return Math.round(number * (factor)) / factor
}