import { realGreenTypes } from "./real-green.types";

const INITIAL_STATE = {
  currentleadData: null,
  allPackages: null,
  allServices: null,
  selectedItems: [],
  selectedIds:[],
  packagesAdded: false,
  servicesAdded: false,
  alreadyExists: false,
  leadStatus:null,
  allDiscounts:[],
  allSources:[],
  loading:false,
  flagForNew:false,
  orderInfo:null
}

export const realGreenReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case realGreenTypes.RG_LOADING:
      return {
        ...state,
        loading: payload
      }
    case realGreenTypes.SET_REAL_GREEN_LEAD:
      let customerStatus=state.leadStatus
      if(payload?.customerData){
        if(payload?.customerData?.statusCharacter==="4"){
          customerStatus="lead"
        }
        if(payload?.customerData?.statusCharacter==="9"){
          customerStatus="order"
        }
      }
      return {
        ...state,
        currentleadData: { ...state.currentleadData, ...payload },
        leadStatus:customerStatus
      }
    case realGreenTypes.UPDATE_REAL_GREEN_LEAD:
      return {
        ...state,
        leads: payload,
        currentleadData: payload
      }
      case realGreenTypes.UPDATE_REAL_GREEN_CURRENT_LEAD:
      return {
        ...state,
        currentleadData:{...state.currentleadData,customerData: payload}
      }
    case realGreenTypes.GET_REAL_GREEN_LEAD:
      return {
        ...state,
        leads: payload,
        currentleadData: payload
      }
    case realGreenTypes.SET_REAL_GREEN_ALL_PACKAGES:
      return {
        ...state,
        allPackages: payload,
      }
    case realGreenTypes.SET_REAL_GREEN_ALL_SERVICES:
      return {
        ...state,
        allServices: payload,
      }
    case realGreenTypes.SET_REAL_GREEN_ALL_DISCOUNTS:
      return {
        ...state,
        allDiscounts: payload,
      }
    case realGreenTypes.SET_REAL_GREEN_ALL_SOURCES:
      return {
        ...state,
        allSources: payload,
      }
    case realGreenTypes.SET_REAL_GREEN_SELECTED_ITEMS:
     
      return {
        ...state,
        selectedItems: payload,
      }
      case realGreenTypes.REPLACE_REAL_GREEN_SELECTED_IDS:
     
      return {
        ...state,
        selectedIds: [...payload],
      }
      case realGreenTypes.SET_REAL_GREEN_SELECTED_IDS:
     
      return {
        ...state,
        selectedIds: [...state.selectedIds,...payload],
      }

      case realGreenTypes.SET_REAL_GREEN_INITIAL_STATE:
        return {
          ...INITIAL_STATE,
          allSources:state.allSources,
          allPackages:state.allPackages,
          allServices:state.allServices,
        }
        case realGreenTypes.SET_REAL_GREEN_FLAG_FOR_NEW:
          return {
            ...state,
            flagForNew: payload,
          }
    case "RG_PACKAGES_ADDED":
      return {
        ...state,
        packagesAdded: true,
      }
    case "RG_SERVICES_ADDED":
      return {
        ...state,
        servicesAdded: true,
      }
      case realGreenTypes.SET_REAL_GREEN_ORDER_INFO:
      return {
        ...state,
        orderInfo: { ...state.orderInfo, ...payload },
      }
    default:
      return state;
  }
}