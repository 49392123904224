import { routeConstants } from "../../routes"
import { getPackagePrice } from "../../utils/getPackagePrice"
import getPackagePriceWithDiscount from "../../utils/getPackagePriceWithDiscount"
import { getServicePrice } from "../../utils/getServicePrice"
import getServicePriceWithDiscount from "../../utils/getServicePriceWithDiscount"
import { getServiceQuantity } from "../../utils/getServiceQuantity"
import sendRequest from "../../utils/useAxios"
import { getCustomer } from "../customer/customer.actions"
import { setFieldRoutesSubscriptionId } from "../leads/leads.actions"
import { CREATE_INTEGRATION, UPDATE_INTEGRATION } from "./integrations.api"
import { integrationTypes } from "./integrations.types"

export const createIntegration = (integration, router?) => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setIntegrationError(null))
  const { data } = await sendRequest(CREATE_INTEGRATION.METHOD, CREATE_INTEGRATION.PATH, integration)
  if (!data || data.statusCode) {
    dispatch(setIntegrationError("Integration Not Succesfull"))
    return
  } else {
    dispatch(getCustomer())
    dispatch(setIntegrationError(null))
    if (router) {
      router.push(routeConstants.INTEGRATIONS.pathname)
    }
  }
}

export const updateIntegration = (integration, id) => async dispatch => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(UPDATE_INTEGRATION.METHOD, `${UPDATE_INTEGRATION.PATH}/${id}`, integration)
  if(!data.statusCode){
    dispatch(getCustomer())
  }
}

export const deleteIntegration = async(customerId,type) =>  {
  const { data } = await sendRequest('DELETE', `/integrations/removeIntegration/${customerId}/${type}`)
  return data
}

const setLoading = payload => async dispatch => {
  dispatch({
    type: integrationTypes.SET_INTEGRATIONS_LOADING,
    payload
  })
}
export const setIntegrationError = payload => async dispatch => {
  dispatch({
    type: integrationTypes.SET_INTEGRATIONS_ERROR,
    payload
  })
  setTimeout(() => {
    dispatch({
      type: integrationTypes.SET_INTEGRATIONS_ERROR,
      payload: ""
    })
  }, 4000);
}

export const checkRealGreenIntegration = async (customerId) => {
  const { data } = await sendRequest('GET', `/integrations/checkIntegration/${customerId}/realGreen`)
  if (data && !data.statusCode) {
      return data
  }
  setIntegrationError(data)
}

export const getAllIntegrations = customerId => async dispatch => {
  const { data } = await sendRequest('GET', `/integrations/customer/${customerId}`)
  if (data && !data.statusCode) {
    dispatch({
      type:integrationTypes.SET_INTEGRATIONS_ALL,
      payload:data
    })
      return data
  }
  setIntegrationError(data)
}

//Field Routes
 export const handleFieldRoutes = (services, packages,setFieldRoutesWorking) => async (dispatch, getState) =>{
  const state = getState()
  const { user } = state.user;
  const { lead, fieldRoutesSubscriptionIDs } = state.leads;
  const { sqFeet, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const {customQuotes,cartPaymentType,quantity, boundary } = state.widget;
  const {allIntegrations} = state.integrations;
  
  
    for (let service of services) {
      if (service?.fieldRoutesServiceID) {
        if (!fieldRoutesSubscriptionIDs.some(obj => obj.serviceID === service?.fieldRoutesServiceID)) {
          try {
            setFieldRoutesWorking(true)
            const servicePrice = getServicePrice(
              service.pricePerSquare, 
              getServiceQuantity(quantity[service._id] ? quantity[service._id] : 1, service._id), 
              service.minPrice, 
              service.fixedPrice, 
              sqFeet, 
              service.ranges, 
              service.areaType
            )

            const { discountedPrice } = getServicePriceWithDiscount(
              service, 
              quantity, 
              sqFeet, 
              cartPaymentType, 
              boundary, 
              customQuotes, 
              measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, 
              polygonsFeaturesSqFeet
            )  
            console.log({servicePrice,  discountedPrice: parseFloat(discountedPrice)})
            const discount = (servicePrice ?? 0) - parseFloat(discountedPrice)
            const { data } = await sendRequest("POST", '/integrations/fieldRoutes/add-subscription', {
              customerID: lead.fieldRoutesIntegration.contactID,
              active: 0,
              soldBy:user?.fieldRoutesEmployeeId,
              serviceID: service.fieldRoutesServiceID,
              servicePrice,
              discount,
              loginUrl: allIntegrations?.fieldRoutes?.auth?.loginUrl,
              authenticationKey: allIntegrations?.fieldRoutes?.auth?.authenticationKey,
              authenticationToken: allIntegrations?.fieldRoutes?.auth?.authenticationToken
            })
            dispatch(setFieldRoutesSubscriptionId({serviceID: service?.fieldRoutesServiceID, subscriptionID: data.result}))
            setFieldRoutesWorking(false)
          } catch(err) {
            console.error(err)
          }
        }
      }
    }
    for (let packageItem of packages) {
      if (packageItem?.fieldRoutesServiceID) {
        if (!fieldRoutesSubscriptionIDs.some(obj => obj.serviceID === packageItem?.fieldRoutesServiceID)) {
          try {
            setFieldRoutesWorking(true)

            const packagePrice = getPackagePrice(
              packageItem, 
              sqFeet,
              boundary?.acreage_calc * 43560,
              customQuotes,
              measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0,
              polygonsFeaturesSqFeet
            )

            const { discountedPrice } = getPackagePriceWithDiscount(
              packageItem,
              sqFeet,
              boundary,
              cartPaymentType, 
              customQuotes,
              measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0,
              polygonsFeaturesSqFeet
            )
            
            console.log({packagePrice, discountedPrice})
            const discount = (packagePrice ?? 0) - parseFloat(discountedPrice)
            const { data } = await sendRequest("POST", '/integrations/fieldRoutes/add-subscription', {
              customerID: lead.fieldRoutesIntegration.contactID,
              active: 0,
              soldBy: user?.fieldRoutesEmployeeId,
              serviceID: packageItem.fieldRoutesServiceID,
              servicePrice: packagePrice,
              discount,
              loginUrl: allIntegrations?.fieldRoutes?.auth?.loginUrl,
              authenticationKey: allIntegrations?.fieldRoutes?.auth?.authenticationKey,
              authenticationToken: allIntegrations?.fieldRoutes?.auth?.authenticationToken
            })
            dispatch(setFieldRoutesSubscriptionId({serviceID: packageItem?.fieldRoutesServiceID, subscriptionID: data.result}))
            setFieldRoutesWorking(false)
          } catch(err) {
            console.error(err)
          }
        }
      }
    }
  }

  export const getDeeplawnApiContractors = async () => {

    try {
      const {data} = await sendRequest('GET', '/integrations/deeplawnApi/contractors')

      if (!data) {
        return null
      }

      return data;

    } catch (error) {
      return error      
    }
  }

  export const fetchCloverItems = async (customerId:string) => {
    try {
      const {data} = await sendRequest('GET', `/integrations/clover-payment/items?customerId=${customerId}`)

      if (data && !data.statusCode) {
        return data;
      }
      
      return []

    } catch (error) {
      setIntegrationError(['Failed to fetch Clover Items'])
      return []
    }
  }
  export const setIntegrationTempData = (payload:any )=> async dispatch => {
    dispatch({
      type: integrationTypes.SET_INTEGRATIONS_TEMP_DATA,
      payload
    })
  }

export const setGTagStatus = (payload: any) => async dispatch => {
  dispatch({
    type: integrationTypes.SET_GTAG_STATUS,
    payload
  })
}