export const getContractorEmail = (businessEmail, userEmail) => {
    if (getBusinessEmail(businessEmail) && getBusinessEmail(businessEmail)?.length) {
        return getBusinessEmail(businessEmail)
    }
    return [userEmail]
}

export const getBusinessEmail = (businessEmail) => {
    if (businessEmail) {
        if (typeof businessEmail === 'string') {
            return [businessEmail]
        }
        return businessEmail
    }
    return null
}

export const stringToArray = (emailString) => {
    const array = emailString.split(",")
    let updatedArray=[]
    for (let eachMail of array){
        updatedArray.push(eachMail?.trim())
    }
    return updatedArray
}

export const arrayToString = (emailArray) => {
    let array = emailArray.toString()
    return array
}