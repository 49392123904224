import { useSelector } from 'react-redux'
import Total from '../../library/stories/Cards/Total'
import { Chart } from '../../library/stories/Charts/Chart'

const DashboardOverview = () => {
  const { currency } = useSelector((state: any) => state.customer)
  const { overviewData, overviewDataLoading } = useSelector(
    (state: any) => state.contractor
  );

  const { ordersData, quotesData, searchesData } = overviewData;

  return (
    <>
      <div className="md:flex md:justify-start space-y-3 md:space-y-0 md:space-x-7 py-[26px]">
        <Total
          data={{
            loading: overviewDataLoading,
            amount: currency + ordersData.sum?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            label: "SALES",
          }}
        />
        <Total
          data={{
            loading: overviewDataLoading,
            amount: quotesData.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            label: "QUOTES",
          }}
        />
        <Total
          data={{
            loading: overviewDataLoading,
            amount: searchesData.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            label: "SEARCHES",
          }}
        />
      </div>
      <section className='hidden lg:grid lg:grid-cols-2 lg:gap-4' style={{ width: '100%' }}>
        <div className={`col-span-1 ${overviewDataLoading ? 'bg-gray-200 animate-pulse h-72 rounded-lg' : ''}`}>
          {!overviewDataLoading && <Chart
            label="Sales"
            total={ordersData.data.length}
            datakey="amount"
            totalChartData={ordersData.data}
          />}
        </div>
        <div className={`col-span-1 ${overviewDataLoading ? 'bg-gray-200 animate-pulse h-72 rounded-lg' : ''}`}>
          {!overviewDataLoading && <Chart
            label="Quotes"
            total={quotesData.count}
            datakey="total"
            totalChartData={quotesData.data}
          />}
        </div>
      </section>
    </>
  )
}

export default DashboardOverview