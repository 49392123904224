export const GET_MEASUREMENT = {
	METHOD: 'GET',
	PATH: '/measurement/'
}

export const GET_ORIGINAL_MEASUREMENT = {
	METHOD: 'GET',
	PATH: '/measurement/originalMeasurement'
}

export const CREATE_MEASUREMENT = {
	METHOD: 'POST',
	PATH: '/measurement'
}

export const UPDATE_MEASUREMENT = {
	METHOD: 'PATCH',
	PATH: '/measurement/'
}

export const GET_MEASUREMENT_STATUS = {
	METHOD: 'GET',
	PATH: '/measurement/status'
}

export const GET_MEASUREMENT_TAX = {
	METHOD: 'GET',
	PATH: '/tax-manager'
}

export const GET_PRECISELY_PROPERTY_INFO = {
	METHOD: 'POST',
	PATH: '/property-info/'
}

// export const GET_NEAR_MAP_HD_IMAGE = {
// 	METHOD: 'GET',
// 	PATH: '/measurement/pictometryImage/'
// }

export const GET_NEAR_MAP_HD_IMAGE = {
	METHOD: 'POST',
	PATH: '/measurement/pictometryImage/locationString'
}