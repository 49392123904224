import React, { useEffect } from 'react'
import DashboardOverview from './dashboard-overview.component'
import DashboardTable from './dashboard-table.component'
import { Filter } from '../../library/stories/Buttons/Filter'
import { useDispatch, useSelector } from 'react-redux'
import { contractorTypes } from '../../redux/contractor/contractor.types'
import { getOrdersOverview, resetContractorOrders } from '../../redux/contractor/contractor.actions'
import AllTeamMembersFilter from '../common/AllTeamMembersFilter'
import { isInRoles } from '../../utils/isInRoles'
import { rolesConstants } from '../../constants/roles.constants'


const ContractorDashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.user)
  const { overviewFilter, overviewData } = useSelector(
    (state: any) => state.contractor
  );
  const { selectedSalesMan } = useSelector(
    (state: any) => state.customer
  );

  const { roles } = user || []
  const isManager = isInRoles(roles, rolesConstants.MANAGER)
  useEffect(() => {
    dispatch(resetContractorOrders())
  }, [])
  useEffect(() => {
    if(overviewFilter){
      dispatch(getOrdersOverview(overviewFilter));
    }
  }, [overviewFilter, selectedSalesMan])

  const FILTER_OPTIONS = [
    { name: 'Today', onClick: () => dispatch({ type: contractorTypes.SET_OVERVIEW_FILTER, payload: 'Today' }) },
    { name: 'Yesterday', onClick: () => dispatch({ type: contractorTypes.SET_OVERVIEW_FILTER, payload: 'Yesterday' }) },
    { name: 'Last 7 Days', onClick: () => dispatch({ type: contractorTypes.SET_OVERVIEW_FILTER, payload: 'Last 7 Days' }) },
    { name: 'Last 30 Days', onClick: () => dispatch({ type: contractorTypes.SET_OVERVIEW_FILTER, payload: 'Last 30 Days' }) },
    { name: 'This Month', onClick: () => dispatch({ type: contractorTypes.SET_OVERVIEW_FILTER, payload: 'This Month' }) },
    { name: 'Last Month', onClick: () => dispatch({ type: contractorTypes.SET_OVERVIEW_FILTER, payload: 'Last Month' }) },
    { name: 'Lifetime', onClick: () => dispatch({ type: contractorTypes.SET_OVERVIEW_FILTER, payload: 'Lifetime' }) },
  ];

  return (
    <>
      <div className="md:flex md:justify-between items-center">
        <h1 className="text-[24px] font-[700]">Dashboard overview</h1>
        <div className="md:flex md:justify-center md:items-center md:space-x-5 mt-3 md:mt-0 space-y-3 md:space-y-3">
          {isManager && <div className='mr-3 w-full'>
            <AllTeamMembersFilter />
          </div>}
          <Filter active={`${overviewFilter}`} label={`${overviewFilter}`} options={FILTER_OPTIONS} />
        </div>
      </div>
      { overviewData && <DashboardOverview />
      }
      <DashboardTable />
    </>
  )
}

export default ContractorDashboard