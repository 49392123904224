export const formatPhone = (phone: string) => {
  if (!phone || !phone?.length) {
    return "-"
  }
  else if (phone[0] !== '1' && phone[0] !== '+') {
    return phone.substring(0, 3) + '-' + phone.substring(3, 6) + '-' + phone.substring(6, phone.length)
  }

  else {
    const number = phone.slice(-10)
    return "+1 " + number.substring(0, 3) + '-' + number.substring(3, 6) + '-' + number.substring(6, number.length)
  }
}