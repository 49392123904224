import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TeamMemberDetails from '../../components/TeamMemberDetails';
import LoadingAnimation from '../../components/loading/loadingAnimation.component';
import HomeStepper from '../../components/stepper/stepper';
import ErrorAlert from '../../components/tailwind/alerts/error-alert.component';
import VideoModal from '../../components/video-modal/video-modal';
import { DashboardLayout } from '../../layouts/dashboard/dashboard.layout';
import { getCustomer } from '../../redux/customer/customer.actions';
import { getAllIntegrations } from '../../redux/integrations/integrations.actions';
import { getTeamMember, getWholeTeam } from '../../redux/teamMember/teamMember.actions';
import { isTeamMember } from '../../utils/isTeamMember';
// import TeamMemberDetails from '../team-member-details';
import ContractorDashboard from '../../components/dashboard/dashboard.component';
import { rolesConstants } from '../../constants/roles.constants';
import { isInRoles } from '../../utils/isInRoles';
import styles from './dashboard.module.scss';
import { isPro } from '../../redux/widget/widget.selectors';
import { useRouter } from 'next/router';
import Head from 'next/head';

const Dashboard = () => {
  const [displayStepper, setDisplayStepper] = useState(true);
  const [teamMemberStepper, setTeamMemberStepper] = useState(false)
  const [pro, setPro] = useState(false)
  const dispatch = useDispatch();
  const router = useRouter()
  const { customer, loading: customerLoading } = useSelector((state: any) => state.customer);
  const customerError = useSelector((state: any) => state.customer.error);
  const { user } = useSelector((state: any) => state.user)
  const { member } = useSelector((state: any) => state.teamMember)

  const { roles } = user || []
  const isManager = isInRoles(roles, rolesConstants.MANAGER)

  useEffect(() => {
    dispatch(getCustomer());
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(getTeamMember(user._id))
      if (isManager) dispatch(getWholeTeam(user.owner_profile_id))
    }
  }, [user])

  useEffect(() => {
    if (customer) {
      if (isTeamMember(user)) {
        const { teamMemberSteps } = member
        if (teamMemberSteps?.namePic === false) {
          setTeamMemberStepper(true)
        }
      } else {
        const { stepsCompleted } = customer;
        if ((stepsCompleted?.addedWidget || stepsCompleted?.skipAddWidget)
          && stepsCompleted.connectedStripe
          && stepsCompleted.addedService
          && stepsCompleted.addedServiceArea
          && stepsCompleted.completedProfile && (stepsCompleted.enabledCommercialMeasurement || stepsCompleted.enabledCommercialMeasurement === undefined) && (stepsCompleted.onBoardingScreen || stepsCompleted.onBoardingScreen === undefined)) { setDisplayStepper(false); }
      }
      dispatch(getAllIntegrations(customer?._id))
      }
  }, [customer]);

  useEffect(() => {
    if (router) {
      setPro(isPro(window))
    }
  }, [router])

  if (pro) {
    router.push('/contractor/pro-measurements')
    return <></>
  }

  if (!customer || customerLoading) return <LoadingAnimation fullScreen={true}/>

  if (customerError && customerError.length) {
    return (
      <div className={styles.error}>
        <ErrorAlert errorMessages={[customerError]} />
      </div>
    );
  }

  function renderHome() {
    if (customer?.settings) {
      if (customer.settings['managerSelectOptions'] && isManager) {
        if (customer.settings['managerSelectOptions']['salesmenSelected'] && customer.settings['managerSelectOptions']['dateRangeSelected']) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }

  return (
    <>
    <Head><script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script></Head>
      {
        isTeamMember(user) ? (teamMemberStepper ? <TeamMemberDetails setTeamMemberStepper={setTeamMemberStepper} /> 
        : 
          renderHome() ? <DashboardLayout>
            <div className='p-5'>
            <ContractorDashboard />
            </div>
          </DashboardLayout> : <LoadingAnimation fullScreen={true} />
          )
          :
          <DashboardLayout>
            <VideoModal />
            {
              customerLoading ? <LoadingAnimation /> : (
                <div className="px-8 pb-8 pt-[34px]">
                  {displayStepper ? <HomeStepper /> : <ContractorDashboard />}
                </div>
              )}
          </DashboardLayout>}
    </>
  );
};

export default Dashboard;