import { SERVICE_AREA_TYPES } from '../constants/services.constants';
import { calculateDiscount } from './calculateDiscount';
import { getServicePrice } from './getServicePrice';
import getServicePriceWithDiscount from './getServicePriceWithDiscount';
import { getServiceSqFeet } from './getServiceSqFeet';
import { roundToTwoDigits } from './roundToTwoDigits';

export const applyServicesDiscountsOnPackage = (packageItem, sqFeet, boundary, customQuotes, cartPaymentType, buildingSquareFeet, polygonsFeaturesSqFeet): {discountedPrice: number, discounts: Array<Object>} => {
  
  let appliedDiscounts = []
  let packagePrice = 0;

  if(customQuotes?.packages && customQuotes?.packages[packageItem._id]){
    return {discountedPrice: customQuotes.packages[packageItem._id], discounts: [] }
  }

  for(const service of packageItem.services) {
    delete service.discount
    const currentServiceInServicesDiscounts = packageItem.servicesDiscounts.find(discount => discount.service === service._id && discount.discounts?.length !== 0 )
    if(currentServiceInServicesDiscounts){
      service.discount = currentServiceInServicesDiscounts.discounts
    }
    
    let squareFeet = getServiceSqFeet(service.areaType, sqFeet, (boundary.acreage_calc)*43560, buildingSquareFeet, polygonsFeaturesSqFeet);
    
    let servicePrice = getServicePrice(
      service.pricePerSquare,
      packageItem.quantity[service._id],
      service.minPrice,
      service.fixedPrice,
      squareFeet,
      service.ranges,
      service.areaType
    );
    
    if(servicePrice === -1){
      if(customQuotes?.packages[packageItem._id]){
        return {discountedPrice: customQuotes.packages[packageItem._id], discounts: appliedDiscounts }
      } else if (customQuotes?.servicesInPackage && customQuotes?.servicesInPackage[packageItem._id]) {
        servicePrice = customQuotes?.servicesInPackage[packageItem._id][service._id]
      } else {
        return {discountedPrice: 0, discounts: appliedDiscounts }
      }
    }
    
    if (service.discount?.length) {
      const {discountedTotal, discounts} = calculateDiscount(service.discount, servicePrice, cartPaymentType, packageItem.quantity[service._id])
      if(discounts.length) {
        appliedDiscounts.push(...discounts)
      }
      servicePrice = discountedTotal
    }
    packagePrice += servicePrice
  };
  return {discountedPrice: roundToTwoDigits(packagePrice), discounts: appliedDiscounts };
};
