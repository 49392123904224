import axios from 'axios';
import { store } from '../redux/store';

export default async function sendRequest(method: any, url: any, data?: any) {
  const token = `Bearer ${store.getState().user.token}`;
  const baseUrl = process.env.NEXT_PUBLIC_API_URL;

  try {
    return await axios({
      method,
      url: `${baseUrl}${url}`,
      data,
      headers: {
        Authorization: token,
      },
    });
  } catch (err: any) {
    const errRes = err.response;

    return errRes;
  }
}
