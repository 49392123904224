import { PolygonTypeConsts } from "../constants/polygonType.constants"

export const totalSnowSqFeet = (polygonsFeaturesSqFeet: any = {}) => {
  let snow_sqFeet = 0;
  const processedFeatures = new Set();

  PolygonTypeConsts.SNOW.polygonFeatures.forEach(feature => {
    if (!processedFeatures.has(feature)) {
      const feature_sqFeet = polygonsFeaturesSqFeet[feature] || 0;
      snow_sqFeet += feature_sqFeet;
      processedFeatures.add(feature);
    }
  });

  return snow_sqFeet.toString();
};
