import { routeConstants } from '../../routes';
import sendRequest from '../../utils/useAxios';
import { updateCustomerStep } from '../customer/customer.actions';
import {
  CREATE_SERVICE_AREA,
  GET_SERVICE_AREA,
  REMOVE_SERVICE_AREAS,
  UPDATE_COUNT_ZIPCODE,
  UPDATE_SERVICE_AREA,
  UPDATE_SPEICIFC_REGION_COUNT,
} from './service-area.api';
import { serviceAreaTypes } from './service-area.types';

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: serviceAreaTypes.SET_SERVICE_AREA_LOADING,
    payload: loading,
  });
};

export const getContractorServiceArea = () => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(GET_SERVICE_AREA.METHOD, `${GET_SERVICE_AREA.PATH}`);
  if (data && !data.statusCode) {
    dispatch(setServiceArea(data));
    dispatch(setLoading(false));
  } else {
    dispatch(setLoading(false));
  }
};

export const getServiceArea = (userId) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(GET_SERVICE_AREA.METHOD, `${GET_SERVICE_AREA.PATH}/${userId}`);
  if (data && !data.statusCode) {
    dispatch(setServiceArea(data));
  }
  dispatch(setLoading(false));
};
export const createServiceArea = (serviceArea: any, router?: any) => async (dispatch) => {
  const { data } = await sendRequest(
    CREATE_SERVICE_AREA.METHOD,
    CREATE_SERVICE_AREA.PATH,
    serviceArea
  );
  if (!data.statusCode) {
    dispatch(setServiceArea(data));
    if (serviceArea.enableAdvancedServiceArea) {
      dispatch(updateServiceAreaAddressCount(data));
    }
    dispatch(updateCustomerStep('addedServiceArea', router, routeConstants.DASHBOARD.pathname));
  }
};

export const updateServiceArea = (serviceArea: any, updatedRegion?: any, isDeleted?: boolean,) => async (dispatch) => {
  const { data } = await sendRequest(
    UPDATE_SERVICE_AREA.METHOD,
    `${UPDATE_SERVICE_AREA.PATH}`,
    serviceArea
  );
  if (data && !data.statusCode) {
    dispatch(setServiceArea(data));
    if (serviceArea.enableAdvancedServiceArea && !isDeleted) {
      const lastRegion = serviceArea?.polygon[serviceArea?.polygon?.length - 1];
      // Check if new region is added
      if (!lastRegion?._id && !updatedRegion) {
        const lastRegionWithID = data?.polygon[data?.polygon?.length - 1];
        dispatch(updateServiceAreaAddressCount(data, lastRegionWithID._id));
        dispatch(setRegionCountLoading(lastRegionWithID._id));
      } else {
        dispatch(updateServiceAreaAddressCount(data, updatedRegion));
        dispatch(setRegionCountLoading(updatedRegion));
      }
    }
    dispatch(setLoading(false));
  }
};

export const updateZipcodeServiceArea = (serviceArea: any, updatedRegion) => async (dispatch) => {
  // dispatch(setLoading(true));
  dispatch(setRegionCountLoading(updatedRegion));
  const { data } = await sendRequest(UPDATE_COUNT_ZIPCODE.METHOD, UPDATE_COUNT_ZIPCODE.PATH, {
    serviceArea,
    updatedRegion,
  });
  dispatch(setServiceArea(data));
  dispatch(setRegionCountLoading(''));
  dispatch(setLoading(false));
};

export const updateServiceAreaAddressCount =
  (serviceArea: any, updatedRegion?: any) => async (dispatch) => {
    const { data } = await sendRequest(
      UPDATE_SERVICE_AREA.METHOD,
      `${UPDATE_SERVICE_AREA.PATH}/updateAdressCount`,
      { serviceArea, updatedRegion }
    );
    if (data && !data.statusCode) {
      dispatch(setServiceArea(data));
      dispatch(setLoading(false));
      dispatch(setRegionCountLoading(''));
    }
  };

export const setServiceArea = (serviceArea) => async (dispatch) => {
  dispatch({
    type: serviceAreaTypes.SET_SERVICE_AREA,
    payload: serviceArea,
  });
};

export const setRegionCountLoading = (id) => async (dispatch) => {
  dispatch({
    type: serviceAreaTypes.SET_REGION_COUNT_LOADING,
    payload: id,
  });
};

export const checkPackages = async (polygonObj: any) => {
  return await sendRequest(
    'GET',
    `/packages?regions=${[polygonObj?._id]}`
  );
}

export const checkServices = async (polygonObj: any) => {
  return await sendRequest(
    'GET',
    `/services?regions=${[polygonObj?._id]}`
  );
}

export const checkDiscounts = async (polygonObj: any) => {
  return await sendRequest(
    'GET',
    `/discounts?regions=${[polygonObj?._id]}`
  );
}

export const getZipcodeData = async (zipcode: any) => {
  return await sendRequest(
    'GET',
    `/zipcode/${zipcode}`
  );
}

export const getTravelArea = async (travelAddress: any, travelTime: any, travelTimeUnit: any) => {
  return await sendRequest('GET', `/travelzone/address=${travelAddress}&country=USA&costs=${travelTime}&costUnit=${travelTimeUnit}&db=driving&defaultAmbientSpeed=15&ambientSpeedUnit=MPH&maxOffroadDistance=1&maxOffroadDistanceUnit=mi&destinationSrs=epsg%3A4326&majorRoads=true&returnHoles=false&returnIslands=false&simplificationFactor=0.5&bandingStyle=Donut&historicTrafficTimeBucket=None`)
}


export const setIsOutsideServiceArea = (isOutsideServiceArea: boolean) => async (dispatch) => {
  dispatch({
    type: serviceAreaTypes.SET_IS_INSIDE_SERVICE_AREA,
    payload: isOutsideServiceArea,
  });
};

export const setIsClearEnabled = (isClearEnabled: boolean) => async (dispatch) => {
  dispatch({
    type: serviceAreaTypes.SET_IS_CLEAR_ENABLED,
    payload: isClearEnabled,
  });
};

export const removeServiceAreas = (payload: any) => async (dispatch) => {
  const { data } = await sendRequest(
    REMOVE_SERVICE_AREAS.METHOD,
    `${REMOVE_SERVICE_AREAS.PATH}`,
    payload
  );
  if (data && !data.statusCode) {
    dispatch(setServiceArea(data));
    dispatch(setLoading(false));
    dispatch(setRegionCountLoading(''));
  }
};