import { SERVICE_AREA_TYPES } from "../constants/services.constants";
import { calculateDiscount } from "./calculateDiscount";
import { getServicePrice } from "./getServicePrice";
import { getServiceQuantity } from "./getServiceQuantity";
import { getServiceSqFeet } from "./getServiceSqFeet";
import { roundToTwoDigits } from "./roundToTwoDigits";

const getServicePriceWithDiscount = (service, quantity, sqFeet, cartPaymentType, boundary, customQuotes, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  const serviceQuantity = typeof quantity === 'object' ? getServiceQuantity(quantity, service._id) : quantity;
  let squareFeet = getServiceSqFeet(service.areaType, sqFeet, (boundary?.acreage_calc || 0) * 43560, buildingSquareFeet, polygonsFeaturesSqFeet);
  
  let servicePrice = getServicePrice(
    service.pricePerSquare,
    serviceQuantity,
    service.minPrice,
    service.fixedPrice,
    squareFeet,
    service.ranges,
    service.areaType
  );
  
  if(servicePrice === -1){
    if(customQuotes?.services[service._id]){
      servicePrice = customQuotes?.services[service._id] * serviceQuantity
    } else {
      servicePrice = 0
    } 
  }

  if(customQuotes?.services[service?._id]){
    servicePrice = customQuotes?.services[service._id] * serviceQuantity
  }

  if (service.discount?.length) {
    const {discountedTotal, discounts} = calculateDiscount(service.discount, servicePrice, cartPaymentType, serviceQuantity)
    if(discountedTotal != undefined && discountedTotal != null) {
      servicePrice = discountedTotal
    }
    return {discountedPrice: roundToTwoDigits(servicePrice).toFixed(2), discounts};
  }

  return {discountedPrice: roundToTwoDigits(servicePrice).toFixed(2), discounts: []}
};

export default getServicePriceWithDiscount