import { SERVICE_AREA_TYPES } from '../../constants/services.constants';
import { calculateDiscount } from '../../utils/calculateDiscount';
import { getCustomQuoteServicePrice } from '../../utils/getCustomQuoteServicePrice';
import { getDiscountedPackagePrice } from '../../utils/getDiscountedPackagePrice';
import { getDiscountedServicePrice } from '../../utils/getDiscountedServicePrice';
import { getPackagePrice } from '../../utils/getPackagePrice';
import { getServicePrice } from '../../utils/getServicePrice';
import { getServiceQuantity } from '../../utils/getServiceQuantity';
import { roundToTwoDigits } from '../../utils/roundToTwoDigits';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import relativeTime from "dayjs/plugin/relativeTime"
import { getServiceSqFeet } from '../../utils/getServiceSqFeet';
import sendRequest from '../../utils/useAxios';
import { ApplyPackageCustomDiscounts, ApplyServiceCustomDiscounts } from '../../utils/applyCustomDiscounts';
import getProTurfPackagePriceWithDiscount_PayGo from '../../utils/getProTurfPackagePriceWithDiscount-payGo';
import getProTurfPackagePriceWithDiscount_ezPay from '../../utils/getProTurfPackagePriceWithDiscount-ezPay';
import { PRO_WEBSITE, ROBIN_WEBSITE } from '../../constants/app.constants'
import { routeConstants } from '../../routes';
import { orderTypeConst } from '../../constants/orderType.constants';
import { isMonthlyCustomized, paymentOption_month } from '../../utils/app-utils';

const getTaxFreeItems = (customer) => {
  return customer?.settings?.taxFreeItems
}

export const isActive = (_id, type) => (state) => {
  const filteredList = state.widget.cart[type].filter(
    (service) => service._id == _id
  );
  return !!filteredList.length;
};

export const isProTrufProPestContractor = (customer) => {
  return process.env.NEXT_PUBLIC_PRO_TURF_PRO_PEST_CONTRACTOR_ID === customer?.user
};

export const isVirginiaGreenCustomer = (customer) => {
  return process.env.NEXT_PUBLIC_VIRGINIA_GREEN_CUSTOMER_ID === customer?._id
};

export const isRainMasterAndLawnBuddiesContractor = (customer) => {
  return JSON.parse(process.env.NEXT_PUBLIC_RAIN_MASTER_LAWN_BUDDIES_CUSTOMER_ID).includes((customer?._id).toString())
};

export const isRobin = (user) => {
  return user?.roles?.includes('ROBIN')
}

export const isPro = window => window.location.origin.includes(PRO_WEBSITE)
export const isRobinSite = window => window.location.origin.includes(ROBIN_WEBSITE)

export const getTotalOrderDiscountAmount = (customPaymentType?) => (state) => {
  const {orderDiscounts, paymentType:widgetPaymentType } = state.widget
  if(!orderDiscounts){
    return null
  }
  const subTotal = getNormalTotalPriceWithoutTax()(state)
  const paymentType=customPaymentType || widgetPaymentType
  const {discountedTotal} = calculateDiscount(orderDiscounts, subTotal, paymentType)
  return roundToTwoDigits(parseFloat(subTotal) - discountedTotal).toFixed(2)
}

export const getNormalTotalPrice = (customPaymentType?) => (state) => {
  const paymentType = customPaymentType || state.widget.paymentType
  if (paymentType === 'EZ_PAY') {
    return getProTurfTotalPriceEzPay()(state)
  } else if (paymentType === orderTypeConst.PAY_AS_YOU_GO && (isProTrufProPestContractor(state.customer.customer) || isDreamLawnContractor(state.customer.customer) || isVirginiaGreenCustomer(state.customer.customer))) {
    return getProTurfTotalPricePayGo()(state)
  } else if (paymentType === 'MONTHLY_PAYMENT') {
      return getTotalPrice_monthly()(state)
  } else return getTotalPrice(customPaymentType)(state)
}

export const getProTurfTotalPriceEzPay = () => (state) => {
  let totalPrice = 0;
  const { tax } = state.measurement
  let subTotal = getProTurfTotalPriceWithoutTaxEzPay()(state)
  let totalTax = getProTurfEstimatedTaxEzPay()(state)

  totalPrice = Number(subTotal) + Number(totalTax)
  const orderDiscountAmount = parseFloat(getTotalOrderDiscountAmount()(state))
  if(orderDiscountAmount > 0){
    totalPrice -= orderDiscountAmount
  }
  return roundToTwoDigits(totalPrice).toFixed(2)
};

export const getProTurfTotalPricePayGo = () => (state) => {
  let totalPrice = 0;
  const { tax } = state.measurement
  let subTotal = getProTurfTotalPriceWithoutTaxPayGo()(state)
  let totlaTax = getProTurfEstimatedTaxPayGo()(state)

  totalPrice = Number(subTotal) + Number(totlaTax)
  const orderDiscountAmount = parseFloat(getTotalOrderDiscountAmount()(state))
  if(orderDiscountAmount > 0){
    totalPrice -= orderDiscountAmount
  }
  return roundToTwoDigits(totalPrice).toFixed(2)
};

export const getProTurfTotalPricePayNow = () => (state) => {
  let totalPrice = 0;
  const { tax } = state.measurement
  let subTotal = getTotalPriceWithoutTax()(state)
  let totlaTax = getProTurfEstimatedTaxPayNow()(state)

  totalPrice = Number(subTotal) + Number(totlaTax)
  const orderDiscountAmount = parseFloat(getTotalOrderDiscountAmount()(state))
  if(orderDiscountAmount > 0){
    totalPrice -= orderDiscountAmount
  }
  return roundToTwoDigits(totalPrice).toFixed(2)
};

export const getTotalPrice_monthly = () => (state) => {
  const { tax } = state.measurement;
  const { paymentType } = state.widget;

  let totalPrice = Number(getTotalPriceWithoutTax_monthly()(state))
  totalPrice = totalPrice + (totalPrice * tax) / 100;
  const orderDiscountAmount = parseFloat(getTotalOrderDiscountAmount(paymentType)(state))
  if(orderDiscountAmount > 0){
    totalPrice -= Number(orderDiscountAmount)
  }
  return roundToTwoDigits(totalPrice).toFixed(2)
};

export const getTotalPrice = (customPaymentType?) => (state) => {
  let totalPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { boundary, quantity, customQuotes, customDiscounts } = state.widget;
  const paymentType = customPaymentType || state.widget.paymentType
  const servicesArray = getTaxFreeItems(state.customer.customer)

  if(servicesArray && servicesArray.length > 0){
    const subTotal = Number(getNormalTotalPriceWithoutTax()(state))
    const estimatedTax = Number(getNormalEstimatedTax()(state))
    totalPrice = subTotal + estimatedTax
  } else {
  state.widget.cart.services.map((service) => {
    const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, boundary, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    totalPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
  });

  state.widget.cart.packages.map((packageItem) => {
    const packagePrice = parseFloat(getDiscountedPackagePrice(packageItem, sqFeet, boundary, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    totalPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, packagePrice, paymentType))
  });

  totalPrice = totalPrice + (totalPrice * tax) / 100;
  }
  const orderDiscountAmount = parseFloat(getTotalOrderDiscountAmount(customPaymentType)(state))
  if(orderDiscountAmount > 0){
    totalPrice -= orderDiscountAmount
  }
  return roundToTwoDigits(totalPrice).toFixed(2)
};

export const getSubTotal = () => (state) => {
  let totalPrice = 0;
  const { sqFeet, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { quantity, customQuotes } = state.widget;
  const {boundary} = state.widget
  const buildingSquareFeet = measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0

  state.widget.cart.services.map((service) => {
    let squareFeet = getServiceSqFeet(service.areaType, sqFeet, (boundary.acreage_calc)*43560, buildingSquareFeet, polygonsFeaturesSqFeet);

    let servicePrice = getServicePrice(
      service.pricePerSquare,
      getServiceQuantity(quantity, service._id),
      service.minPrice,
      service.fixedPrice,
      squareFeet,
      service.ranges,
      service.areaType
    );
    servicePrice = getCustomQuoteServicePrice(servicePrice, customQuotes, service._id, quantity)
    totalPrice += servicePrice
  });

  state.widget.cart.packages.map((packageItem) => {
    totalPrice += getPackagePrice(packageItem, sqFeet, boundary?.acreage_calc*43560, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet);
  });
  return roundToTwoDigits(totalPrice).toFixed(2)
};

export const getNormalTotalPriceWithoutTax = () => (state) => {
  const paymentType = state.widget.paymentType
  if (paymentType === 'EZ_PAY') {
    return getProTurfTotalPriceWithoutTaxEzPay()(state)
  } else if (paymentType === orderTypeConst.PAY_AS_YOU_GO && (isProTrufProPestContractor(state.customer.customer) || isDreamLawnContractor(state.customer.customer) || isVirginiaGreenCustomer(state.customer.customer))) {
    return getProTurfTotalPriceWithoutTaxPayGo()(state)
  } else if (paymentType === 'MONTHLY_PAYMENT') {
    return getTotalPriceWithoutTax_monthly()(state)
  } else return getTotalPriceWithoutTax()(state)
}

export const getProTurfTotalPriceWithoutTaxEzPay = () => (state) => {
  let totalPrice = 0;
  totalPrice = Number(getTotalPriceWithoutTax()(state))
  return roundToTwoDigits(totalPrice/12).toFixed(2)
};

export const getProTurfTotalPriceWithoutTaxPayGo = () => (state) => {
  let totalPrice = 0;
  const { sqFeet, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { boundary, quantity, paymentType, customQuotes, customDiscounts } = state.widget;

  state.widget.cart.services.map((service) => {
    const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, boundary, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    totalPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
  });

  state.widget.cart.packages.map((packageItem) => {
    const { discountedPrice } = getProTurfPackagePriceWithDiscount_PayGo(packageItem, sqFeet, boundary, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet)
    const packagePrice = parseFloat(discountedPrice)
    totalPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, packagePrice, paymentType))
  });

  return roundToTwoDigits(totalPrice).toFixed(2)
};

export const getTotalPriceWithoutTax_monthly = () => (state) => {

  let totalPrice = getTotalPriceWithoutTax()(state)
  totalPrice = paymentOption_month(totalPrice, state.customer.customer?.branding?.paymentButtons.find(singlePayment => singlePayment.const == state.widget.paymentType), isMonthlyCustomized(state.customer?.customer?.branding?.isRollOverEnabled))
  
  return totalPrice
};

export const getTotalPriceWithoutTax = () => (state) => {
  let totalPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { boundary, quantity, paymentType, customQuotes, customDiscounts } = state.widget;

  state.widget.cart.services.map((service) => {
    const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity,sqFeet, paymentType,boundary, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    totalPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
  });

  state.widget.cart.packages.map((packageItem) => {
    const packagePrice = parseFloat(getDiscountedPackagePrice(packageItem, sqFeet, boundary, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    totalPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, packagePrice, paymentType))
  });

  totalPrice;
  
  if ((paymentType === 'MONTHLY_PAYMENT') && isMonthlyCustomized(state.customer?.branding?.isRollOverEnabled)) {
    console.log("This ran")
    totalPrice = parseFloat(paymentOption_month(totalPrice, state.customer.customer?.branding?.paymentButtons.find(singlePayment => singlePayment.const == state.widget.paymentType), isMonthlyCustomized(state.customer?.branding?.isRollOverEnabled)));
  }
  
  
  
  return roundToTwoDigits(totalPrice).toFixed(2)
};


export const getNormalEstimatedTax = () => (state) => {
  const paymentType = state.widget.paymentType
  if (paymentType === 'EZ_PAY') {
    return getProTurfEstimatedTaxEzPay()(state)
  } else if (paymentType === orderTypeConst.PAY_AS_YOU_GO && (isProTrufProPestContractor(state.customer.customer) || isDreamLawnContractor(state.customer.customer) || isVirginiaGreenCustomer(state.customer.customer))) {
    return getProTurfEstimatedTaxPayGo()(state)
  } else if (paymentType === 'MONTHLY_PAYMENT') {
    return getEstimatedTax_monthly()(state)
  } else return getEstimatedTax()(state)
}

export const getProTurfEstimatedTaxEzPay = () => (state) => {
  let taxPrice = 0;
  let totalTaxPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { quantity, customQuotes } = state.widget;
  const { paymentType, customDiscounts } = state.widget;
  const { boundary } = state.widget
  const servicesArray = getTaxFreeItems(state.customer.customer)
  const addressState = filterAddress(measurement.address)

  state.widget.cart.services.map((service) => {
    if (addressState === 'NE' || addressState === 'MO') {
      if (!servicesArray?.includes(service._id)) {
        const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, boundary, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
        taxPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
      }
    } else {
      const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, boundary, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
      taxPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
    }
  });

  if (addressState === 'NE' || addressState === 'MO') {
    const filteredPackage = filteredPackages(state.widget.cart.packages, servicesArray)
    filteredPackage.map((packageItem) => {
      if (packageItem.services && packageItem.services.length > 0) {
        const { discountedPrice: packagePrice } = getProTurfPackagePriceWithDiscount_ezPay(packageItem, sqFeet, boundary, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet)
        taxPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, packagePrice, paymentType))
      }
    });
  } else {
    state.widget.cart.packages.map((packageItem) => {
      const { discountedPrice: packagePrice } = getProTurfPackagePriceWithDiscount_ezPay(packageItem, sqFeet, boundary, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet)
      taxPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, packagePrice, paymentType))
    });
  }

  totalTaxPrice = (Number(taxPrice/12) * tax) / 100;
  return totalTaxPrice.toFixed(2)
}

export const getProTurfEstimatedTaxPayNow = () => (state) => {
  let taxPrice = 0;
  let totalTaxPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { quantity, customQuotes } = state.widget;
  const { paymentType, customDiscounts } = state.widget;
  const { boundary } = state.widget
  const servicesArray = getTaxFreeItems(state.customer.customer)
  const addressState = filterAddress(measurement.address)

  state.widget.cart.services.map((service) => {
    if (addressState === 'NE' || addressState === 'MO') {
      if (!servicesArray?.includes(service._id)) {
        const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, boundary, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
        taxPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
      }
    } else {
      const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, boundary, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
      taxPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
    }
  });
  

  if (addressState === 'NE' || addressState === 'MO') {
    const filteredPackage = filteredPackages(state.widget.cart.packages, servicesArray)
    filteredPackage.map((packageItem) => {
      if (packageItem.services && packageItem.services.length > 0) {
        const discountedPrice = parseFloat(getDiscountedPackagePrice(packageItem, sqFeet, boundary, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
        taxPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, discountedPrice, paymentType))
      }
    });
  } else {
    state.widget.cart.packages.map((packageItem) => {
      const discountedPrice = parseFloat(getDiscountedPackagePrice(packageItem, sqFeet, boundary, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
      taxPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, discountedPrice, paymentType))
    });
  }

  totalTaxPrice = (Number(taxPrice) * tax) / 100;
  return totalTaxPrice.toFixed(2)
}

export const getProTurfEstimatedTaxPayGo = () => (state) => {
  let taxPrice = 0;
  let totalTaxPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { quantity, customQuotes } = state.widget;
  const { paymentType, customDiscounts } = state.widget;
  const { boundary } = state.widget
  const servicesArray = getTaxFreeItems(state.customer.customer)
  const addressState = filterAddress(measurement?.address)

  state.widget.cart.services.map((service) => {
    if (addressState === 'NE' || addressState === 'MO') {
      if (!servicesArray?.includes(service._id)) {
        const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, boundary, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
        taxPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
      }
    } else {
      const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, boundary, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
      taxPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
    }
  });

  if (addressState === 'NE' || addressState === 'MO') {
    const filteredPackage = filteredFirstServicePackages(state.widget.cart.packages, servicesArray)
    filteredPackage.map((packageItem) => {
      if (packageItem.services && packageItem.services.length > 0) {
        const { discountedPrice } = getProTurfPackagePriceWithDiscount_PayGo(packageItem, sqFeet, boundary, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet)
        const packagePrice = parseFloat(discountedPrice)
        taxPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, packagePrice, paymentType))
      }
    });
  } else {
    state.widget.cart.packages.map((packageItem) => {
      const { discountedPrice } = getProTurfPackagePriceWithDiscount_PayGo(packageItem, sqFeet, boundary, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet)
      const packagePrice = parseFloat(discountedPrice)
      taxPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, packagePrice, paymentType))
    });
  }
  // const totalPrice = getProTurfTotalPriceWithoutTaxPayGo()(state)
  totalTaxPrice = (Number(taxPrice) * tax) / 100;
  return totalTaxPrice.toFixed(2)
}

export const getEstimatedTax_monthly = () => (state) => {
  
  const { tax } = state.measurement;

  let totalPrice = Number(getTotalPriceWithoutTax_monthly()(state))
  const taxPrice = (totalPrice * tax) / 100;

  return roundToTwoDigits(taxPrice).toFixed(2)
}

export const getEstimatedTax = () => (state) => {
  let totalPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { quantity, customQuotes } = state.widget;
  const { paymentType, customDiscounts } = state.widget;
  const {boundary} = state.widget
  const servicesArray = getTaxFreeItems(state.customer.customer)

  state.widget.cart.services.map((service) => {
    // check to exclude tax free items
    if(!servicesArray?.includes(service._id)){
      const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, boundary, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
      totalPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
    }
    });

  const filteredPackage = filteredPackages(state.widget.cart.packages, servicesArray)
  filteredPackage.map((packageItem) => {
    const packagePrice = parseFloat(getDiscountedPackagePrice(packageItem, sqFeet, boundary, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    totalPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, packagePrice, paymentType))
  });
  totalPrice = (totalPrice * tax) / 100;
  
  return roundToTwoDigits(totalPrice).toFixed(2)
}


export const getCustomerRedirectURL = (customer,orderPaymentType) => {
  let redirectUrl = '/widget/thank-you'

  // check if customer has redirectUrl attached to payment Types
  if (customer?.branding?.paymentButtons?.length) {
    customer.branding.paymentButtons.forEach((btn) => {
      if (btn.redirectUrl && btn.const === orderPaymentType) {
        redirectUrl = btn.redirectUrl
      }
    })
  }
    
  return redirectUrl  
}

export const isOnOrderDetailsPage = (router): boolean => {
  return router.pathname === routeConstants.ORDER_DETAILS.pathname
}

export const isOnLeadDetailsPage = (router): boolean => {
  return router.pathname === routeConstants.LEAD_DETAILS.pathname
}

export const isOnEstimatePage = (router): boolean => {
  return router.pathname === routeConstants.SEARCH.pathname
}

export const
isOnQuotePage = (router): boolean => {
  return router.pathname === '/quote/[id]'
  // return (router.pathname === '/quote/[id]' || router.pathname === '/contractor/convert-to-order/[id]')
}

export const isOnWidgetPage = (router): boolean => {
  return router.pathname === '/widget/[id]'
}

export const isOnSearchPage = (router): boolean => {
  return router.pathname === routeConstants.SEARCH.pathname
}

export const calculateNormalTotalWithoutTax = (customPaymentType?) => (state) => {
  console.log("This ran")
  const paymentType = customPaymentType || state.widget.paymentType
  if (paymentType === 'EZ_PAY') {
    return calculateProTurfTotalWithoutTaxEzPay()(state)
  } else if (paymentType === orderTypeConst.PAY_AS_YOU_GO && (isProTrufProPestContractor(state.customer.customer) || isDreamLawnContractor(state.customer.customer) || isVirginiaGreenCustomer(state.customer.customer))) {
    return calculateProTurfTotalWithoutTaxPayGo()(state)
  } else if (paymentType === 'MONTHLY_PAYMENT') {
    console.log("This ran")
    return getTotalPriceWithoutTax_monthly()(state)
  } else return calculateTotalWithoutTax(customPaymentType)(state)
}

export const calculateProTurfTotalWithoutTaxEzPay = () => (state) => {
  const withoutTax = Number(calculateTotalWithoutTax()(state))
  return roundToTwoDigits(withoutTax/12).toFixed(2)
};
export const calculateProTurfTotalWithoutTaxPayGo = () => (state) => {
  const { tax } = state.measurement
  let subTotal = getProTurfTotalPriceWithoutTaxPayGo()(state)

  return roundToTwoDigits(subTotal).toFixed(2)
}
export const calculateTotalWithoutTax = (customPaymentType?) => (state) => {
  let totalPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { quantity, customQuotes, customDiscounts } = state.widget;
  const paymentType = customPaymentType || state.widget.paymentType
  const {boundary} = state.widget;

  console.log("This ran")

  state.widget.cart.services.map((service) => {
    const discountedServicePrice = parseFloat(getDiscountedServicePrice(service, quantity,sqFeet, paymentType,boundary, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    totalPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, discountedServicePrice, paymentType, quantity))
  });

  state.widget.cart.packages.map((packageItem) => {
    const discountedPackagePrice = parseFloat(getDiscountedPackagePrice(packageItem, sqFeet, boundary, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    totalPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, discountedPackagePrice, paymentType))
  });


  const orderDiscountAmount = parseFloat(getTotalOrderDiscountAmount()(state))
  if(orderDiscountAmount > 0){
    totalPrice -= orderDiscountAmount
  }

  return roundToTwoDigits(totalPrice).toFixed(2)
};

export const formatDate = (date) => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(relativeTime)
  const user_timeZone = dayjs.tz.guess()
  return `${dayjs(date).tz(user_timeZone).format('MMMM DD, YYYY [at] hh:mm A')}`
}

export const getSessionString = (session,key,index) =>{
  let str = ''
  if (key==='searched_address') {
    str = `Address searched ${index!==0 ? 'again' : ''} on ${formatDate(session[key])}`
  } else if (key==='viewed_services') {
    str = `viewed_services`
  } else if (key==='abandoned_cart') {
    str = `Cart abandoned on ${formatDate(session[key])}`
  }else if (key==='Order_placed') {
    str = `Order placed on ${formatDate(session[key])}`
  }
  return str
}

export const isAdvancedFlow = (router, settings) => {
  const enableServiceQuestions = settings?.widgetSearchFlowOptions?.enableServiceQuestions || false
  if (isOnWidgetPage(router) && enableServiceQuestions) {
    return true
  }
  return false
};

export const isEmeraldLawn = (settings) => {
  const enableServiceQuestions = settings?.widgetSearchFlowOptions?.enableServiceQuestions || false
  const enableCategories = settings?.widgetSearchFlowOptions?.enableCategories || false
  if (enableServiceQuestions && enableCategories) {
    return true
  }
  return false
};

export const isDreamLawnContractor = (customer: any) => (customer?._id === process.env.NEXT_PUBLIC_JEFFREY_DREAMLAWN_CUSTOMER_ID)

export const isSenskeContractor = (customer: any) => (customer?._id === process.env.NEXT_PUBLIC_SENSKE_CUSTOMER_ID)

export const isSunTeckContractor = (customer: any) => (customer?._id === process.env.NEXT_PUBLIC_SUNTECK_CUSTOMER_ID)

export const isTaterFarmsContractor = (customer: any) => (customer?._id === process.env.NEXT_PUBLIC_TATER_FARMS_CUSTOMER_ID)

export const isLawnBuddies = (customer) => {
  if (customer?._id === process.env.NEXT_PUBLIC_LAWN_BUDDIES_CUSTOMER_ID) {
    return true
  }
  return false
};

export const isSharpLawn = (settings) => {
  const enableServiceQuestions = settings?.widgetSearchFlowOptions?.enableServiceQuestions || false
  const enableCategories = settings?.widgetSearchFlowOptions?.enableCategories || false
  const singlePackageSelection = settings?.widgetSearchFlowOptions?.singlePackageSelection || false
  if (enableServiceQuestions && !enableCategories && singlePackageSelection) {
    return true
  }
  return false
};

export const isSharpLawnWithServices = (settings) => {
  const enableServiceQuestions = settings?.widgetSearchFlowOptions?.enableServiceQuestions || false
  const enableCategories = settings?.widgetSearchFlowOptions?.enableCategories || false
  const singlePackageSelection = settings?.widgetSearchFlowOptions?.singlePackageSelection || false
  const showServices = settings?.widgetSearchFlowOptions?.showServices || false
  if (enableServiceQuestions && !enableCategories && singlePackageSelection && showServices) {
    return true
  }
  return false
};

export const isSharpLawnWithoutServices = (settings) => {
  const enableServiceQuestions = settings?.widgetSearchFlowOptions?.enableServiceQuestions || false
  const enableCategories = settings?.widgetSearchFlowOptions?.enableCategories || false
  const singlePackageSelection = settings?.widgetSearchFlowOptions?.singlePackageSelection || false
  const showServices = settings?.widgetSearchFlowOptions?.showServices || false
  if (enableServiceQuestions && !enableCategories && singlePackageSelection && !showServices) {
    return true
  }
  return false
};

export const getRequiredFeatures = (customerFeatures, searchedFeatures) => {
  // return the search features that are enabled for contractor
  const requiredFeatures = customerFeatures.filter(feature=> searchedFeatures.includes(feature))
  return requiredFeatures
}

export const getMeasurementImage = async (Center, polygons, zoom, type) => {
  const { data } = await sendRequest('POST', '/measurement/image', { Center, polygons, zoom, type })
  return data
};

export const getCustomerServiceAutoPilotData = async (customerId) => {
  const { data } = await sendRequest('GET', `/service-autopilot/customer/${customerId}`)
  if (!data?.forms || data.statusCode) return
  return data
};

export const getColumnGrids = (Size) => {
  if (Size === 'small') return 'grid-cols-3'
  if (Size === 'large') return 'grid-cols-1'
  // if (Size === 'medium') return 'grid-cols-2'
  return 'grid-cols-2'
}

export const calculateCardSize = (Size) => {
  if (Size === 'small') {
    return 3;
  }
  if (Size === 'medium') {
    return 2;
  }
  if (Size === 'large') {
    return 1;
  }
}

export const getRecommendedServiceCardSize = (size) => {
  if (size === 'small') {
    return 'Recommended size: (940W x 940L)';
  }
  if (size === 'medium') {
    return 'Recommended size: (2400W x 940L)';
  }
  if (size === 'large') {
    return 'Recommended size: (2400W x 940L)';
  }
};

export const getRecommendedCardSize = (size) => {
  if (size === 'small') {
    return 'Recommended size: (940W x 940L)';
  }
  if (size === 'medium') {
    return 'Recommended size: (2300W x 940L)';
  }
  if (size === 'large') {
    return 'Recommended size: (2300W x 940L)';
  }
};

export const filteredPackages = (packages, servicesArray) => {
  const filteredPackages = packages.filter(singlePackage => !servicesArray?.includes(singlePackage?._id)).map(packageItem => {
    const filteredServices = packageItem.services.filter(serviceItem => {
      if (!servicesArray?.includes(serviceItem._id)) {
        return serviceItem
      }
    })
    return {...packageItem, services:filteredServices}
  })
  return filteredPackages
}

export const filteredFirstServicePackages = (packages, servicesArray) => {
  const filteredPackages = packages.map(packageItem => {
    const filteredServices = !servicesArray?.includes(packageItem.services[0]._id) ? [packageItem.services[0]]  : [] 
    return {...packageItem, services:filteredServices}
  })
  return filteredPackages
}

export const filterAddress = (address) => {
  const seperate = address?.split(",")
  const stateZip = seperate?.[seperate?.length-2].split(" ")
  const addressState = stateZip?.[stateZip?.length - 2]
  return addressState
}