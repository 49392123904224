import sendRequest from '../../utils/useAxios';
import {leadTypes} from './leads.types';
import { CREATE_LEAD, DELETE_LEAD, GET_LEAD, GET_LEADS, UPDATE_LEAD, UPDATE_LEAD_IS_CHECKED, GET_SEARCHED_LEADS, GET_LEADS_BY_PLACEID } from './leads.api';
import { contractorTypes } from '../contractor/contractor.types';
import { addQuantityToService } from '../../utils/addQuantityToService';
import { getBoundary, setCart, setContactInfo, setCurrentRegion, setErrors, setReferrerInfo, setWidgetStepper, toggleCategories, toggleSubCategories } from '../widget/widget.actions';
import { setLoading as setWidgetLoading } from '../widget/widget.actions';
import { validateLead } from '../../utils/validateLead';
import { SERVICE_AUTOPILOT_STATES_MAPPING } from '../../constants/states.constant';
import { saveSearchLog } from '../searches-log/searches-log.actions';
import { calculateTax, setMeasurementForMap } from '../measurement/measurement.actions';
import { toggleItemInArray } from '../../utils/toggleItemInArray';
import { getServiceArea, setIsOutsideServiceArea } from '../service-area/service-area.actions';
import { GET_SERVICE_AREA } from '../service-area/service-area.api';
import { widgetTypes } from '../widget/widget.types';
import { getCustomerRedirectURL, getCustomerServiceAutoPilotData, getRequiredFeatures, isOnSearchPage, isOnWidgetPage } from '../widget/widget.selectors';
import { realGreenTypes } from '../real-green/real-green.types';
import { checkRealGreenCustomerByEmailAndAddress, setCustomerDataBySearch } from '../real-green/real-green.actions';
import Router from 'next/router'
import { getSource, get_recurring_mowing_service_price } from '../../utils/app-utils';
import { GET_SERVICES_BY_ID } from '../services/services.api';
import { checkInsideServiceArea } from '../service-area/service-area.selectors';
import { checkRealGreenCustomerBySearch } from '../real-green/real-green.selectors';
import { removeCountryCode } from '../../utils/removeCountryCode';
import { totalSnowSqFeet } from '../../utils/getTotalSnowSqft';


export const abandonedCart = (from) => (dispatch, getState) => {
  if(from == 'order') return
  const state = getState();
  const { lead } = state.leads
  console.log('addevent')
  if (lead && lead?.leadSessions && lead?.leadSessions?.length) {
    let leadSessions = lead.leadSessions
      leadSessions[leadSessions?.length - 1]['abandoned_cart'] = Date.now()
    console.log('addevent_updatedLead')
    dispatch(updateLead(lead._id, { leadSessions }))
  }
}

export const createLead = (lead, router?, query?, redirectData?) => async (dispatch, getState) => {
  if(!lead?.measurement || !lead?.measurement?.address) return
  const state = getState();
  const { searchedServicesType, currentRegion } = state.widget;
  dispatch(setLoading(true))
  let path = CREATE_LEAD.PATH
  if (query) {
    path += '?' + query
  }
  const { data } = await sendRequest(CREATE_LEAD.METHOD, path, { ...lead, leadSearchType: searchedServicesType, serviceAreaRegion: currentRegion?._id })
  if (!data.statusCode) {
    dispatch(setLoading(false))
    dispatch({
      type: leadTypes.SET_LEAD,
      payload: data
    })
    dispatch({ type: leadTypes.SET_IS_LEAD_CREATED, payload: true })
    dispatch(setWidgetLoading(false));
    if (router && redirectData) {
      const { customer, paymentType } = redirectData
      const redirectUrl = getCustomerRedirectURL(customer, paymentType)
      router.push(redirectUrl)
    }
  } else {
    const { data } = await sendRequest(GET_LEADS_BY_PLACEID.METHOD, `${GET_LEADS_BY_PLACEID.PATH}/${lead.measurement.placeId}/${lead.contractor}/${isOnSearchPage(router) ? 'internal': 'storefront'}`)
    if (data?.response?.statusCode || !data) {
      dispatch(setWidgetLoading(false));
      dispatch(setLoading(false))
      return
    }
    dispatch({
      type: leadTypes.SET_LEAD,
      payload: data
    })
    dispatch({ type: leadTypes.SET_IS_LEAD_CREATED, payload: true })
    dispatch(setWidgetLoading(false));
    dispatch(setLoading(false))
  }
}

export const resetLead = () => async (dispatch) => {
  dispatch({
    type: leadTypes.SET_LEADS_INITIAL_STATE,
  })
}

export const createQuoteRequestLead = (router?) => (dispatch, getState) => {
  const state = getState();
  const { measurement } = state.measurement;
  const { customer } = state.customer;
  const { cart, quantity, orderQuestions, signature, orderUser, paymentType, searchedServicesType} = state.widget;
  const { lead } = state.leads;

  let services = {}
  let onePackage = []
  let packages = []
  cart.packages.forEach( function (packageItem) {
      services = packageItem.services.map(serviceItem => (
          {
              ...serviceItem,
              quantity: packageItem.quantity[serviceItem._id]
          }
      ))
      onePackage = {...packageItem, services}
      packages = [...packages, onePackage]
    })
  const order = {
    services: addQuantityToService(cart.services, quantity),
    packages: packages?.map(orderPackage => orderPackage._id),
    signature,
    user: orderUser
  }
  const {isValid, errors} = validateLead(order, customer, orderQuestions)
  if(!isValid){
    dispatch(setErrors(errors))
    dispatch(setWidgetLoading(false));
    return
  }
  const leadPayload = {
    contractor: customer?.user,
    measurement,
    ...orderUser,
    services: addQuantityToService(cart.services, quantity),
    packages: packages?.map(orderPackage => orderPackage._id),
    signature,
    leadSessions: lead?.leadSessions,
    leadSearchType: searchedServicesType,
    
  }
  if(lead) {
    dispatch(updateLead(lead._id, leadPayload, router))
  } else {
    dispatch(createLead(leadPayload, router, ...[,], {customer, paymentType}))
  }
}

export const getLead = (leadId) => async (dispatch) => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(GET_LEAD.METHOD, `${GET_LEAD.PATH}/${leadId}`)
  if(!data.statusCode){
    dispatch({
      type: leadTypes.SET_LEAD,
      payload: data
    })
  }
}

export const deleteLead = (leadId) => async (dispatch) => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(DELETE_LEAD.METHOD, `${DELETE_LEAD.PATH}/${leadId}`)
  if(!data.statusCode){
    dispatch({
      type: leadTypes.DELETE_LEAD_BY_ID,
      payload: leadId
    })
  }
}

export const updateLead = (leadId, leadPayload, router?) => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const {router: temp_router} = Router;
  const state = getState();
  const {quantity, searchedServicesType} = state?.widget
  const { lead } = state?.leads
  let items = {}
  if(leadPayload?.services?.length){
    items['services'] = addQuantityToService(leadPayload?.services, quantity)
  }
  if(leadPayload?.packages?.length){
    items['packages'] = leadPayload?.packages?.map((packageItem) => packageItem._id)
  }
  const { data } = await sendRequest(UPDATE_LEAD.METHOD, `${UPDATE_LEAD.PATH}/${leadId}`, { ...leadPayload, ...items, isSearchPage: isOnSearchPage(temp_router), leadSearchType: searchedServicesType, fieldRoutesEmployeeId: lead?.fieldRoutesIntegration?.contactID  })
  if(!data.statusCode){
    dispatch({
      type: leadTypes.SET_LEAD,
      payload: data
    })
    // dispatch(setWidgetLoading(false));
    if(router) {
      router.push('/widget/thank-you')
    }
  }
  dispatch(setLoading(false))
}

export const sendAbandonedCartEmail = (emailPayload) => async (dispatch) => {
  sendRequest('POST', '/leads/send-abandoned-cart-email', emailPayload);
  return
}

export const updateItemsInLead = () => (dispatch, getState) => {
  const state = getState();
  const {lead} = state?.leads
  const {services, packages} = state?.widget.cart
  const {quantity} = state?.widget
  if(!lead) return

  dispatch(updateLead(lead._id, {...lead, services, packages, quantity}))
} 

export const addLeadSession = (leadId, sessionPayload) => async (dispatch) => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(GET_LEAD.METHOD, `${GET_LEAD.PATH}/${leadId}`)
  if(!data.statusCode){
    let leadSessions = []
    if (data.leadSessions) {
      leadSessions = data.leadSessions
    }
    leadSessions.push(sessionPayload)
    dispatch(updateLead(leadId, { ...data, leadSessions }))
  }
}

export const updateLeadIsChecked = (leadId, leadPayload) => async (dispatch) => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(UPDATE_LEAD_IS_CHECKED.METHOD, `${UPDATE_LEAD_IS_CHECKED.PATH}/${leadId}`, leadPayload)
  dispatch(getLeads("page=1"))
}

export const getLeads = (query) => async (dispatch) => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(GET_LEADS.METHOD, `${GET_LEADS.PATH}?${query}`)
  if(!data.statusCode){
    dispatch({
      type: leadTypes.SET_LEADS,
      payload: data
    })
    dispatch({
      type: contractorTypes.SET_CONTRACTOR_ORDERS_TOTAL_COUNT,
      payload: data.leadsCount 
    })
  }
  dispatch(setLoading(false))
}

export const inserNewSession = (leadData: any, latestSession?:any) => (dispatch) => {
  let leadSessions: any = []
  if (leadData?.leadSessions) {
    leadSessions = leadData.leadSessions
  }
  const newSession = latestSession?.stepper?.currentStep === 2 ? { searched_address: Date.now(), viewed_services: true } : { searched_address: Date.now() }
  leadSessions.push(newSession)
  dispatch(updateLead(leadData._id, { leadSessions }))
}

export const getLeadsByPlaceID = (placeId, router) => async (dispatch, getState) => {
  dispatch(setLoading(true))
  dispatch(setLeadByPlaceIdLoading(true))
  
  const contractorId = router.query.id
  const state = getState();
  const { customer } = state.customer;
  const { place } = state.measurement
  const { searchedServicesFeatures, searchedServicesType } = state.widget
  const enableMapOverlayImage = customer?.settings?.enableMapOverlayImage === 'All' ? 'All' : 'None';
  const polygonFeatures = customer?.settings?.polygonFeatures || ['LAWN']
  const requiredFeatures = getRequiredFeatures(polygonFeatures, searchedServicesFeatures)
  // const { address_components } = place
  const longAddress = `${place?.address_components[0]?.long_name} ${place?.address_components[1]?.long_name}`

  const { data } = await sendRequest(GET_LEADS_BY_PLACEID.METHOD, `${GET_LEADS_BY_PLACEID.PATH}/${placeId}/${contractorId}/${isOnSearchPage(router) ? 'Internal': 'Storefront'}?address=${place?.formatted_address}&customerId=${customer._id}&longAddress=${longAddress}`)

  if (data?.response?.statusCode || !data || data?.status == 400) {
    dispatch({
      type: leadTypes.SET_LEAD,
      payload: null
    })
    dispatch(setLoading(false))
    dispatch(setLeadByPlaceIdLoading(false))
    return
  }
  
  dispatch({
    type: leadTypes.SET_LEAD,
    payload: data?.isDeeplawnLead ? data : null
  })



  //  check if customer already exist on realgreen
  const placeObject = {
    place_id: router.query.placeId,
    formatted_address: router.query.address,
    address_components: JSON.parse(router.query.address_components),
    geometry: JSON.parse(router.query.geometry),
  }

  const realGreenCustomer = await checkRealGreenCustomerBySearch(placeObject, customer._id)
  if (realGreenCustomer) dispatch(setCustomerDataBySearch({ customerId: customer._id, data: realGreenCustomer }))

  if (!realGreenCustomer) {
    dispatch(setContactInfo({
      firstName: data.firstName,
      lastName: data.lastName,
      name: data.name,
      email: data.email,
      phone: data.phone,
      source: data?.source,
      comments: '',
    }))
  }

  if (data?.referrerInfo) {
    dispatch(setReferrerInfo({
      phone: data?.referrerInfo?.phone,
      selectedReferrer: data?.referrerInfo?.selectedReferrer,
      referrerName: data?.referrerInfo?.selectedReferrer
    }))
  }

  //  check if customer already exist on fieldRoutes
  if (data?.params?.action) {
    dispatch({
      type: leadTypes.SET_FIELD_ROUTES_LEAD,
      payload: data
    })
    dispatch(setContactInfo({
      firstName: data?.customer?.fname,
      lastName: data?.customer?.lname,
      name: (data?.customer?.fname && data?.customer?.lname) ? `${data?.customer?.fname} ${data?.customer?.lname}` : '',
      email: data?.customer?.email,
      phone: data?.customer?.phone1,
      source: '',
      comments: ''
    }))
  }

  //  check if customer already exist on jobber
  if (data?.data?.properties?.nodes[0]?.id) {
    dispatch({
      type: leadTypes.SET_JOBBER_LEAD,
      payload: data?.data
    })
    dispatch(setContactInfo({
      firstName: data?.data?.properties?.nodes[0]?.client?.firstName,
      lastName: data?.data?.properties?.nodes[0]?.client?.lastName,
      name: (data?.data?.properties?.nodes[0]?.client?.firstName && data?.data?.properties?.nodes[0]?.client?.lastName) ? `${data?.data?.properties?.nodes[0]?.client?.firstName} ${data?.data?.properties?.nodes[0]?.client?.lastName}` : '',
      email: data?.data?.properties?.nodes[0]?.client?.emails[0]?.address,
      phone: data?.data?.properties?.nodes[0]?.client?.phones[0]?.number,
      source: '',
      comments: ''
    }))
  }

  let { measurement, latestSession, services, packages, contractor, selectedCategories, selectedSubCategories, quantity } = data
  const { data: serviceArea } = await sendRequest(GET_SERVICE_AREA.METHOD, `${GET_SERVICE_AREA.PATH}/${ customer?.user || contractor?._id}`);
  const { isInside, region } = checkInsideServiceArea(serviceArea.polygon, measurement?.mapCenter, searchedServicesType)
  if (!region) {
    dispatch(setLoading(false))
    dispatch(setIsOutsideServiceArea(true))
    dispatch(setLeadByPlaceIdLoading(false))
    return
  }
  dispatch(setCurrentRegion(region))

  // if (isOnSearchPage(router)) {
  //   dispatch(inserNewSession(data, latestSession))
  //   dispatch({
  //     type: widgetTypes.SET_CALL_ON_PLACE_SELECTED,
  //     payload: false,
  //   });
  //   dispatch(setLoading(false))
  //   dispatch(setLeadByPlaceIdLoading(false))
  //   return
  // }

  // check if measurement includes all required polygon features
  const dbFeatures: any = []
  const dbFeaturesPolygons: any = {}
  measurement.mapPolygons.map(polygonObj => {
    if (!dbFeatures.includes(polygonObj?.type) && requiredFeatures?.includes(polygonObj?.type)) {
      dbFeatures.push(polygonObj?.type)
    }
    if (!dbFeaturesPolygons[polygonObj?.type]) {
      dbFeaturesPolygons[polygonObj?.type] = []
    }
    dbFeaturesPolygons[polygonObj?.type]?.push(polygonObj)
  })
  if (requiredFeatures.length !== dbFeatures.length) {
    dispatch(inserNewSession(data))
    dispatch(setLoading(false))
    dispatch(setLeadByPlaceIdLoading(false))
    return
  }

  if (enableMapOverlayImage === 'All' && (!measurement?.overlayImage?.imageObject || !measurement?.overlayImage?.bounds)) {
    dispatch(inserNewSession(data))
    dispatch(setLoading(false))
    dispatch(setLeadByPlaceIdLoading(false))
    return
  }
  dispatch({
    type: widgetTypes.SET_CALL_ON_PLACE_SELECTED,
    payload: false,
  });
  if (selectedCategories) {
    dispatch(toggleCategories(selectedCategories))
    dispatch(toggleSubCategories(selectedSubCategories))
  }
  const routerGeometry =  JSON.parse(router?.query?.geometry)
  if (routerGeometry?.location) {
    measurement = {...measurement, mapCenter: routerGeometry?.location}
  }
  dispatch(setMeasurementForMap(measurement))
  // dispatch(
  //   saveSearchLog({
  //     contractor: contractorId,
  //     measurement: measurement._id,
  //     searchedFrom: 'WIDGET',
  //     timeTaken: 0,
  //     sentBy: 'database',
  //     isBuildingSquareFeet: !!measurement?.propertyInfo?.propertyAttributes?.buildgSqFt,
  //     features: requiredFeatures,
  //   })
  // );

  let address = measurement?.address;
  let parsedAddress = address.split(' ');
  const requestedAddress = parsedAddress.slice(0, parsedAddress.length - 2).join(' ');

  dispatch(getBoundary(measurement?.placeId, requestedAddress));

  dispatch(calculateTax(measurement.address, customer?._id, region?._id));
  if (latestSession?.stepper) {
    dispatch(setWidgetStepper(latestSession.stepper))
  }
  if (quantity) {
    dispatch({
      type: widgetTypes.SET_QUANTITY_FOR_SERVICE,
      payload: quantity,
    });
  }
  const cartSerivese = services ? services : []
  const cartPackages = packages ? packages : []
  dispatch(setCart(cartSerivese, cartPackages))
  // new session
  dispatch(inserNewSession(data, latestSession))
  dispatch(setLoading(false))
  dispatch(setLeadByPlaceIdLoading(false))
}

export const getSearchedLeads = (searchQuery, page) => async (dispatch) => {
  const query = {
    searchQuery
  }
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_SEARCHED_LEADS.METHOD, `${GET_SEARCHED_LEADS.PATH}?page=${page}`, query)
  if(!data.statusCode){
    dispatch({
      type: leadTypes.SET_LEADS,
      payload: data
    })
    dispatch({
      type: contractorTypes.SET_CONTRACTOR_ORDERS_TOTAL_COUNT,
      payload: data.leadsCount 
    })
  }
  dispatch(setLoading(false))

}

export const setLoading = (type) => (dispatch) => {
  dispatch({
    type: leadTypes.SET_LEADS_LOADING,
    payload: type,
  });
}

export const setLeadByPlaceIdLoading = (status) => (dispatch) => {
  dispatch({
    type: leadTypes.SET_LEADS_BY_PLACE_ID_LOADING,
    payload: status,
  });
}

export const setLead = (leadData) => (dispatch) => {
  dispatch({
    type: leadTypes.SET_LEAD,
    payload: leadData
  })
}

export const setFieldRoutesLead = (leadData) => (dispatch) => {
  dispatch({
    type: leadTypes.SET_FIELD_ROUTES_LEAD,
    payload: leadData
  })
}

export const setJobberLead = (leadData) => (dispatch) => {
  dispatch({
    type: leadTypes.SET_JOBBER_LEAD,
    payload: leadData
  })
}

export const setDeeplawn_fieldRoutes_jobberLeads = ({deeplawnLead, fieldRoutesLead, jobberLead}: any) => (dispatch) => {
  dispatch(setLead(deeplawnLead));
  dispatch(setFieldRoutesLead(fieldRoutesLead))
  dispatch(setJobberLead(jobberLead))
}

export const setLeadWidgetAddress = (address) => (dispatch) => {
  dispatch({
    type: leadTypes.SET_WIDGET_LEAD_ADDRESS,
    payload: address,
  });
}

export const setFieldRoutesSubscriptionId = (resultObj) => (dispatch) => {
  dispatch({
    type: leadTypes.SET_FIELD_ROUTES_SUBSCRIPTION_IDS,
    payload: resultObj
  })
}

export const xmlHttpPostRquest = async (url: any, data: any, company_id: string) => {
  try {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
    xhr.setRequestHeader('x-CompanyID', company_id);
    xhr.responseType = 'json';
    xhr.withCredentials = false;
    (xhr as any).crossDomain = true;
    xhr.send(JSON.stringify(data));
  } catch (error) {
    console.log("ServiceAutoPilot lead error ::", error)
  }
};

export const create_serviceAutoPilot_lead = (leadData, customer, router) => async (dispatch, getState) => {
  const store = getState()
  const { currentRegion, quantity, boundary, paymentType: cartPaymentType } = store.widget

  const { sqFeet, measurement } = store.measurement;
  // const { serviceAutoPilotForms } = customer
  const { _id } = customer
  const serviceAutoPilotDoc = await getCustomerServiceAutoPilotData(_id)
  const firstName = leadData.name.split(" ").map(item => item.trim()).slice(0, 1).join(' ')
  const lastName = leadData.name.split(" ").map(item => item.trim()).slice(1).join(' ')
  const address = leadData.measurement.address.split(',').map(item => item.trim());
  const state = address[2].split(' ');
  const phoneNumber = removeCountryCode(leadData.phone);
  const leadForms = serviceAutoPilotDoc?.forms?.find(form => form.formType === 'lead' && ((form?.serviceArea_regions?.length && form.serviceArea_regions.includes(currentRegion?._id)) || !form?.serviceArea_regions?.length))
  const dropdownSource = leadData?.dropdownProperties?.answer
  const leadConsent = leadData?.consent?.answer
  let services = []
  const { data: contractor_services } = await sendRequest(
    GET_SERVICES_BY_ID.METHOD,
    `${GET_SERVICES_BY_ID.PATH}/${customer.user}?active=${true}`
  );
  
  if (contractor_services && !contractor_services?.statusCode) {
    services = contractor_services
  }
  const utmParameter= router?.query?.utm || null

  try {
    await xmlHttpPostRquest('https://my.serviceautopilot.com/MarketingBFF/Form/ApplyTagsOnClick', {
      request: {
        companyId: leadForms?.companyId || serviceAutoPilotDoc?.companyId,
        formTemplateId: leadForms?.formId,
      },
    },
      leadForms?.companyId || serviceAutoPilotDoc?.companyId
    );

    await xmlHttpPostRquest('https://my.serviceautopilot.com/MarketingBFF/Form/SubmitForm', {
      request: {
        formTemplateId: leadForms?.formId,
        data: {
          pages: [
            {
              page: 1,
              fields: [
                {
                  fieldId: leadForms?.formFields?.firstName || 3,
                  value: firstName,
                },
                {
                  fieldId: leadForms?.formFields?.lastName || 4,
                  value: lastName,
                },
                {
                  fieldId: leadForms?.formFields?.email || 5,
                  value: leadData.email,
                },
                {
                  fieldId: leadForms?.formFields?.phone || 6,
                  value: phoneNumber,
                },
                {
                  fieldId: leadForms?.formFields?.addressLine1 || 7,
                  value: address[0],
                },
                {
                  fieldId: leadForms?.formFields?.addressLine2 || 8,
                  value: "",
                },
                {
                  fieldId: leadForms?.formFields?.city || 9,
                  value: address[1],
                },
                {
                  fieldId: leadForms?.formFields?.state || 10,
                  value: state[0] ? (serviceAutoPilotDoc.stateIds ? serviceAutoPilotDoc?.stateIds[state[0]] : SERVICE_AUTOPILOT_STATES_MAPPING[state[0]]) : '',
                },
                {
                  fieldId: leadForms?.formFields?.postalCode || 11,
                  value: state[1],
                },
                {
                  "fieldId": leadForms?.formFields?.description || 0,
                  "value": `<div>
                  ${(customer?.serviceAutoPilot?.sendMeasurementImgInComment || serviceAutoPilotDoc?.sendMeasurementImgInComment) ? `<img src=${measurement?.measurementImage} alt='measurement image' />` : ''}
                  </div>`
                },
                {
                  "fieldId": leadForms?.formFields?.dropdown_source || 0,
                  "value": dropdownSource
                },
                {
                  "fieldId": leadForms?.formFields?.lead_consent || 0,
                  "values": leadConsent === true ? ["0"] : [""]
                },
                {
                  "fieldId": customer?.serviceAutoPilot?.formFields?.sqfeet || leadForms?.formFields?.sqfeet,
                  "value": parseInt(sqFeet.toString())
                },
                {
                  "fieldId": leadForms?.formFields?.recurring_mowing_service_price || 0,
                  "value": get_recurring_mowing_service_price(services, quantity, measurement, boundary, cartPaymentType)
                },
                {
                  "fieldId": leadForms?.formFields?.source || 0,
                  "value": getSource([], serviceAutoPilotDoc)
                },
                {
                  "fieldId": leadForms?.formFields?.utm || 0,
                  "value": utmParameter || ''
                },
                {
                  "fieldId": leadForms?.formFields?.lot_sqft || 0,
                  "value": parseInt(Math.round(boundary?.acreage_calc * 43560)?.toString() || '0')
                },
                {
                  "fieldId": leadForms?.formFields?.snow_sqft || 0,
                  "value": totalSnowSqFeet(measurement?.polygonsFeaturesSquareFeet)
                },
                {
                  "fieldId": leadForms?.formFields?.house_perimeter || 0,
                  "value": parseInt(measurement?.polygonsFeaturesSquareFeet?.BUILDING || 0)
                },
                {
                  "fieldId": leadForms?.formFields?.house_footprint || 0,
                  "value": parseInt(measurement?.polygonsFeaturesSquareFeet?.BUILDING_FOOTPRINT_AREA || 0)
                },
              ],
            },
          ],
        },
      },
    },
      leadForms?.companyId || serviceAutoPilotDoc?.companyId
    );
  } catch (error) {
    console.log("serviceAutoPilot lead error: ", error)
  }
}
