import { userTypes } from './user.types';

const INITIAL_STATE = {
  loading: false,
  member: null,
  token: '',
  verified: false,
  user: null,
  isCustomer: true,
  isPaid: true,
  errorMessages: [],
  email: '',
  requirements: {},
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case userTypes.SET_USER_TOKEN:
      return {
        ...state,
        token: payload,
      };
    case userTypes.SET_USER_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case userTypes.SET_USER:
      return {
        ...state,
        user: payload,
      };
    case userTypes.SET_USER_ERROR:
      return {
        ...state,
        errorMessages: payload,
      };
    case userTypes.IS_CUSTOMER:
      return {
        ...state,
        isCustomer: payload.customer,
        isPaid: payload.paid,
        requirements: payload.requirements,
      };
    case userTypes.SET_INITIAL_STATE:
      return {
        ...state,
        token: '',
        user: null,
      };
    case userTypes.SET_VERIFICATION_STATUS:
      return {
        verified: payload,
      };
    case userTypes.SET_EMAIL:
      return {
        ...state,
        email: payload
      };
    case userTypes.UPDATE_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          name: payload,
        }
      }
    case userTypes.SET_USER_MEMBER:
      return {
        ...state,
        member: payload
      };
    default:
      return state;
  }
};

export default userReducer;
